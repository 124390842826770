// MYND FYRIR VERSION 2
// import photo from '../../images/satti-mynd.jpeg';

// MYND FYRIR VERSION 3
import photo from '../../images/borgartun_overlay.png';

import './header.scss';

export function Header(props) {
  return (
    // <div className='headerSection'>
    //   <div className='headerContainer'>
    //     <div className='headerImage'>
    //       <img src={photo} alt=''></img>
    //     </div>
    //   </div>
    //   <div className='headerTextContainer'>
    //     <div className='headerText'>
    //       <h1 className='headerTextValue'>{props.title}</h1>
    //     </div>
    //   </div>
    // </div>

    <>
      {/* VERSION 2 */}
      {/* <----------------------------------> */}
      {/* <div className='headerSection'>
        <div className='headerContainer'>
          <div className='headerImage'>
            <img src={photo} alt=''></img>
          </div>
        </div>

        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '60px',
          }}
        >
          <div
            style={{
              width: '1000px',
            }}
          >
            <h1
              style={{
                paddingLeft: '30px',
                borderLeft: '#02529c 4px solid',
                lineHeight: '40px',
              }}
            >
              {props.title}
            </h1>
          </div>
        </div>
      </div> */}
      {/* <---------------------------------------------> */}

      {/* VERSION 3 */}
      {/* --------------------------------------------- */}
      <div className='headerSection'>
        <div className='headerContainer'>
          <div className='headerImage'>
            {/* <img src={photo} alt=''></img> */}
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div className='headerTitleContainer'>
                <h1 className='headerTitle'>{props.title}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ------------------------------------------------- */}
    </>
  );
}
