import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import './islogin.css';

import { message, Spin } from 'antd';
import { user } from '../auth.js';
import { NavBar } from '../../components/navbar/NavBar.jsx';
import { Header } from '../../components/header/Header.jsx';
import { LoadingOutlined } from '@ant-design/icons';

const loadingIcon = <LoadingOutlined style={{ fontSize: 100 }} spin />;

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    async function checkAuthentication() {
      const result = await user();

      if (!result.ok) {
        message.error(result.error);
        setTimeout(function () {
          setIsAuthenticated(false);
          setLoading(false);
        }, 1500);
      } else {
        setIsAuthenticated(true);
        setLoading(false);
      }
    }

    checkAuthentication();
  }, []);

  if (loading) {
    return (
      <>
        <NavBar />
        <Header title='Sæki gögn...' />
        <div className='loadingContainer'>
          <Spin
            indicator={loadingIcon}
            tip={
              <h3
                style={{
                  color: '#02529c',
                  fontStyle: 'italic',
                  marginTop: '1rem',
                }}
              >
                Sæki gögn...
              </h3>
            }
          ></Spin>
        </div>
      </>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthenticated ? (
          <>
            <Component isAuthenticated={isAuthenticated} {...props} />
          </>
        ) : (
          <Redirect to='/user/login' />
        );
      }}
    />
  );
};

export default PrivateRoute;
