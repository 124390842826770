import { useState, useEffect } from 'react';

import { Tabs } from 'antd';

import { TabContent } from '../../../components/tabcontent/TabContent.jsx';
import { ContractCollapseReplication } from '../../../components/contractcollapsereplication/ContractCollapseReplication.jsx';
import { Preferences } from '../../../components/preferences/Preferences.jsx';
import { HeroReplication } from './HeroReplication';
import { TabContentReplication } from '../../../components/tabcontentreplication/TabContentReplication.jsx';
import { PreferencesReplication } from '../../../components/preferencesreplication/PreferencesReplication.jsx';

const fetchlink = 'https://api.kjarasamningar.is';

export function HomeReplication() {
  const [unions, setUnions] = useState([]);
  const [employers, setEmployers] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState('');
  const [tabNumber, setTabNumber] = useState(1);
  const [orderBy, setOrderBy] = useState('name');

  useEffect(() => {
    if (tabNumber === 1) {
      fetchByKeyword(value);
    } else if (tabNumber === 2) {
      fetchByKeyword(value);
    } else if (tabNumber === 3) {
      fetchByKeyword(value);
    }
  }, [tabNumber]);

  useEffect(() => {
    tabNumber === 1
      ? fetchByKeyword(value)
      : tabNumber === 2
        ? fetchByKeyword(value)
        : fetchByKeyword(value);
  }, [orderBy]);

  async function fetchData(link) {
    return await fetch(`${fetchlink}${link}`);
  }

  async function fetchByKeyword(query, offset, limit) {
    setLoading(true);
    let tab = '';
    if (tabNumber === 1) tab = 'unions';
    if (tabNumber === 2) tab = 'employers';
    if (tabNumber === 3) tab = 'contractgroup';
    const data = await fetchData(
      `/contracts/all?keyword=${query}&type=${tab}&issingle=true&agecategory=new&orderby=${orderBy}`
    );

    const json = await data.json();
    if (tab === 'unions') setUnions(json);
    if (tab === 'employers') setEmployers(json);
    if (tab === 'contractgroup') setContracts(json);
    setLoading(false);
  }

  return (
    <div style={{ padding: '40px 0' }}>
      <HeroReplication />
      <div className="mainSection">
        <div className="mainContainer">
          <PreferencesReplication
            tabNumber={tabNumber}
            oldcontractslink="/kjara/oldcontracts"
            onSortSelect={(e) => {
              setOrderBy(e);
            }}
            onTextInput={(e) => {
              fetchByKeyword(e);
              setValue(e);
            }}
          />
          <Tabs
            onTabClick={(e) => setTabNumber(parseInt(e))}
            className="mainContent"
            centered
            defaultActiveKey="1"
          >
            <Tabs.TabPane tab="Stéttarfélög" key="1">
              <TabContentReplication
                items={unions}
                contentType="unions"
                loading={loading}
                changeOffsetLimit={(offset, limit) => {
                  fetchByKeyword(value, offset, limit);
                }}
              />
            </Tabs.TabPane>

            <Tabs.TabPane tab="Launagreiðendur" key="2">
              <TabContentReplication
                items={employers}
                contentType="employers"
                loading={loading}
              />
            </Tabs.TabPane>

            <Tabs.TabPane tab="Gildandi kjarasamningar" key="3">
              <ContractCollapseReplication
                loading={loading}
                isHomePage={true}
                data={contracts}
              />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
}
