// import axios from 'axios';
import { fetchlink as apiUrl } from '../utils/link.js';

export const getJwt = () => JSON.parse(localStorage.getItem('jwtToken'));
export const getUser = () => localStorage.getItem('user');

/**
 * Function that authenticates users. Called on
 * every route that requires login information.
 * @returns {Object} The logged in user, if he is logged in
 */
export const user = async () => {
  const jwt = getJwt();
  if (!jwt) {
    return { error: 'Notandi ekki skráður inn', ok: false };
  }

  const result = await fetch(`${apiUrl}/user`, {
    method: 'GET',
    headers: { Authorization: `Bearer ${jwt.token}` },
  });

  if (!result.ok) {
    const json = await result.json();
    return json;
  } else {
    const json = await result.json();
    localStorage.setItem('user', json.username);
    return { ...json, ok: true };
  }
};

/**
 * Function that logs users in, giving them admin access
 * @param {Object} values Login data
 * @returns The logged in user, if the data is correct
 */
export const login = async (values) => {
  const result = await fetch(`${apiUrl}/users/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(values),
  });

  if (!result.ok) {
    const json = await result.json();
    return { ...json, ok: false };
  } else {
    const json = await result.json();
    localStorage.setItem('jwtToken', JSON.stringify(json));
    return user();
  }
};

/**
 * A function that registers a new user, only callable by a
 * user thats already logged in.
 * @param {Object} values
 * @returns
 */
export const register = async (values) => {
  const jwt = getJwt();
  console.log('token -> ', jwt.token);
  const result = await fetch(`${apiUrl}/users/register`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt.token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(values),
  });

  if (!result.ok) {
    const json = await result.json();
    return { ...json, ok: false };
  } else {
    const json = await result.json();
    return json;
  }
};

/**
 * Logs the current user out
 */
export const logout = () => {
  localStorage.removeItem('jwtToken');
  localStorage.removeItem('user');
  ensureAuthenticated();
};

/**
 * A function that's called when a user forgets their
 * password.
 * @param {Object} values Email address
 * @returns
 */
export const forgotPass = async (values) => {
  const result = await fetch(`${apiUrl}/users/forgot-password`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(values),
  });

  if (!result.ok) {
    const json = await result.json();
    return { ...json, ok: false };
  } else {
    const json = await result.json();
    return { ...json, ok: true };
  }
};

/**
 * This is only callable from a page that is accessible through
 * email if a user forgets their password.
 * @param {Object} values
 * @param {String} resetToken
 * @returns
 */
export const updatePass = async (values, resetToken) => {
  const data = { ...values, resetToken };
  console.log('data -> ', data);
  const result = await fetch(`${apiUrl}/users/new-password`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (!result.ok) {
    const json = await result.json();
    return { ...json, ok: false };
  } else {
    const json = await result.json();
    return { ...json, ok: true };
  }
};

// export const get

// refreshToken = async () => {
//     return new Promise((resolve, reject) => {
//         const formData = new FormData();
//         formData.set('refresh', getJwt().refresh);

//         axios.post(`${apiUrl}/token/refresh/`, formData, {})
//             .then(res => {
//                 let jwt = getJwt();
//                 jwt.access = res.data.access;
//                 localStorage.setItem('jwtToken', JSON.stringify(jwt));
//                 resolve(true);
//             })
//             .catch(err => {
//                 localStorage.removeItem('user');
//                 localStorage.removeItem('jwtToken');
//                 reject();
//             })
//     })
// }

// Helpers
export const ensureAuthenticated = () => {
  const loginToken = getJwt();
  const currentUser = getUser();
  if (loginToken && currentUser) {
    return true;
  } else {
    window.location.href = '/';
  }
};
