import { useEffect, useState } from 'react';
import { Form, Button, Input, Modal, Typography } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { fetchlink } from '../../../utils/link.js';
import { getJwt, getUser } from '../../../auth/auth.js';

export function FormModal(props) {
  const [form] = Form.useForm();
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const result = await fetch(`${fetchlink}/${props.dataType}/asc`);

      if (!result.ok) {
        setError('Ekki tókst að sækja gögn');
      } else {
        const json = await result.json();
        setFormData(json);
        props.setData(json, props.isUnion);
      }
      setLoading(false);
    }
    fetchData();
  }, []);

  const postForm = async (values) => {
    setError(null);
    setLoading(true);

    const jwt = getJwt();
    const user = getUser();

    let result;
    try {
      result = await fetch(`${fetchlink}/${props.dataType}/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${jwt.token}`,
        },
        body: JSON.stringify({ ...values, ...user }),
      });

      if (!result.ok) {
        setError('Villa kom upp, gat ekki sett inn gögn');
      }
    } catch (err) {
      console.error('Error --> ', err);
    }
    if (!result.ok) {
      setLoading(false);
    } else {
      form.resetFields();
      const concatData = [values, ...formData];
      props.setData(concatData, props.isUnion);
      setLoading(false);
      setModal(false);
    }
  };

  const showModal = () => {
    setModal(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setModal(false);
  };

  return (
    <>
      <Button onClick={showModal} style={{ marginLeft: '5px' }}>
        +
      </Button>
      <Modal
        title={props.title}
        visible={modal}
        okText={`Skrá ${props.oktext}`}
        cancelText='Hætta við'
        okButtonProps={{ htmlType: 'submit', loading: loading }}
        onOk={form.submit}
        onCancel={handleCancel}
      >
        <Form form={form} method='POST' onFinish={postForm}>
          <Form.Item
            name='name'
            label={`Nafn ${props.oktext}:`}
            rules={[
              {
                required: true,
                message: 'Verður að velja a.m.k einn valmöguleika',
              },
            ]}
          >
            <Input disabled={loading} />
          </Form.Item>
          {props.isUnion ? (
            <Form.Item
              name='unionid'
              label='Einkennisnúmer stéttarfélags'
              rules={[
                {
                  required: true,
                  len: 6,
                  message: 'Einkenni verður að vera 6 tölustafir',
                },
              ]}
            >
              <Input type='number' disabled={loading} />
            </Form.Item>
          ) : (
            <></>
          )}
        </Form>
        {error ? (
          <Typography.Paragraph style={{ backgroundColor: '#eee' }}>
            <CloseCircleOutlined style={{ color: 'red' }} /> {error}
          </Typography.Paragraph>
        ) : (
          <></>
        )}
      </Modal>
    </>
  );
}
