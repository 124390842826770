import React, { useState } from 'react';
import './editconnections.scss';
import { fetchlink } from '../../../utils/link';
import { Row } from './row/Row';
import { Button, Modal, message } from 'antd';
import { getJwt } from '../../../auth/auth.js';

export const EditConnections = (props) => {
  const [loading, setLoading] = useState(false);

  const handleOldNew = () => {
    Modal.confirm({
      title: 'Ertu viss?',
      content: (
        <>
          <p>
            Ekki er hægt að draga aðgerðina til baka eftir að búið er að
            samþykkja
          </p>
        </>
      ),
      async onOk() {
        const jwt = getJwt();

        let result;
        try {
          result = await fetch(`${fetchlink}/contracts/update/isold`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${jwt.token}`,
            },
            body: JSON.stringify({
              id: props.contract.id,
              isold: !props.contract.isold,
            }),
          });

          const response = await result.json();

          if (!result.ok) {
            message.error(response);
            setLoading(false);
          } else {
            message.success('Samningi breytt!');
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        } catch (err) {
          message.error('Óþekkt villa');
          setLoading(false);
        }
      },
      onCancel() {
        setLoading(false);
        console.info('Hætt við');
      },
    });
  };

  const handleCategoryChange = () => {
    Modal.confirm({
      title: 'Ertu viss?',
      content: (
        <>
          <p>
            Fara þarf varlega í svona breytingu, sérstaklega ef samningur er
            tengdur öðrum samningum.
          </p>
        </>
      ),
      async onOk() {
        const jwt = getJwt();

        let result;
        console.log({
          id: props.contract.id,
          contractCategory:
            props.contract.contractcategory === 'heildarutgafa'
              ? 'kjarasamningur'
              : 'heildarutgafa',
        });

        try {
          result = await fetch(`${fetchlink}/contracts/update`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${jwt.token}`,
            },
            body: JSON.stringify({
              id: props.contract.id,
              contractCategory:
                props.contract.contractcategory === 'heildarutgafa'
                  ? 'kjarasamningur'
                  : 'heildarutgafa',
            }),
          });

          const response = await result.json();

          if (!result.ok) {
            message.error(response);
            setLoading(false);
          } else {
            message.success('Samningi breytt!');
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        } catch (err) {
          message.error('Óþekkt villa');
          setLoading(false);
        }
      },
      onCancel() {
        setLoading(false);
        console.info('Hætt við');
      },
    });
  };

  return (
    <div className="connectionContainer">
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            type="dashed"
            style={{ margin: '8px' }}
            onClick={handleOldNew}
            loading={loading}
          >
            Skrá samning sem {props.contract.isold ? 'nýr' : 'gamall'}
          </Button>

          <Button
            danger
            type="dashed"
            loading={loading}
            style={{ margin: '8px' }}
            onClick={handleCategoryChange}
          >
            Breyta samning í{' '}
            {props.contract.contractcategory === 'heildarutgafa'
              ? 'kjarasamning'
              : 'heildarútgáfu'}
          </Button>
        </div>
        {console.log(props.contract)}
      </div>
      <Row
        title="Samningurinn"
        contract={props.contract}
        datafetch={`${fetchlink}/contracts/contractarrid=${props.contract.contractarrid}/contractgroupid=${props.contract.groupid}`}
        selectionFetch={`${fetchlink}/contracts${
          !props.contract.groupid ? '/heildar/' : '/notconnected/'
        }${props.contract.isold ? 'old' : 'new'}`}
        onEdit={props.onEdit}
      />

      <Row
        title="Sagan"
        contract={props.contract}
        datafetch={`${fetchlink}/contracts/heildar/contractarrid=${props.contract.contractarrid}`}
        selectionFetch={`${fetchlink}/contracts/heildar/notconnected`}
        onlyHeildar={true}
        onEdit={props.onEdit}
      />
    </div>
  );
};
