import React, { useEffect, useState } from 'react';

import { Form, Radio, Switch, Select, Tooltip, message } from 'antd';
import {
  CloseOutlined,
  CheckOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { fetchlink } from '../../../utils/link.js';

export function ContractCategory(props) {
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notConnectedContracts, setNotConnected] = useState([]);

  useEffect(() => {
    async function fetchContracts() {
      if (contracts) {
        setContracts(await fetchData('/contractarr'));
      }
    }
    fetchContracts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.form.getFieldValue('wasContractUpdated')]);

  useEffect(() => {
    async function fetchNotConnected() {
      if (notConnectedContracts) {
        setNotConnected(await fetchData('/contracts/notconnected'));
      }
    }
    fetchNotConnected();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.form.getFieldValue('contractConnection')]);

  const fetchData = async (route) => {
    setLoading(true);

    const data = await fetch(`${fetchlink}${route}`);

    if (!data.ok) {
      setLoading(false);
      message.error(
        'Villa kom upp þegar reynt var að sækja stéttarfélög/launagreiðanda, prófaðu að endurhlaða síðunni'
      );
    } else {
      setLoading(false);
      return await data.json();
    }
  };

  return (
    <>
      <Form.Item
        name="contractCategory"
        label="Útgáfa:"
        className="formitemContainer"
        rules={[
          {
            required: true,
            message: 'Vinsamlegast hakaðu í valkost sem á best við',
          },
        ]}
      >
        <Radio.Group
          onChange={() => {
            if (
              props.form.getFieldValue('contractCategory') ===
                'kjarasamningur' &&
              props.form.getFieldValue('contractConnection')
            ) {
              props.form.setFieldsValue({ ['contractConnection']: false });
            }
          }}
        >
          <Tooltip
            title="Hér er átt við að undirritaður var heildarútgáfa kjarasamnings,
                   þ.e. kjarasamningur sem tók til allra þátta.
                   Stundum er undirritaður svokallaður „kálfur“ þar sem aðeins eru tilgreind
                   þau atriði samningsins sem er breytt frá síðustu heildarútgáfu."
          >
            <Radio.Button disabled={props.formSubmit} value="heildarutgafa">
              Heildarútgáfa <InfoCircleOutlined />
            </Radio.Button>
          </Tooltip>
          <Tooltip
            title="Hér er átt við að ekki var undirrituð heildarútgáfa kjarasamnings
                   heldur svokallaður „kálfur“, þ.e. kjarasamningur þar sem aðeins eru tilgreind
                   þau atriði sem eru breytt frá síðasta kjarasamningi eða heildarútgáfu.
                   Kjarasamninginn þarf því að lesa með tilliti til síðustu heildarútgáfu og allra
                   annarra breytinga sem kunnu að hafa verið gerðar á heildarútgáfunni."
          >
            <Radio.Button disabled={props.formSubmit} value="kjarasamningur">
              Kjarasamningur <InfoCircleOutlined />
            </Radio.Button>
          </Tooltip>
        </Radio.Group>
      </Form.Item>

      {/* If "Heildarútgáfa" is CHECKED */}
      {props.form.getFieldValue('contractCategory') && (
        <Form.Item
          name="wasContractUpdated"
          style={{ backgroundColor: '#f3f3f3' }}
          className="formitemContainer"
          label={
            props.form.getFieldValue('contractCategory') === 'heildarutgafa'
              ? 'Ný heildarútgáfa: '
              : 'Veldu heildarútgáfu sem samningur tengist: '
          }
          valuePropName="checked"
        >
          <Switch
            loading={props.formSubmit}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      )}

      {/*
       If "Ný heildarútgáfa/Veldu heildarútgáfu..."is CHEKED
       we display options based on that 
       */}
      {props.form.getFieldValue('wasContractUpdated') && (
        <Form.Item
          style={{ backgroundColor: '#f3f3f3' }}
          name="contractArrID"
          className="formitemContainer"
          label={
            props.form.getFieldValue('contractCategory') === 'heildarutgafa'
              ? 'Veldu samning sem verið er að uppfæra: '
              : 'Heildarsamningur: '
          }
          rules={[
            {
              type: 'any',
              required: true,
              message: 'Verður að velja samning, eða afhaka ef það á við',
              max: 1,
            },
          ]}
        >
          <Select
            disabled={loading}
            loading={loading}
            showSearch
            placeholder
            optionFilterProp="children"
            dropdownMatchSelectWidth={false}
            dropdownStyle={{ width: '700px' }}
          >
            {contracts?.map((contract) => {
              return (
                <Select.Option key={contract.id} value={contract.id}>
                  {`(${contract.id}) ${contract.name}`}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      )}

      {props.form.getFieldValue('contractCategory') === 'heildarutgafa' && (
        <>
          <Form.Item
            name="contractConnection"
            style={{ backgroundColor: '#f3f3f3' }}
            className="formitemContainer"
            label="Viltu tengja kjarasamninga við heildarútgáfu?:"
            valuePropName="checked"
          >
            <Switch
              loading={props.formSubmit}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Form.Item>
        </>
      )}

      {props.form.getFieldValue('contractConnection') && (
        <Form.Item
          style={{ backgroundColor: '#f3f3f3' }}
          name="connectID"
          className="formitemContainer"
          label={<div>Veldu samninga sem á að tengja við heildarútgafu </div>}
          rules={[
            {
              type: 'any',
              max: 1,
            },
          ]}
        >
          <Select
            disabled={loading}
            loading={loading}
            mode="multiple"
            showSearch
            placeholder
            optionFilterProp="children"
            dropdownMatchSelectWidth={false}
            dropdownStyle={{ width: '700px' }}
          >
            {notConnectedContracts?.map((contract) => {
              return (
                <Select.Option key={contract.id} value={contract.id}>
                  {`(${moment(contract.durationperiodfrom).year()} - ${
                    contract.durationperiodto
                      ? moment(contract.durationperiodto).year()
                      : 'Ótímabundinn'
                  })`}{' '}
                  {contract.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      )}
    </>
  );
}
