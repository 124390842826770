import React, { useEffect, useState } from 'react';
import '../editconnections.scss';

import { Button, message, Select, Empty, Modal } from 'antd';
import { CaretRightFilled, CloseOutlined } from '@ant-design/icons';
import { Headline } from '../../../headline/Headline';
import { getJwt, getUser } from '../../../../auth/auth.js';
import { fetchlink } from '../../../../utils/link.js';

import moment from 'moment';

const RowItem = ({
  title,
  currentContract,
  dateToFrom,
  category,
  removeRow,
  added,
  loading,
  id,
}) => {
  return (
    <div
      className={`dragItem ${currentContract ? 'currentContract' : undefined} ${
        added ? 'contractAdded' : undefined
      } rowItemContainer`}
      style={{ border: loading && 'solid 1px white' }}
    >
      <div className={loading ? 'rowItemLoading' : undefined}>
        <div className="rowItem">
          <div className="m0 rowItemContentContainer">
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <h4 className="m0">
                {currentContract && <CaretRightFilled />} {title}
                <Button
                  href={`/admin/editcontract/${id}`}
                  style={{ marginLeft: '10px' }}
                  size="small"
                  type="default"
                >
                  Opna
                </Button>
              </h4>
            </div>
            <p
              className={`${
                category === 'Heildarutgafa' ? 'contentSpanText' : ''
              } m0`}
              style={{
                color: category === 'Kjarasamningur' && 'rgba(0, 0, 0, 0.45)',
              }}
            >
              {category === 'Heildarutgafa' ? 'Heildarútgáfa' : category}
            </p>
          </div>

          <div>
            <p className="m0">Gildistími:</p>
            <p className="m0 contentSpanText">{dateToFrom}</p>
          </div>

          <Button
            style={{ marginLeft: '16px' }}
            type="primary"
            danger
            size="small"
            icon={<CloseOutlined />}
            onClick={removeRow}
          ></Button>
        </div>
      </div>
    </div>
  );
};

export const Row = (props) => {
  const [data, setData] = useState([]);
  const [selection, setSelection] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      if (!!!props.contract.groupid) {
        const laus = [
          {
            key: props.contract.id,
            name: props.contract.name,
            durationPeriodFrom: props.contract.durationperiodfrom,
            durationPeriodTo: props.contract.durationperiodto,
            contractCategory:
              props.contract.contractcategory.charAt(0).toUpperCase() +
              props.contract.contractcategory.slice(1),
            added: false,
          },
        ];
        setData(laus);
      } else {
        const contractsData = await fetch(`${props.datafetch}`);
        if (!contractsData.ok) {
          message.error('Engir samningar fundust');
          setLoading(false);
        } else {
          let data = await contractsData.json();
          if (props.onlyHeildar) {
            data = data.filter((contract) => {
              return contract.contractcategory === 'heildarutgafa';
            });
          }

          const mappedData = data.map((contract) => {
            return {
              key: contract.id,
              name: contract.name,
              durationPeriodFrom: contract.durationperiodfrom,
              durationPeriodTo: contract.durationperiodto,
              contractCategory:
                contract.contractcategory.charAt(0).toUpperCase() +
                contract.contractcategory.slice(1),
              added: false,
            };
          });
          setData(mappedData);
        }
        setLoading(false);
      }
    }

    async function fetchSelection() {
      const data = await fetch(`${props.selectionFetch}`);

      if (!data.ok) {
        message.error('Ekki tókst að sækja gögn');
      } else {
        const json = await data.json();
        setSelection(json);
      }
      setLoading(false);
    }

    fetchData();
    fetchSelection();
  }, [
    props.contract,
    props.datafetch,
    props.onlyHeildar,
    props.selectionFetch,
    props.contract.groupid,
  ]);

  const isLaus = !!!props.contract.groupid;

  const handleDelete = (result) => {
    if (
      !props.onlyHeildar &&
      result.contractCategory === 'Heildarutgafa' &&
      !isLaus
    ) {
      message.error('Ekki hægt að eyða heildarútgáfu');
      return;
    }
    const temp = data.filter((item) => {
      return item.key !== result.key;
    });

    if (result.added) {
      const items = selection;
      const item_i = selection.findIndex((c) => c.id === result.key);
      items[item_i].added = false;
      setSelection(items);
    }

    setData(temp);
  };

  return (
    <>
      <Headline size="24" title={props.title} />
      {(isLaus ||
        props.contract.contractcategory === 'kjarasamningur' ||
        !data.length) &&
      props.onlyHeildar ? (
        <Empty
          description="Samningur á sér enga sögu"
          style={{ marginBottom: '20px' }}
        />
      ) : (
        <>
          <div
            style={{
              height: '100px',
              border: '1px solid #eee',
              margin: '16px',
              padding: '16px',
            }}
          >
            <h4>
              {props.onlyHeildar
                ? 'Heildarútgáfur'
                : isLaus
                ? 'Heildarútgáfur'
                : 'Lausir samningar'}
            </h4>
            <Select
              onChange={(val) => {
                // Validation for adding element from the select box as well as
                // the add feature itself. Adding the object to the contracts state
                if (isLaus && data.length > 1) {
                  message.error(
                    'Ekki er hægt að framkvæma þessa aðgerð, aðeins hægt að tengja eina heildarútgáfu við lausan samning'
                  );
                } else {
                  const selected = selection.findIndex((c) => val === c.id);

                  const items = selection;
                  const item = selection[selected];
                  item.added = true;
                  items[selected] = item;
                  setSelection(items);

                  const dragItem = {
                    key: item.id,
                    name: item.name,
                    durationPeriodFrom: item.durationperiodfrom,
                    durationPeriodTo: item.durationperiodto,
                    contractCategory:
                      item.contractcategory.charAt(0).toUpperCase() +
                      item.contractcategory.slice(1),
                    added: true,
                  };

                  let isLowerThanHeildar = false;

                  const dataWithSelectedItem = [dragItem, ...data].sort(
                    (a, b) => {
                      !props.onlyHeildar &&
                      new Date(a.durationPeriodFrom) >
                        new Date(b.durationPeriodFrom) &&
                      a.contractCategory === 'Heildarutgafa'
                        ? (isLowerThanHeildar = true)
                        : (isLowerThanHeildar = false);

                      return (
                        new Date(b.durationPeriodFrom) -
                        new Date(a.durationPeriodFrom)
                      );
                    }
                  );

                  if (isLowerThanHeildar) {
                    message.error(
                      'Æjjæjjæjj, ekki hægt að tengja samning sem hefur gildistíma lægri en Heildarútgáfan'
                    );
                  } else {
                    setData(dataWithSelectedItem);
                  }
                }
              }}
              loading={loading}
              showSearch
              placeholder
              value={selection
                .filter((item) => item.added)
                .map((item) => item.id)}
              optionFilterProp="children"
              style={{ width: '50%' }}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ width: '935px' }}
            >
              {selection.map((contract, i) => {
                return (
                  <Select.Option
                    disabled={contract.added}
                    key={i}
                    value={contract.id}
                  >
                    {`(${new Date(
                      contract.durationperiodfrom
                    ).getFullYear()} - ${new Date(
                      contract.durationperiodto
                    ).getFullYear()}) ${contract.name}`}
                  </Select.Option>
                );
              })}
            </Select>
          </div>

          {data.map((contract, i) => {
            return (
              <RowItem
                title={contract.name}
                currentContract={contract.key === props.contract.id}
                dateToFrom={`${moment(contract.durationPeriodFrom).format(
                  'll'
                )} - ${moment(contract.durationPeriodTo).format('ll')}`}
                category={contract.contractCategory}
                removeRow={() => handleDelete(contract)}
                added={contract.added}
                loading={loading}
                id={contract.key}
                key={i}
              />
            );
          })}

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '16px',
            }}
          >
            <Button
              onClick={() => {
                setLoading(true);
                // This is so the ID of heildarútgáfa is the first element of an array
                // at the request of the backend developer/s
                let contractIDs;
                if (!props.onlyHeildar) {
                  const heildar = data.filter(
                    (c) => c.contractCategory === 'Heildarutgafa'
                  )[0]?.key;
                  contractIDs = data.map((contract) => contract.key);
                  contractIDs.splice(contractIDs.indexOf(heildar), 1);
                  contractIDs = [heildar, ...contractIDs];
                } else {
                  contractIDs = data.map((contract) => contract.key);
                }

                Modal.confirm({
                  title: 'Ertu viss?',
                  content: (
                    <>
                      <p>
                        Ekki er hægt að draga aðgerðina til baka eftir að búið
                        er að samþykkja
                      </p>
                    </>
                  ),
                  async onOk() {
                    const jwt = getJwt();
                    const user = getUser();

                    let result;
                    try {
                      result = await fetch(`${fetchlink}/contracts/connectv2`, {
                        method: 'PATCH',
                        headers: {
                          'Content-Type': 'application/json',
                          Accept: 'application/json',
                          Authorization: `Bearer ${jwt.token}`,
                        },
                        body: JSON.stringify({
                          type: props.onlyHeildar ? 'h' : 'k',
                          data: contractIDs,
                          islaus:
                            !props.contract.contractarrid &&
                            !props.contract.groupid
                              ? true
                              : false,
                          user,
                        }),
                      });

                      const response = await result.json();

                      if (!result.ok) {
                        message.error(response.message);
                        setLoading(false);
                      } else {
                        message.success('Samningi breytt!').then(() => {
                          props.onEdit().then(() => {
                            setLoading(false);
                          });
                        });
                      }
                    } catch (err) {
                      message.error('Óþekkt villa');
                      setLoading(false);
                    }
                  },
                  onCancel() {
                    setLoading(false);
                    console.info('Hætt við');
                  },
                });
              }}
              type="primary"
            >
              Samþykkja breytingar
            </Button>
            <Button
              onClick={() => {
                props.onEdit();
              }}
              style={{ marginLeft: '8px' }}
            >
              Draga breytingar til baka
            </Button>
          </div>
        </>
      )}
    </>
  );
};
