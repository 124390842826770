import { useHistory } from 'react-router-dom';

import { PageHeader } from 'antd';
import { Header } from '../../../components/header/Header.jsx';
import { NavBar } from '../../../components/navbar/NavBar.jsx';
import { ContractList } from '../../../components/contractlist/ContractList.jsx';
import './filteredsearchpage.css';

export function FilteredSearchPage() {
  const routerhistory = useHistory();

  return (
    <>
      <NavBar />
      <Header title='Ítarleg leit' />
      <div className='mainSection'>
        <div className='mainContent__contractlist'>
          <PageHeader
            style={{ paddingLeft: 0, width: '100%' }}
            ghost={true}
            onBack={() => window.history.back()}
            title='Til baka'
          />
          <ContractList
            isCollapse={false}
            link={`/contracts/filter${routerhistory.location.search}`}
          />
        </div>
      </div>
    </>
  );
}
