import { useEffect, useState } from 'react';

import './contractlistreplication.scss';
import { Tag, List, Button, message } from 'antd';
import {
  CheckCircleOutlined,
  InfoCircleOutlined,
  FileSearchOutlined,
} from '@ant-design/icons';
import { dateManipulation } from '../../utils/utils.js';
import { fetchlink } from '../../utils/link.js';
import { ContractTag } from '../contracttag/ContractTag.jsx';
import { ContractTagReplication } from '../contracttagreplication/ContractTagReplication';

export function ContractListReplication(props) {
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchContracts(link) {
      const data = await fetch(`${fetchlink}${link}`);

      if (!data.ok) {
        message.error('Ekki tókst að sækja gögn');
      } else {
        const json = await data.json();
        setContracts(json);
      }
      setLoading(false);
    }
    fetchContracts(props.link);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <List
      itemLayout="horizontal"
      dataSource={contracts}
      loading={loading}
      pagination={
        props.isCollapse
          ? false
          : {
            defaultPageSize: 20,
            pageSizeOptions: [10, 20, 50, 100],
          }
      }
      renderItem={(item) => (
        <List.Item className="dropdownListItem">
          <List.Item.Meta
            avatar={
              <>
                <Button
                  style={{ border: 0 }}
                  shape="circle"
                  size="large"
                  icon={
                    <FileSearchOutlined
                      style={{ fontSize: '22px', color: '#02529c' }}
                    />
                  }
                  href={item.contractlink}
                  target="_blank"
                  rel="norefferer"
                />
              </>
            }
            title={
              <>
                <a href={`/kjara/contracts/${item.id}`}>{item.name}</a>
                {item.iswritecontrol ? (
                  <Tag
                    icon={<CheckCircleOutlined />}
                    style={{ marginLeft: '0.5rem' }}
                    color="cyan"
                  >
                    Ritstýrður samningur
                  </Tag>
                ) : item.contractcategory === 'heildarutgafa' ? (
                  <Tag
                    icon={<CheckCircleOutlined />}
                    style={{ marginLeft: '0.5rem' }}
                    color="success"
                  >
                    Heildarútgáfa
                  </Tag>
                ) : (
                  <></>
                )}
              </>
            }
            description={
              <>
                <p>
                  Gildistími: Frá{' '}
                  <span style={{ color: '#02529c', fontWeight: 500 }}>
                    {dateManipulation(item.durationperiodfrom)}
                  </span>{' '}
                  til{' '}
                  <span style={{ color: '#02529c', fontWeight: 500 }}>
                    {item.durationperiodto ? dateManipulation(item.durationperiodto) : 'Ótímabundinn'}
                  </span>
                </p>
                <ContractTagReplication link={''} id={item.id} />
              </>
            }
          />
          <div>
            <Button
              href={`/kjara/contracts/${item.id}`}
              shape="round"
              icon={<InfoCircleOutlined />}
              type="primary"
            >
              Sjá meira
            </Button>
          </div>
        </List.Item>
      )}
    />
  );
}
