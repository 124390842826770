import React, { useState, useEffect } from 'react';

import { Pagination } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';
import { message, Tabs, Table, Typography, Select, Empty } from 'antd';
import Chart from 'react-apexcharts';

import { fetchlink } from '../../../utils/link';
import moment from 'moment';
import { RSSChart } from '../../../components/piecharts/rsschart/RSSChart';
import { NewContractsChart } from '../../../components/piecharts/newcontractschart/NewContractsChart';
import { MiniTable } from '../../../components/minitable/MiniTable';

export const StatsReplication = (props) => {
  const [loading, setLoading] = useState(true);
  const [contracts, setContracts] = useState([]);
  const [chartData, setChartData] = useState();
  const [months, setMonths] = useState(6);
  const [nrOfContracts, setNrOfContracts] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [tab, setTab] = useState('free');
  const [fetchOptions, setFetchOptions] = useState({ limit: 20, offset: 1 });

  useEffect(() => {
    async function fetchData() {
      const data = await fetch(
        `${fetchlink}/contracts/free/calc?months=${months}`
      );
      const json = await data.json();

      if (!data.ok) {
        message.error('Engin gögn fundust');
      } else {
        setNrOfContracts(json);
        setChartData({
          series: [json.duenow, json.yes, json.no, json.inconclusive],
          options: {
            chart: {
              type: 'pie',
            },
            legend: {
              show: false,
            },
            colors: ['#048f6a', '#02529c', '#eda7af', '#A7EDE5'],
            labels: [
              'Útrunnir',
              'Renna út',
              'Renna ekki út',
              'Ótímabundir',
            ],
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 150,
                  },
                  legend: {
                    position: 'bottom',
                  },
                },
              },
            ],
          },
        });
      }
    }

    if (months) {
      setLoading(true);
      fetchData().then(() => {
        fetchContracts().then(() => {
          setLoading(false);
        });
      });
    }
  }, [months]);

  useEffect(() => {
    if (tab && fetchOptions) {
      setLoading(true);
      fetchContracts().then(() => {
        setLoading(false);
      });
    }
  }, [tab, fetchOptions]);

  async function fetchContracts() {
    const data = await fetch(
      `${fetchlink}/contracts/${tab}?months=${months}&limit=${fetchOptions.limit}&offset=${fetchOptions.offset}`
    );

    if (!data.ok) {
      message.error('Villa kom upp þegar gögn voru sótt');
    } else {
      const json = await data.json();
      setTotalRows(json.total);
      setContracts(json.data);
    }
  }

  const columns = [
    {
      title: 'Stéttarfélag',
      dataIndex: 'unionname',
      width: '25%',
      render: (_, record, i) => (
        <Typography.Link key={i} href={`/kjara/unions/${record.unionid}`}>
          {record.unionname}
        </Typography.Link>
      ),
    },
    {
      title: 'Launagreiðendur',
      dataIndex: 'employername',
      width: '25%',
      render: (_, record, i) =>
        record.employers.map((employer, i) => {
          return (
            <>
              <Typography.Link
                key={i}
                href={`/kjara/employers/${record.employerid[i]}`}
              >
                {employer}
              </Typography.Link>
              {i < record.employers.length - 1 && <Typography.Text strong> | </Typography.Text>}
            </>
          );
        }),
    },

    {
      title: 'Samningur',
      dataIndex: 'contractname',
      width: '40%',
      editable: true,
      render: (_, record, i) => (
        <Typography.Link key={i} href={`/kjara/contracts/${record.contractid}`}>
          {record.contractname}
        </Typography.Link>
      ),
    },
    {
      title: 'Dags. undirritunar',
      dataIndex: 'signaturedate',
      editable: true,
      render: (_, record) =>
        record.signaturedate == null
          ? 'Á ekki við'
          : moment(record.signaturedate).format('ll'),
      sorter: (a, b) =>
        moment(a.signaturedate).unix() - moment(b.signaturedate).unix(),
      responsive: ['lg'],
    },
    {
      title: 'Gildir til',
      dataIndex: 'durationperiodto',
      editable: true,
      render: (_, record) =>
        record.durationperiodto
          ? moment(record.durationperiodto).format('ll')
          : 'Ótímabundinn',
      sorter: (a, b) =>
        moment(a.durationperiodto).unix() - moment(b.durationperiodto).unix(),
    },
  ];

  const handleSelect = (value) => {
    setMonths(value);
  };

  const handleTabClick = (tabKey) => {
    setFetchOptions({ offset: 1, limit: fetchOptions.limit });
    switch (tabKey) {
      case '1':
        setTab('alreadydue');
        break;
      case '2':
        setTab('free');
        break;
      case '3':
        setTab('notfree');
        break;
      case '4':
        setTab('inconclusive');
        break;
    }
  };

  const handlePagination = (offs, lim) => {
    if (lim !== fetchOptions.limit) {
      setFetchOptions({ limit: lim, offset: 1 });
    } else {
      setFetchOptions({ limit: lim, offset: offs });
    }
  };

  const monthSelection = [1, 3, 6, 9, 12];

  return (
    <>
      <div style={{ margin: 0, padding: '40px 0' }} className="statsPage">
        <div className="statsTopSection">
          <div className="statsMainCard">
            <div className="mainCardTitleContainer">
              <h3 className="mainCardTitle">
                Fjöldi samninga sem renna út innan{' '}
                {months === 1 ? 'mánaðar' : `${months} mánaða`}{' '}
              </h3>
            </div>

            <div className="mainCardContent">
              <div className="mainCardContentContainer">
                <div className="mainChartLabel">
                  <div
                    className="mainChartLabelDot"
                    style={{ backgroundColor: '#048f6a' }}
                  />
                  <div className="mainCardDataContainer">
                    <p className="mainChartLabelText">Útrunnir:</p>
                    {!loading ? (
                      <span className="chartNumberData">
                        {nrOfContracts?.duenow}
                      </span>
                    ) : (
                      '---'
                    )}
                  </div>
                </div>

                <div className="mainChartLabel">
                  <div
                    className="mainChartLabelDot"
                    style={{ backgroundColor: '#02529c' }}
                  />
                  <div className="mainCardDataContainer">
                    <p className="mainChartLabelText">Renna út:</p>
                    {!loading ? (
                      <span className="chartNumberData">
                        {nrOfContracts?.yes}
                      </span>
                    ) : (
                      '---'
                    )}
                  </div>
                </div>

                <div
                  className="mainChartLabel"
                >
                  <div
                    className="mainChartLabelDot"
                    style={{ backgroundColor: '#eda7af' }}
                  />
                  <div className="mainCardDataContainer">
                    <p className="mainChartLabelText">Renna ekki út: </p>
                    {!loading ? (
                      <span className="chartNumberData">
                        {nrOfContracts?.no}
                      </span>
                    ) : (
                      '---'
                    )}
                  </div>
                </div>

                <div
                  className="mainChartLabel"
                  style={{ borderBottom: 'dotted 1px #cdcdcd' }}
                >
                  <div
                    className="mainChartLabelDot"
                    style={{ backgroundColor: '#A7EDE5' }}
                  />

                  <div className="mainCardDataContainer">
                    <p className="mainChartLabelText">Ótímabundir: </p>
                    {!loading ? (
                      <span className="chartNumberData">
                        {nrOfContracts?.inconclusive}
                      </span>
                    ) : (
                      '---'
                    )}
                  </div>
                </div>

                <div
                  className="mainChartLabel"
                  style={{ paddingBottom: '10px' }}
                >
                  <div
                    className="mainChartLabelDot"
                    style={{ backgroundColor: 'white' }}
                  />

                  <div className="mainCardDataContainer">
                    <p className="mainChartLabelText">Samtals: </p>
                    {!loading ? (
                      <span className="chartNumberData">
                        {nrOfContracts?.no +
                          nrOfContracts?.yes +
                          nrOfContracts?.inconclusive +
                          nrOfContracts?.duenow}
                      </span>
                    ) : (
                      '---'
                    )}
                  </div>
                </div>

                <div className="mainCardSelectionContainer">
                  <h4 style={{ margin: 0, fontSize: '16px' }}>
                    Fjöldi mánaða:{' '}
                  </h4>
                  <Select
                    className="monthSelection"
                    placeholder
                    optionFilterProp="children"
                    defaultValue={6}
                    onSelect={handleSelect}
                    loading={loading}
                    dropdownMatchSelectWidth={110}
                    size="middle"
                  >
                    {monthSelection.map((m) => {
                      return (
                        <Select.Option key={m} value={m}>
                          {m === 1 ? `${m} mánuður` : `${m} mánuðir`}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
              </div>

              <div className="mainCardChartContainer">
                {loading ? (
                  <LoadingOutlined
                    style={{ fontSize: 150, color: '#02529c' }}
                    spin
                  />
                ) : nrOfContracts?.total === 0 || !chartData ? (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="Engir samningar renna út á völdu tímabili"
                  />
                ) : (
                  <Chart
                    height="100%"
                    options={chartData.options}
                    series={chartData.series}
                    type="pie"
                  />
                )}
              </div>
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <p className='chartDisclaimer'>Þegar valinn er fjöldi mánaða í glugganum hér fyrir ofan, birtast þeir samningar sem renna út á tímabilinu í töflunni hér fyrir neðan</p>
            </div>
          </div>

          <div className="statsPieChartsCards">
            <RSSChart
              datalink="/contracts/rss/calc"
              title="Hlutfall samninga sem vísað var til Ríkissáttasemjara"
              showLegend={false}
              admin={false}
            />
            <NewContractsChart
              datalink="/contracts/all/replace/active/calc"
              title="Hlutfall samninga þar sem nýr samningur tekur við af eldri, áður en sá eldri rennur út"
              showLegend={false}
              reverse
              displayDetails={false}
            />
          </div>
        </div>

        <Tabs
          onTabClick={handleTabClick}
          className="statsTable"
          centered
          defaultActiveKey="2"
        >
          <Tabs.TabPane tab="Útrunnir" key="1">
            <Table
              pagination={false}
              bordered
              dataSource={contracts}
              columns={columns}
              loading={loading}
              rowKey={(record) => {
                return record.unionid + record.contractid.toString();
              }}
            />

            <Pagination
              style={{ padding: '20px', float: 'right' }}
              total={totalRows}
              pageSizeOptions={[10, 20, 50, 100]}
              defaultPageSize={fetchOptions.limit}
              current={fetchOptions.offset}
              onChange={handlePagination}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={`Renna út innan ${months === 1 ? '1 mánaðar' : `${months} mánaða`
              }`}
            key="2"
          >
            <Table
              pagination={false}
              bordered
              dataSource={contracts}
              columns={columns}
              loading={loading}
              rowKey={(record) => {
                return record.unionid + record.contractid.toString();
              }}
            />

            <Pagination
              style={{ padding: '20px', float: 'right' }}
              total={totalRows}
              pageSizeOptions={[10, 20, 50, 100]}
              defaultPageSize={fetchOptions.limit}
              current={fetchOptions.offset}
              onChange={handlePagination}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Renna ekki út" key="3">
            <Table
              pagination={false}
              bordered
              dataSource={contracts}
              columns={columns}
              loading={loading}
              rowKey={(record) => {
                return record.unionid + record.contractid.toString();
              }}
            />

            <Pagination
              style={{ padding: '20px', float: 'right' }}
              total={totalRows}
              pageSizeOptions={[10, 20, 50, 100]}
              defaultPageSize={fetchOptions.limit}
              current={fetchOptions.offset}
              onChange={handlePagination}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Ótímabundir samningar" key="4">
            <Table
              pagination={false}
              bordered
              dataSource={contracts}
              columns={columns}
              loading={loading}
              rowKey={(record) => {
                return record.unionid + record.contractid.toString();
              }}
            />

            <Pagination
              style={{ padding: '20px', float: 'right' }}
              total={totalRows}
              pageSizeOptions={[10, 20, 50, 100]}
              defaultPageSize={fetchOptions.limit}
              // defaultCurrent={1}
              current={fetchOptions.offset}
              onChange={handlePagination}
            />
          </Tabs.TabPane>
          {/* <Tabs.TabPane tab="Meiri Tölfræði" key="5">
            <MiniTable
              title="Fjöldi kjarasamninga eftir launagreiðendum"
              fetchRoute="/contracts/contractsinemployer/calc"
              tableHeaders={[
                'Atvinnurekendur',
                'Fjöldi launafólks á launaskrá',
                'Hlutfallsleg skipting',
              ]}
              tableRecordIndexes={['launagreiðandi', 'Fjöldi Samninga']}
            />

            <MiniTable
              title="Fjöldi kjarasamningar eftir heildarsamtökum"
              fetchRoute="/contracts/contractsinwholeorg/calc"
              tableHeaders={[
                'Atvinnurekendur',
                'Fjöldi launafólks á launaskrá',
                'Hlutfalssleg skipting',
              ]}
              tableRecordIndexes={['heildarsamtök', 'Fjöldi Samninga']}
            />
          </Tabs.TabPane> */}
        </Tabs>
      </div>
    </>
  );
};
