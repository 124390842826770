import './notfound.css';

import notFoundImg from '../../images/notfound.png';
import { NavBar } from '../navbar/NavBar.jsx';
import { Button } from 'antd';

export function NotFound() {
  return (
    <>
      <NavBar />
      <div className='notfound_section'>
        <div className='notfound_container'>
          <h1 className='notfound_title'>Síða ekki til</h1>
          <img className='notfound_img' src={notFoundImg} alt='' />
          <Button href='/' size='large' type='primary' className='notfound-btn'>
            Til baka á forsíðu
          </Button>
        </div>
      </div>
    </>
  );
}
