import { useHistory } from 'react-router-dom';

import { PageHeader } from 'antd';
import { Header } from '../../../components/header/Header.jsx';
import { AdminNavBar } from '../../../components/adminnavbar/AdminNavBar.jsx';
import { AdminContractList } from '../../../components/admincontractlist/AdminContractList.jsx';
import './adminfilteredsearchpage.css';

export function AdminFilteredSearchPage(props) {
  const routerhistory = useHistory();

  return (
    <>
      <AdminNavBar isAuthenticated={props.isAuthenticated} />
      <Header title='Ítarleg leit' />
      <div className='contractsSection'>
        <div className='contractsContainer'>
          <PageHeader
            style={{ paddingLeft: 0, width: '100%' }}
            ghost={true}
            onBack={() => window.history.back()}
            title='Til baka'
          />
          <AdminContractList
            isCollapse={false}
            link={`/contracts/filter${routerhistory.location.search}`}
          />
        </div>
      </div>
    </>
  );
}
