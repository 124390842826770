import { useState, useEffect } from 'react';

import { Tabs } from 'antd';
import { fetchlink } from '../../../utils/link.js';

import { TabContent } from '../../../components/tabcontent/TabContent.jsx';
import { ContractCollapse } from '../../../components/contractcollapse/ContractCollapse.jsx';
import { Preferences } from '../../../components/preferences/Preferences.jsx';
import { NavBar } from '../../../components/navbar/NavBar.jsx';
import { Hero } from '../../../components/hero/Hero.jsx';

export function Home() {
  const [unions, setUnions] = useState([]);
  const [employers, setEmployers] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState('');
  const [tabNumber, setTabNumber] = useState(1);
  const [orderBy, setOrderBy] = useState('name');
  const [noContracts, setNoContracts] = useState(false);
  // const [offset, setOffset] = useState(1);
  // const [limit, setLimit] = useState(20);

  useEffect(() => {
    if (tabNumber === 1) {
      fetchByKeyword(value);
    } else if (tabNumber === 2) {
      fetchByKeyword(value);
    } else if (tabNumber === 3) {
      fetchByKeyword(value);
    }
  }, [tabNumber]);

  useEffect(() => {
    tabNumber === 1
      ? fetchByKeyword(value)
      : tabNumber === 2
      ? fetchByKeyword(value)
      : fetchByKeyword(value);
  }, [orderBy]);

  async function fetchData(link) {
    return await fetch(`${fetchlink}${link}`);
  }

  async function fetchByKeyword(query, offset, limit) {
    setLoading(true);
    let tab = '';
    if (tabNumber === 1) tab = 'unions';
    if (tabNumber === 2) tab = 'employers';
    if (tabNumber === 3) tab = 'contractgroup';
    const data = await fetchData(
      `/contracts/all?keyword=${query}&type=${tab}&issingle=true&agecategory=new&orderby=${orderBy}`
    );

    // const geymsla = `&offset=${offset || 1}&limit=${limit || 20}`;
    const json = await data.json();
    if (tab === 'unions') setUnions(json);
    if (tab === 'employers') setEmployers(json);
    if (tab === 'contractgroup') setContracts(json);
    setLoading(false);
  }

  return (
    <>
      <NavBar />
      <Hero />
      <div className="mainSection">
        <div className="mainContainer">
          <Preferences
            tabNumber={tabNumber}
            oldcontractslink="/oldcontracts"
            onSortSelect={(e) => {
              setOrderBy(e);
            }}
            onTextInput={(e) => {
              fetchByKeyword(e);
              setValue(e);
            }}
          />
          <Tabs
            onTabClick={(e) => setTabNumber(parseInt(e))}
            className="mainContent"
            centered
            defaultActiveKey="1"
          >
            <Tabs.TabPane tab="Stéttarfélög" key="1">
              <TabContent
                items={unions}
                contentType="unions"
                loading={loading}
                changeOffsetLimit={(offset, limit) => {
                  // setOffset(offset);
                  // setLimit(limit);
                  fetchByKeyword(value, offset, limit);
                }}
              />
            </Tabs.TabPane>

            <Tabs.TabPane tab="Launagreiðendur" key="2">
              <TabContent
                items={employers}
                contentType="employers"
                loading={loading}
              />
            </Tabs.TabPane>

            <Tabs.TabPane tab="Gildandi kjarasamningar" key="3">
              <ContractCollapse
                loading={loading}
                isHomePage={true}
                data={contracts}
              />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    </>
  );
}
