const months = [
  'janúar',
  'febrúar',
  'mars',
  'apríl',
  'maí',
  'júní',
  'júlí',
  'ágúst',
  'september',
  'október',
  'nóvember',
  'desember',
];

/**
 * Function sem tekur inn date og skilar streng með
 * dagsetningu á íslensku formati. Dæmi:
 ** dateManipulation('Fri Aug 20 2021 10:08:17 GMT+0000')
 ** returns: '20. ágúst 2021'
 * @param {Date Object} dateStr
 * @returns {String}
 */
export const dateManipulation = (dateStr) => {
  const time = new Date(Date.parse(dateStr)).getTime();
  const date = new Date(time);
  const year = date.getFullYear();
  const month = months[date.getMonth()];
  const day = date.getDate();

  return `${day}. ${month} ${year}`;
};

/**
 * Tekur inn tvö Date object og skilar tölu sem
 * segir til um hve mikið er búið af tímabilinu (í prósentum).
 * Miðast við daginn í dag.
 * @param {Date} dateFrom
 * @param {Date} dateTo
 * @returns {int}
 */
export const dateProgress = (dateFrom, dateTo) => {
  const from = new Date(Date.parse(dateFrom)).getTime();

  const nowDividedByFrom = (
    (new Date().getTime() - from) /
    (1000 * 60 * 60 * 24)
  ).toFixed(1);

  const fromPlusTo =
    (new Date(Date.parse(dateTo)).getTime() -
      new Date(Date.parse(dateFrom)).getTime()) /
    (1000 * 60 * 60 * 24);

  return parseFloat((nowDividedByFrom / fromPlusTo) * 100).toFixed();
};

/**
 * Function sem tekur inn date og skilar streng með
 * dagsetningu á "venjulegu" formati.
 * @param {Date} dateStr
 * @returns {String}
 */
export const dateFormat = (dateStr) => {
  const time = new Date(Date.parse(dateStr)).getTime();
  const date = new Date(time);
  const year = date.getFullYear();
  const month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();

  return `${day}.${month}.${year}`;
};
