import './adminnavbar.css';

import logo from '../../images/navlogo.png';
import mobileLogo from '../../images/rsslogo.png';

import { Menu, Dropdown, Button } from 'antd';
import {
  UserOutlined,
  HomeOutlined,
  ArrowRightOutlined,
  FileAddOutlined,
  FileAddFilled,
  UserAddOutlined,
  DatabaseOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { logout } from '../../auth/auth';

const menu = (
  <Menu
    items={[
      {
        key: 'home',
        icon: <HomeOutlined />,
        label: <a href="/admin">Heim</a>,
      },
      {
        key: 'addNewContract',
        icon: <FileAddOutlined />,
        label: <a href="/admin/addcontract">Skrá nýjan samning</a>,
      },
      {
        key: 'addOldContract',
        icon: <FileAddFilled />,
        label: <a href="/admin/addoldcontract">Skrá gamlan samning</a>,
      },
      {
        key: 'contractWorkspace',
        icon: <DatabaseOutlined />,
        label: <a href="/admin/editcontract/all">Vinnusvæði samninga</a>,
      },
      {
        key: 'createUser',
        icon: <UserAddOutlined />,
        label: <a href="/user/register">Skrá nýjan notanda</a>,
      },
      {
        type: 'divider',
      },
      {
        key: 'logOut',
        label: (
          <>
            <Button block type="primary" onClick={logout}>
              Skrá út <ArrowRightOutlined />
            </Button>
          </>
        ),
      },
    ]}
  />
);

export const AdminNavBar = () => {
  return (
    <div className="bar">
      <div className="navLogoContainer">
        <Link to="/admin">
          <img src={logo} alt="logo" className="navbarLogo" />
          <img src={mobileLogo} alt="logo" className="logoMobile" />
        </Link>
      </div>
      <div className="navLinksContainer">
        <Dropdown
          overlay={menu}
          placement="bottomLeft"
          arrow
          trigger={['click']}
        >
          <Button
            size="large"
            shape="circle"
            type="primary"
            icon={<UserOutlined />}
            style={{ marginRight: '1.25rem' }}
          ></Button>
        </Dropdown>
      </div>
    </div>
  );
};
