import React, { useState } from 'react';
import './login.css';

import { Form, Input, Button, message } from 'antd';
import { Header } from '../../components/header/Header.jsx';
import { NavBar } from '../../components/navbar/NavBar.jsx';
import { login } from '../auth.js';
import { useHistory } from 'react-router-dom';
import ForgotPassword from '../forgotpassword/ForgotPassword';

export const Login = () => {
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const history = useHistory();

  const onFinish = async (values) => {
    setLoading(true);
    const result = await login(values);

    setLoading(false);
    if (!result.ok) {
      message.error(result.error);
    } else {
      history.push('/admin');
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };

  const showModal = () => {
    setModal(true);
  };

  const handleCancel = () => {
    setModal(false);
  };

  return (
    <>
      <NavBar />
      <Header title='Innskráning' />
      <div className='loginSection'>
        <div className='loginContainer'>
          <Form
            layout='vertical'
            className='loginForm'
            name='login'
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label='Notendanafn'
              className='loginItem'
              name='username'
              rules={[
                { required: true, message: 'Please input your username!' },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label='Lykilorð'
              className='loginItem'
              name='password'
              style={{ marginBottom: 0 }}
              rules={[
                { required: true, message: 'Please input your password!' },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Button
              className='loginItem'
              onClick={showModal}
              style={{ marginTop: 0, paddingTop: 0 }}
              type='link'
            >
              Gleymt lykilorð
            </Button>

            <ForgotPassword
              closeModal={() => setModal(false)}
              handleCancel={() => handleCancel}
              modal={modal}
            />

            <Form.Item className='loginItem'>
              <Button
                size='large'
                className='loginBtn'
                type='primary'
                htmlType='submit'
                loading={loading}
              >
                Skrá inn
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};
