

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './filteredsearch.css';
import { RightOutlined } from '@ant-design/icons';

import {
  Form,
  Button,
  Radio,
  DatePicker,
  Select,
  Typography,
  Divider,
  Modal,
  message,
} from 'antd';
import { fetchlink } from '../../../utils/link.js';

const hardcodedHeildarEmployers = [
  {
    id: 1,
    name: 'Ríkissjóður, SNR'
  },
  {
    id: 2,
    name: 'Samtök Atvinnulífsins, SA'
  },
  {
    id: 3,
    name: 'Samband íslenskra sveitarfélaga, SNS'
  },
  {
    id: 4,
    name: 'Reykjavíkurborg'
  },
  {
    id: 5,
    name: 'Samtök Fyrirtækja í velferðarþjónustu, SFV'
  },
]

export const FilteredSearch = (props) => {
  const routerHistory = useHistory();
  const [form] = Form.useForm();
  const [modal, setModal] = useState(false);
  const [wholeorg, setWholeorg] = useState([]);
  const [unions, setUnions] = useState([]);
  const [employers, setEmployers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (modal) {
      async function fetchData() {
        setLoading(true);
        const result = await fetch(`${fetchlink}/wholeorg`);

        Promise.all([
          fetch(`${fetchlink}/wholeorg`),
          fetch(`${fetchlink}/unions/asc`),
          fetch(`${fetchlink}/employers/asc`),
        ])
          .then(async ([allWholeorgs, allUnions, allEmployers]) => {
            const wholeorgJson = await allWholeorgs.json();
            const unionJson = await allUnions.json();
            const employerJson = await allEmployers.json();

            setWholeorg(wholeorgJson);
            setUnions(unionJson);
            setEmployers(employerJson);
          })
          .catch((err) => {
            console.error(err);
          });

        if (!result.ok) {
          setError('Ekki tókst að sækja gögn');
        } else {
          const json = await result.json();
          setWholeorg(json);
        }
        setLoading(false);
      }
      fetchData();
    }
  }, [modal]);

  function isUndefined(obj) {
    for (var key in obj) {
      if (obj[key] !== undefined && obj[key] !== '') return false;
    }
    return true;
  }

  const onFinish = async (values) => {
    if (values.selectedEmployerWholeorg) {
      if (!values.selectedEmployer) {
        values.selectedEmployer = [values.selectedEmployerWholeorg];
      } else {

        !values.selectedEmployer.includes(values.selectedEmployerWholeorg) && values.selectedEmployer.push(values.selectedEmployerWholeorg)
      }
    }

    let str = '?';
    if (isUndefined(values)) {
      message.info('Verður að fylla inn í a.m.k einn reit');
      return;
    }

    for (const [key, value] of Object.entries(values)) {
      if (value === undefined) continue;
      str += `${key}=${value}&`;
    }

    try {
      props.isAuthenticated
        ? routerHistory.push({
          pathname: '/admin/filtered-search',
          search: str,
        })
        : routerHistory.push({
          pathname: '/filtered-search',
          search: str,
        });
    } catch (error) {
      console.error('Eitthvað fór úrskeiðis --> ', error);
    }
  };

  const showModal = () => {
    setModal(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setModal(false);
  };

  return (
    <>
      <Button
        icon={<RightOutlined />}
        type='link'
        onClick={showModal}
        className='filterBtn'
      >
        Ítarleg leit
      </Button>
      <Modal
        title='Ítarleg leit'
        visible={modal}
        okText='Leita'
        cancelText='Hætta við'
        okButtonProps={{ htmlType: 'submit' }}
        onOk={form.submit}
        onCancel={handleCancel}
        width={900}
      >
        <Form
          layout='vertical'
          method='GET'
          onFinish={onFinish}
          className='filteredSearchForm'
          form={form}
        >
          <div className='leftFilterContainer'>
            <div className='unionsEmployersContainer'>
              <Form.Item
                className='unionsSelection'
                label='Stéttarfélög'
                name='selectedUnion'
              >
                <Select
                  style={{ width: '100%' }}
                  loading={loading}
                  showSearch
                  mode='multiple'
                  optionFilterProp='children'
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ width: '390px' }}
                >
                  {unions.map((item) => {
                    return (
                      <Select.Option key={item.id} value={item.unionid}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                className='employersSelection'
                label='Launagreiðendur'
                name='selectedEmployer'
              >
                <Select
                  style={{ width: '100%' }}
                  loading={loading}
                  showSearch
                  mode='multiple'
                  optionFilterProp='children'
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ width: '300px' }}
                >
                  {employers.map((item) => {
                    return (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>

            {/* <Form.Item
              name="durationDate"
              className="filterFormItem"
              label="Leita á tímabili gildistíma:"
            >
              <DatePicker.RangePicker
                style={{ width: '70%' }}
                placeholder={['Frá', 'Til']}
              />
            </Form.Item> */}

            <div>
              <p>Gildistími:</p>

              <div className='filterFormItem'>
                <Form.Item
                  name='durationperiodfrom'
                  style={{ marginRight: '32px' }}
                >
                  <DatePicker disabled={loading} placeholder='Frá' />
                </Form.Item>

                <Form.Item name='durationperiodto'>
                  <DatePicker disabled={loading} placeholder='Til' />
                </Form.Item>
              </div>
            </div>

            <Form.Item
              name='typeOfMarket'
              className='filterFormItem'
              label='Markaður'
            >
              <div>
                <Radio.Group>
                  <Radio.Button disabled={loading} value='Almennur'>
                    Almennum markaði
                  </Radio.Button>
                  <Radio.Button disabled={loading} value='Opinber'>
                    Opinberum markaði
                  </Radio.Button>
                </Radio.Group>
              </div>
            </Form.Item>

            <Form.Item
              name='isactive'
              className='filterFormItem'
              label='Leita í gildandi/öllum samningum:'
            >
              <div>
                <Radio.Group>
                  <Radio.Button disabled={loading} value='true'>
                    Gildandi samningum
                  </Radio.Button>
                  <Radio.Button disabled={loading} value='false'>
                    Öllum samningum
                  </Radio.Button>
                </Radio.Group>
              </div>
            </Form.Item>
          </div>
          <div className='rightFilterContainer'>
            <Form.Item
              name='contractCategory'
              label='Tegund samnings:'
              className='filterFormItem'
            >
              <Radio.Group>
                <Radio.Button value='heildarutgafa'>Heildarútgáfa</Radio.Button>
                <Radio.Button value='kjarasamningur'>
                  Kjarasamningur
                </Radio.Button>
              </Radio.Group>
            </Form.Item>

            {/* <Form.Item
              name='signatureDate'
              className='filterFormItem'
              label='Leita á tímabili undirskrifta:'
            >
              <DatePicker.RangePicker
                style={{ width: '100%' }}
                placeholder={['Frá', 'Til']}
              />
            </Form.Item> */}

            <div>
              <p>Leita á tímabili dagsetningu undirskriftar:</p>
              <div className='filterFormItem'>
                <Form.Item
                  name='signaturedatefrom'
                  style={{ marginRight: '32px' }}
                >
                  <DatePicker disabled={loading} placeholder='Frá' />
                </Form.Item>

                <Form.Item name='signaturedateto'>
                  <DatePicker disabled={loading} placeholder='Til' />
                </Form.Item>
              </div>
            </div>

            <Form.Item label='Heildarsamtök launafólks' name='selectedWholeorg'>
              <Select
                style={{ width: '100%' }}
                loading={loading}
                showSearch
                optionFilterProp='children'
              >
                {wholeorg.map((item) => {
                  return (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item label='Heildarsamtök launagreiðenda' name='selectedEmployerWholeorg'>
              <Select
                style={{ width: '100%' }}
                loading={loading}
                showSearch
                optionFilterProp='children'
              >
                {hardcodedHeildarEmployers.map((item) => {
                  return (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
        </Form>
        <Divider />
        <Typography.Text type='secondary'>
          Ekki þarf að fylla inn í alla reiti, aðeins þá sem notandi kýs.
        </Typography.Text>
      </Modal>
    </>
  );
};

