import React, { useState, useEffect } from 'react';

import { message, Table, Typography,  } from 'antd';

import { fetchlink } from '../../utils/link.js';

export const MiniTable = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState(null);

  useEffect(() => {
    setLoading(true);
    fetchData().then(() => {
      setLoading(false);
    });
  }, []);

  async function fetchData() {
    const data = await fetch(`${fetchlink}${props.fetchRoute}`);

    if (!data.ok) {
      message.error('Villa kom upp þegar gögn voru sótt');
    } else {
      const json = await data.json();
      const last = json.pop();

      setSummary(last);
      setData(json);
    }
  }

  const columns = [
    {
      title: props.tableHeaders[0],
      dataIndex: 'unionname',
      width: '60%',
      render: (_, record, i) => (
        <Typography.Text key={i} href={`#`}>
          {record[props.tableRecordIndexes[0]]}
        </Typography.Text>
      ),
    },
    {
      title: props.tableHeaders[1],
      dataIndex: 'contractCount',
      width: '20%',
      render: (_, record, i) => (
        <Typography.Text key={i} href={`#`}>
          {record[props.tableRecordIndexes[1]]}
        </Typography.Text>
      ),
    },
    {
      title: props.tableHeaders[2],
      dataIndex: 'percentage',
      width: '20%',
      render: (_, record, i) => (
        <Typography.Text key={i}>{`${(
          (record[props.tableRecordIndexes[1]] / summary['Fjöldi Samninga']) *
          100
        ).toFixed(1)}%`}</Typography.Text>
      ),
    },
  ];

  return (
    // <Table
    //   pagination={false}
    //   bordered
    //   dataSource={contracts}
    //   columns={columns}-
    //   loading={loading}
    //   rowKey={(record) => {
    //     return record.unionid + record.contractid.toString();
    //   }}
    // />
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h2
          style={{
            width: '70%',
            margin: '16px 16px 0 16px',
          }}
        >
          {props.title}
        </h2>
        <Table
          style={{ width: '70%', height: '90%', margin: '0 16px 16px 16px' }}
          pagination={false}
          bordered
          dataSource={data}
          columns={columns}
          loading={loading}
          size="small"
          summary={(_, record, i) => {
            return (
              <>
                <Table.Summary.Row
                  style={{ backgroundColor: '#fafafa' }}
                  key={i}
                >
                  <Table.Summary.Cell index={0}>
                    <Typography.Text strong>Samtals</Typography.Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <Typography.Text strong>
                      {summary?.['Fjöldi Samninga']}
                    </Typography.Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <Typography.Text strong>100%</Typography.Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </div>
    </>
  );
};
