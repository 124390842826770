import 'antd/dist/antd.css';
import './App.scss';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { Home } from './pages/frontend/home/Home.jsx';
import { AddContract } from './pages/admin/addcontract/AddContract.jsx';
import { UnionPage } from './pages/frontend/unionpage/UnionPage.jsx';
import { ContractPage } from './pages/frontend/contractpage/ContractPage.jsx';
import { EmployerPage } from './pages/frontend/employerpage/EmployerPage.jsx';
import { AdminHome } from './pages/admin/adminhome/AdminHome.jsx';
import { NotFound } from './components/notfound/NotFound';
import { AdminContractPage } from './pages/admin/admincontractpage/AdminContractPage.jsx';
import { AddOldContract } from './pages/admin/addoldcontract/AddOldContract.jsx';
import { OldContractsPage } from './pages/frontend/oldcontractspage/OldContractsPage.jsx';
import { FilteredSearchPage } from './pages/frontend/filteredsearchpage/FilteredSearchPage.jsx';
import { AdminFilteredSearchPage } from './pages/admin/adminfilterdsearchpage/AdminFilteredSearchPage.jsx';
import { EditContract } from './pages/admin/editcontract/EditContract';
import { AdminOldContractsPage } from './pages/admin/adminoldcontractspage/AdminOldContractPage.jsx';
import { Register } from './auth/register/Register.jsx';
import { Login } from './auth/login/Login.jsx';
import { NewPassword } from './auth/newpassword/NewPassword.jsx';
import PrivateRoute from './auth/isLogin/isLogin.jsx';
import { EditAllContracts } from './pages/admin/editallcontracts/EditAllContracts';
import { Stats } from './pages/frontend/stats/Stats';
import { AdminStats } from './pages/admin/adminstats/AdminStats';
import { HomeReplication } from './pages/replication/homereplication/HomeReplication';
import { StatsReplication } from './pages/replication/statsreplication/StatsReplication';
import { UnionPageReplication } from './pages/replication/unionpagereplication/UnionPageReplication';
import { EmployerPageReplication } from './pages/replication/employerpagereplication/EmployerPageReplication';
import { ContractPageReplication } from './pages/replication/contractpagereplication/ContractPageReplication';
import { OldContractsPageReplication } from './pages/replication/oldcontractspagereplication/OldContractsPageReplication';
import { FilteredSearchPageReplication } from './pages/replication/filteredsearchpagereplication/FilteredSearchPageReplication';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/kjara" component={HomeReplication} />
        <Route exact path="/stats" component={Stats} />
        <Route exact path="/kjara/stats" component={StatsReplication} />
        <Route path="/unions/:id" component={UnionPage} />
        <Route path="/kjara/unions/:id" component={UnionPageReplication} />
        <Route path="/employers/:id" component={EmployerPage} />
        <Route path="/kjara/employers/:id" component={EmployerPageReplication} />
        <Route path="/contracts/:id" component={ContractPage} />
        <Route path="/kjara/contracts/:id" component={ContractPageReplication} />
        <Route exact path="/oldcontracts" component={OldContractsPage} />
        <Route exact path="/kjara/oldcontracts" component={OldContractsPageReplication} />
        <Route exact path="/filtered-search" component={FilteredSearchPage} />
        <Route exact path="/kjara/filtered-search" component={FilteredSearchPageReplication} />
        <Route exact path="/user/login" component={Login} />
        <Route path="/user/new-password/:id" component={NewPassword} />
        <PrivateRoute
          exact
          path="/user/register"
          component={(props) => (
            <Register isAuthenticated={props.isAuthenticated} />
          )}
        />
        <PrivateRoute
          exact
          path="/admin"
          component={(props) => (
            <AdminHome isAuthenticated={props.isAuthenticated} />
          )}
        />
        <PrivateRoute
          exact
          path="/admin/stats"
          component={(props) => (
            <AdminStats isAuthenticated={props.isAuthenticated} />
          )}
        />
        <PrivateRoute
          path="/admin/unions/:id"
          component={(props) => (
            <UnionPage isAuthenticated={props.isAuthenticated} />
          )}
        />
        <PrivateRoute
          path="/admin/employers/:id"
          component={(props) => (
            <EmployerPage isAuthenticated={props.isAuthenticated} />
          )}
        />
        <PrivateRoute
          path="/admin/contracts/:id"
          component={(props) => (
            <AdminContractPage isAuthenticated={props.isAuthenticated} />
          )}
        />

        {/* ------Table test!------ */}
        <PrivateRoute
          path="/admin/editcontract/all"
          component={(props) => (
            <EditAllContracts isAuthenticated={props.isAuthenticated} />
          )}
        />
        {/* ------Table test!------ */}

        <PrivateRoute
          path="/admin/editcontract/:id"
          component={(props) => (
            <EditContract isAuthenticated={props.isAuthenticated} />
          )}
        />
        <PrivateRoute
          exact
          path="/admin/filtered-search"
          component={(props) => (
            <AdminFilteredSearchPage isAuthenticated={props.isAuthenticated} />
          )}
        />
        <PrivateRoute
          exact
          path="/admin/addcontract"
          component={(props) => (
            <>
              <AddContract isAuthenticated={props.isAuthenticated} />
            </>
          )}
        />
        <PrivateRoute
          exact
          path="/admin/addoldcontract"
          component={(props) => (
            <AddOldContract isAuthenticated={props.isAuthenticated} />
          )}
        />
        <PrivateRoute
          exact
          path="/admin/oldcontracts"
          component={(props) => (
            <AdminOldContractsPage isAuthenticated={props.isAuthenticated} />
          )}
        />
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
