import React, { useState, useEffect } from 'react';

import { fetchlink } from '../../../utils/link.js';
import Chart from 'react-apexcharts';
import { LoadingOutlined } from '@ant-design/icons';

import { Empty } from 'antd';

import '../pichart.scss';

export const NewContractsChart = (props) => {
  const [loading, setLoading] = useState(true);
  const [chartOptions, setChartOptions] = useState({
    options: null,
    series: null,
  });
  const [data, setData] = useState(null);
  const [total, setTotal] = useState(null);

  const labels = props.displayDetails ? ['Eftir', 'Fyrir', 'Vantar eldri samning', 'Útrunnir'] : ['Eftir', 'Fyrir'];
  const colors = props.displayDetails ? ['#a70d23', '#02529c', '#048f6a', '#81ccb8'] : ['#a70d23', '#02529c'];

  useEffect(() => {
    async function fetchData() {
      const fetchedData = await fetch(`${fetchlink}${props.datalink}`);
      const json = await fetchedData.json();

      if (!fetchedData.ok) {
        console.error('Engin gögn fundust');
      } else {
        if (props.displayDetails) {
          setData([
            json.newContractAfterExpCount,
            json.newContractBeforeExpCount,
            json.notConnectedCount,
            json.duenow,
          ]);
        } else {
          setData([(json.newContractAfterExpCount + json.notConnectedCount + json.duenow), json.newContractBeforeExpCount])
        }
        setTotal(json.totalvalues + json.notConnectedCount);
        setChartOptions({
          series: props.displayDetails ? [
            json.newContractAfterExpCount,
            json.newContractBeforeExpCount,
            json.notConnectedCount,
            json.duenow,
          ] : [(json.newContractAfterExpCount + json.notConnectedCount + json.duenow), json.newContractBeforeExpCount],
          options: {
            chart: {
              type: 'donut',
            },
            plotOptions: {
              pie: {
                startAngle: -90,
                endAngle: 90,
                offsetY: 40,
              },
            },
            legend: {
              show: false,
            },
            labels: labels,
            colors: colors,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  legend: {
                    position: 'bottom',
                  },
                },
              },
            ],
            tooltip: {
              enabled: false,
              onDatasetHover: {
                highlightDataSeries: false,
              }
            }
          },
        });
      }
      setLoading(false);
    }

    fetchData();
  }, []);

  return (
    <div className="smallPieChartCard">
      <div
        className="smallPieChartContent"
        style={{ flexDirection: props.reverse ? 'row-reverse' : 'row' }}
      >
        <div className="smallPieChartContainer">
          {loading ? (
            <LoadingOutlined style={{ fontSize: 70, color: '#02529c' }} spin />
          ) : total === 0 ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Gat ekki sótt gögn"
            />
          ) : (
            <Chart
              height="100%"
              options={chartOptions.options}
              series={chartOptions.series}
              type="donut"
            />
          )}
        </div>

        <div className="smallPieChartDataContainer">
          <div className="smallPieChartContentTitleContainer">
            <h3 className="smallPieChartContentTitle">{props.title}</h3>
          </div>

          <div className="mainChartLabels">
            {labels.map((row, i) => {
              return (
                <div key={i} className="mainChartLabel">
                  <div
                    className="mainChartLabelDot"
                    style={{ backgroundColor: colors[i] }}
                  />
                  <div className="mainCardDataContainer">
                    <p className="dataLabel mainChartLabelText">{row}</p>
                    {!loading ? (
                      <span
                        className="chartNumberData"
                        style={{ fontSize: '12px' }}
                      >
                        {props.admin
                          ? data[i]
                          : `${((data[i] / total) * 100).toFixed(1)}%`}
                      </span>
                    ) : (
                      '---'
                    )}
                  </div>
                </div>
              );
            })}

            <div style={{ borderBottom: 'dotted 1px #cdcdcd' }} />

            <div className="mainChartLabel" style={{ paddingBottom: '10px' }}>
              <div
                className="mainChartLabelDot"
                style={{ backgroundColor: 'white' }}
              />

              <div className="mainCardDataContainer">
                <p className="dataLabel mainChartLabelText">Samtals: </p>
                {!loading ? (
                  <span
                    className="chartNumberData"
                    style={{ fontSize: '12px' }}
                  >
                    {props.admin
                      ? total
                      : `${data
                          .reduce((a, b) => a + (b / total) * 100, 0)
                          .toFixed(1)}%`}
                  </span>
                ) : (
                  '---'
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
