import { useEffect, useState } from 'react';

import './timelinecardreplication.scss';
import { Typography, Card, Timeline, Tag, Empty, message } from 'antd';
import { HistoryOutlined, RightCircleOutlined } from '@ant-design/icons';
import { fetchlink } from '../../../utils/link.js';

import moment from 'moment';

export function TimelineCardReplication(props) {
  const [loading, setLoading] = useState(false);
  const [contracts, setContracts] = useState([]);

  useEffect(() => {
    if (props.contractarrid) {
      async function fetchContracts() {
        setLoading(true);
        try {
          const data = await fetch(
            `${fetchlink}/contracts/new/contractarrid=${props.contractarrid}`
          );
          if (!data.ok) {
            message.error('Ekki tókst að sækja gildandi kjarasamninga aðila');
          } else {
            const json = await data.json();
            setContracts(json);
          }
        } catch (error) {
          message.error('Ekki tókst að sækja gildandi kjarasamninga aðila');
          console.error('error', error);
        } finally {
          setLoading(false);
        }
      }
      fetchContracts();
    }
  }, [props.contractarrid, props.groupid]);

  return (
    <>
      <Card
        loading={loading}
        title='Gildandi kjarasamningar aðila'
        className='timelineCard bs'
        extra={<HistoryOutlined style={{ fontSize: '28px' }} />}
      >
        {props.contractarrid === null ? (
          <Empty
            imageStyle={{
              height: 100,
            }}
            description={
              <span className='contentText'>
                Samningur á sér ekki neina sögu í þessari lotu
              </span>
            }
          />
        ) : (
          <Timeline mode='left'>
            {contracts.length &&
              contracts.map((contract, i) => {
                return (
                  <Timeline.Item
                    style={{ paddingBottom: '40px' }}
                    color={
                      contract.contractcategory === 'heildarutgafa'
                        ? 'green'
                        : 'blue'
                    }
                    key={i}
                    dot={<RightCircleOutlined />}
                  >
                    {contract.id === props.id ? (
                      <Typography.Text style={{ fontSize: 16 }}>
                        {contract.name}
                      </Typography.Text>
                    ) : (
                      <Typography.Link
                        href={
                          `/kjara/contracts/${contract.id}`
                        }
                        style={{
                          fontSize: 16,
                        }}
                      >
                        {contract.name}
                      </Typography.Link>
                    )}
                    <p
                      style={{
                        color: 'rgba(0,0,0, 0.45)',
                        fontSize: 12,
                        margin: 0,
                      }}
                    >
                      <Tag
                        style={{
                          fontSize: 10,
                          marginLeft: '10px',
                          lineHeight: '12px',
                          padding: '0 4px',
                        }}
                        color={
                          contract.iswritecontrol
                            ? 'cyan'
                            : contract.contractcategory === 'heildarutgafa'
                              ? 'green'
                              : 'magenta'
                        }
                      >
                        {contract.iswritecontrol
                          ? 'Ritstýrður samningur'
                          : contract.contractcategory === 'heildarutgafa'
                            ? 'Heildarútgáfa'
                            : 'Kjarasamningur'}
                      </Tag>

                      <>
                        {/* Undirritaður{' '}
                          <span style={{ fontWeight: 500, color: '#02529c' }}>
                            {dateManipulation(contract.signaturedate)}
                          </span>{' '} */}
                        <span>Gildistími: </span>
                        <span style={{ fontWeight: 500, color: '#02529c' }}>
                          {`${moment(contract.durationperiodfrom).year()} - 
                            ${contract.durationperiodto === null ? 'Ótímabundinn' : moment(contract.durationperiodto).year()}`}
                        </span>{' '}
                      </>
                    </p>
                  </Timeline.Item>
                );
              })}
          </Timeline>
        )}
      </Card>
    </>
  );
}
