import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { fetchlink } from '../../../utils/link.js';
import {
  Form,
  Input,
  Button,
  Radio,
  Switch,
  DatePicker,
  InputNumber,
  Select,
  Result,
  Upload,
  Typography,
  Modal,
  message,
  Divider,
} from 'antd';

import moment from 'moment';
import {
  InboxOutlined,
  CloseOutlined,
  CheckOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
  FileSyncOutlined,
} from '@ant-design/icons';
import { getJwt, getUser } from '../../../auth/auth.js';

const { Option } = Select;
const { RangePicker } = DatePicker;

export const EditGeneralInfo = (props) => {
  const [form] = Form.useForm();
  const [fileForm] = Form.useForm();
  const [employerForm] = Form.useForm();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [render, setRender] = useState(false);
  const [employers, setEmployers] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    let data = {
      name: props.contract.name,
      signatureDate: props.contract.signaturedate
        ? moment(props.contract.signaturedate)
        : null,
      durationperiodfrom: moment(props.contract.durationperiodfrom),
      durationperiodto: moment(props.contract.durationperiodto),
      contractCategory: props.contract.contractcategory,
      typeOfMarket: props.contract.typeofmarket,
      nrOfBookings: props.contract.nrofbookings,
      iswritecontrol: props.contract.iswritecontrol,
      rss: props.contract.rss,
      rssDate: props.contract.rssdate ? moment(props.contract.rssdate) : null,
      comment: props.contract.comment,
      isactive: props.contract.isactive,
    };
    form.setFieldsValue(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.contract]);

  useEffect(() => {
    async function fetchEmployers() {
      const data = await fetch(`${fetchlink}/employers/asc`);
      const json = await data.json();
      if (!data.ok) {
        console.error('Engir lauagreiðendur fundust');
        return;
      } else {
        setEmployers(json);
      }
      setLoading(false);
    }

    async function fetchCurrentEmployer() {
      const data = await fetch(`${fetchlink}/employers/contractid=${id}`);
      const json = await data.json();
      if (!data.ok) {
        console.error('Enginn lauagreiðandi fannst');
        return;
      } else {
        console.log(json);
        form.setFieldsValue({
          employer: json.map((employer) => employer.name),
        });
      }
      setLoading(false);
    }
    fetchEmployers().then(() => {
      fetchCurrentEmployer();
    });
  }, [form, id]);

  function showEditConfirm(values) {
    Modal.confirm({
      title: 'Ertu viss um að þú viljir breyta þessum gildum?',
      icon: <ExclamationCircleOutlined />,
      content: 'Ekki er hægt að afturkalla þessa aðgerð!',
      okText: 'Breyta',
      okType: 'primary',
      cancelText: 'Hætta við',
      async onOk() {
        console.log(values);
        const jwt = getJwt();
        const user = getUser();

        const durationdate = {
          durationPeriodFrom: values.durationperiodfrom,
          durationPeriodTo: values.durationperiodto,
        };

        let result;
        try {
          result = await fetch(`${fetchlink}/contracts/update`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${jwt.token}`,
            },
            body: JSON.stringify({ ...values, ...durationdate, id, user }),
          });
          if (!result.ok) {
            setError('Tenging við gagnagrunn rofnaði');
          } else {
            message.success('Samningi breytt!');
          }
        } catch (err) {
          setError('Óþekkt villa');
        } finally {
          setLoading(false);
        }
      },
      onCancel() {
        setLoading(false);
        console.info('Hætt við');
      },
    });
  }

  const onFinish = async (values) => {
    setLoading(true);
    showEditConfirm(values);
  };

  const draggerProps = {
    name: 'files',
    accept: '.pdf',
    multiple: false,
    maxCount: 1,
    showUploadList: true,
    customRequest: ({ onSuccess }) => {
      setTimeout(() => {
        onSuccess('ok');
      }, 0);
    },
  };

  if (error) {
    if (error.error === 'consent_required') {
      return (
        <Result
          status='error'
          title='Villa kom upp!'
          subTitle={error.message}
          extra={
            <>
              <Button
                onClick={() => {
                  setError(null);
                }}
                key='back'
              >
                Til baka
              </Button>
              <Button>Leyfa aðgerð</Button>
            </>
          }
        ></Result>
      );
    }
    return (
      <Result
        status='error'
        title='Villa kom upp!'
        subTitle={error}
        extra={
          <>
            <Button
              onClick={() => {
                setError(null);
              }}
              key='back'
            >
              Til baka
            </Button>
          </>
        }
      ></Result>
    );
  }

  return (
    <Form
      onValuesChange={() => setRender(!render)}
      method='PATCH'
      onFinish={onFinish}
      form={form}
    >
      {/* <Headline size='24' title='Breyta samningi' /> */}
      <Form.Item
        name='name'
        className='formitemContainer'
        label='Kjarasamningur:'
      >
        <Input
          disabled={loading}
          placeholder='Kjarasamningur milli Eflingar og SA '
          allowClear
        />
      </Form.Item>

      <Form.Item
        name='isactive'
        className='formitemContainer'
        label='Er kjarasamningur í gildi:'
        valuePropName='checked'
      >
        <Switch
          loading={loading}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
        />
      </Form.Item>

      <Form.Item
        className='formitemContainer'
        name='signatureDate'
        label='Dagsetning undirritunar:'
      >
        <DatePicker disabled={loading} />
      </Form.Item>

      {/* <Form.Item
        name='durationDate'
        className='formitemContainer'
        label='Gildistími:'
      >
        <RangePicker disabled={loading} placeholder={['Frá', 'Til']} />
      </Form.Item> */}

      <div className='formitemContainer_dates'>
        <p>Gildistími:</p>

        <Form.Item
          name='durationperiodfrom'
          label='Frá'
          rules={[
            {
              required: true,
              message: 'Vinsamlegast settu inn gildistíma samnings',
            },
          ]}
        >
          <DatePicker disabled={loading} placeholder='Frá' />
        </Form.Item>

        <Form.Item name='durationperiodto' label='Til'>
          <DatePicker disabled={loading} placeholder='Til' />
        </Form.Item>
      </div>

      <Form.Item
        name='typeOfMarket'
        className='formitemContainer'
        label='Er samningur hjá ohf, á almennum eða opinberum markaði?'
      >
        <Radio.Group>
          <Radio.Button disabled={loading} value='Almennur'>
            Almennum markaði
          </Radio.Button>
          <Radio.Button disabled={loading} value='Opinber'>
            Opinberum markaði
          </Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name='iswritecontrol'
        className='formitemContainer'
        label='Er kjarasamningur ritstýrður'
        valuePropName='checked'
      >
        <Switch
          loading={loading}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
        />
      </Form.Item>

      <Form.Item
        className='formitemContainer'
        name='nrOfBookings'
        label='Fjöldi bókana'
      >
        <InputNumber disabled={props.loading} />
      </Form.Item>

      <Form.Item
        className='formitemContainer'
        name='employer'
        label='Launagreiðandi:'
        rules={[
          {
            type: 'array',
            max: 50,
            min: 1,
            message: 'Verður að velja a.m.k einn launagreiðanda',
          },
        ]}
      >
        <Select
          disabled={loading}
          loading={loading}
          mode='multiple'
          showSearch
          placeholder
          optionFilterProp='children'
        >
          {employers.map((employer, i) => {
            return (
              <Option key={i} value={employer.name}>
                {employer.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
        <Button
          onClick={() => setShowModal(true)}
          style={{ marginLeft: '5px', gridColumn: '2', width: '80%' }}
        >
          Bæta við nýjum launagreiðanda
        </Button>
      </div>
      <Modal
        title='Bæta við nýjum launagreiðanda'
        visible={showModal}
        okText={`Skrá launagreiðanda`}
        cancelText='Hætta við'
        okButtonProps={{ htmlType: 'submit', loading: loading }}
        onOk={employerForm.submit}
        onCancel={() => {
          employerForm.resetFields();
          setShowModal(false);
        }}
      >
        <Form
          form={employerForm}
          method='POST'
          onFinish={async (values) => {
            setError(null);
            setLoading(true);

            const jwt = getJwt();
            const user = getUser();

            let result;
            try {
              result = await fetch(`${fetchlink}/employers/add`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                  Authorization: `Bearer ${jwt.token}`,
                },
                body: JSON.stringify({ ...values, ...user }),
              });

              if (!result.ok) {
                message.error(
                  'Villa kom upp, gat ekki sett inn launagreiðanda'
                );
              }
            } catch (err) {
              console.error('Error --> ', err);
            }
            if (!result.ok) {
              setLoading(false);
            } else {
              employerForm.resetFields();
              const concatData = [values, ...employers];
              setEmployers(concatData);
              setLoading(false);
              setShowModal(false);
            }
          }}
        >
          <Form.Item
            name='name'
            label={`Nafn launagreiðanda:`}
            rules={[
              {
                required: true,
                message: 'Verður að velja a.m.k einn valmöguleika',
              },
            ]}
          >
            <Input disabled={loading} />
          </Form.Item>
        </Form>
        {error && (
          <Typography.Paragraph style={{ backgroundColor: '#eee' }}>
            <CloseCircleOutlined style={{ color: 'red' }} /> {error}
          </Typography.Paragraph>
        )}
      </Modal>

      <div className='formitemContainer_rss'>
        <h4>Ríkissáttasemjari</h4>
        <Form.Item
          name='rss'
          label='Var samningi vísað til ríkissáttasemjara:'
          valuePropName='checked'
        >
          <Switch
            loading={loading}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>

        <Form.Item name='rssDate' label='Dagsetning vísunar:'>
          <DatePicker disabled={loading || !form.getFieldValue('rss')} />
        </Form.Item>
      </div>

      <Form.Item
        className='formitemContainer'
        name='comment'
        label='Auka upplýsingar:'
      >
        <Input.TextArea disabled={loading} maxLength={255} rows={4} showCount />
      </Form.Item>

      <div
        style={{ display: 'flex', flexDirection: 'row', marginBottom: '30px' }}
      >
        <div style={{ width: 'calc(50% - 32px)', marginLeft: '32px' }}>
          <p>Breyta PDF skjali samnings:</p>
        </div>
        <div style={{ width: '50%' }}>
          <Button
            type='dashed'
            icon={<FileSyncOutlined />}
            onClick={() => {
              Modal.confirm({
                title: 'Breyta PDF skjali',
                icon: <ExclamationCircleOutlined />,
                width: 600,
                content: (
                  <>
                    <Divider />
                    <a
                      style={{ marginBottom: '20px' }}
                      href={props.contract.contractlink}
                      target='_blank'
                      rel='noreferrer'
                    >
                      Núverandi PDF skjal
                    </a>
                    <Form form={fileForm}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: 'Verður að hlaða inn pdf skjali!',
                          },
                        ]}
                        label='Kjarasamningur í pdf formi'
                        name='contractPdf'
                        valuePropName='file'
                        noStyle
                      >
                        <Upload.Dragger disabled={loading} {...draggerProps}>
                          <p className='ant-upload-drag-icon'>
                            <InboxOutlined />
                          </p>
                          <p className='ant-upload-text'>
                            Smelltu hér eða dragðu skjalið inn í boxið
                          </p>
                          <p className='ant-upload-hint'>
                            Aðeins hægt að hlaða inn einu skjali (pdf).
                          </p>
                        </Upload.Dragger>
                      </Form.Item>
                    </Form>
                  </>
                ),
                okText: 'Breyta',
                okType: 'primary',
                cancelText: 'Hætta við',
                async onOk(values) {
                  setLoading(true);
                  const jwt = getJwt();

                  const file = new FormData();

                  file.append(
                    'file',
                    fileForm.getFieldValue('contractPdf').file.originFileObj
                  );

                  const postfile = await fetch(`${fetchlink}/contracts/file`, {
                    method: 'POST',
                    headers: {
                      Authorization: `Bearer ${jwt.token}`,
                    },
                    body: file,
                  });

                  const filejson = await postfile.json();

                  if (postfile.ok) {
                    const result = await fetch(
                      `${fetchlink}/contracts/update/upload`,
                      {
                        method: 'PATCH',
                        headers: {
                          'Content-Type': 'application/json',
                          Accept: 'application/json',
                          Authorization: `Bearer ${jwt.token}`,
                        },
                        body: JSON.stringify({
                          id: props.contract.id,
                          newLink: filejson.key,
                          oldLink: props.contract.contractlink,
                        }),
                      }
                    );

                    const resJson = await result.json();

                    if (!result.ok) {
                      message.error(resJson.message);
                    } else {
                      message.success('Skjali hefur verið breytt');
                    }
                  }

                  setLoading(false);
                },
                onCancel() {
                  setLoading(false);
                  console.info('Hætt við');
                },
              });
            }}
          >
            Breyta PDF skjali
          </Button>
        </div>
      </div>

      <Form.Item className='btnContainer'>
        <Button
          disabled={loading}
          loading={loading}
          className='submitBtn'
          type='primary'
          htmlType='submit'
        >
          Breyta samning
        </Button>
      </Form.Item>
    </Form>
  );
};
