import { List, Avatar, Button, Pagination } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import logo from '../../images/satti-logo3.jpeg';
import { dateManipulation } from '../../utils/utils.js';
import { UnionEmployerSettings } from '../unionemployersettings/UnionEmployerSettings';
import { useEffect, useState } from 'react';

export function TabContent(props) {
  const [dataCount, setDataCount] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const isContracts = props.contentType === 'contracts';

  useEffect(() => {
    setDataCount(props.items[0]?.totalvalues && props.items[0].totalvalues);
  }, []);

  return (
    <>
      {console.log("props homepage is", props.homepage)}
      <List
        style={{
          backgroundColor: 'white',
          padding: '0 1rem',
          marginBottom: '16px',
        }}
        itemLayout="horizontal"
        dataSource={props.items}
        loading={props.loading}
        pagination={{
          defaultPageSize: 20,
          pageSizeOptions: [20],
        }}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              style={{ alignItems: 'flex-end' }}
              avatar={<Avatar src={logo} />}
              title={
                <a
                  href={
                    props.admin
                      ? `/admin/${props.contentType}/${item?.unionid || item.id
                      }`
                      : `/${props.contentType}/${item?.unionid || item.id}`
                  }
                >
                  {item.name}
                </a>
              }
              description={
                isContracts ? (
                  <p>
                    Gildistími: Frá{' '}
                    <span style={{ fontWeight: 500 }}>
                      {dateManipulation(item.durationperiodfrom)}
                    </span>{' '}
                    til{' '}
                    <span style={{ color: '#02529c', fontWeight: 500 }}>
                      {item.durationperiodto ? dateManipulation(item.durationperiodto) : 'Ótímabundinn'}
                    </span>
                  </p>
                ) : (
                  ''
                )
              }
            />

            {props.admin && (
              <UnionEmployerSettings
                contentType={props.contentType}
                id={item?.unionid || item.id}
                data={item}
              />
            )}
            <Button
              href={
                props.admin
                  ? `/admin/${props.contentType}/${item?.unionid || item.id}`
                  : `/${props.contentType}/${item?.unionid || item.id}`
              }
              size="small"
              type="default"
              icon={<RightOutlined />}
            />
          </List.Item>
        )}
      />

      {/* {!props.loading && (
        <Pagination
          style={{ padding: '16px', float: 'right' }}
          // defaultCurrent={1}
          total={dataCount}
          // total={props.items.length}
          // total={props.items[0]?.totalvalues && props.items[0].totalvalues}
          onChange={(e, i) => {
            props.changeOffsetLimit(e, i);
            setPage(e);
            setLimit(i);
          }}
          // defaultPageSize={limit}
          defaultCurrent={page}
        />
      )} */}
    </>
  );
}
