import './situationalcard.scss';

import { Card, Divider, Empty } from 'antd';
import { PartitionOutlined } from '@ant-design/icons';

export function SituationalCard(props) {
  return (
    <>
      <Card
        className='situationalCard bs'
        title='Annað'
        extra={<PartitionOutlined style={{ fontSize: '28px' }} />}
      >
        {!props.iswritecontrol && !props.comment && (
          <Empty
            imageStyle={{
              height: 50,
            }}
            description={<span className='contentText'>Á ekki við</span>}
          />
        )}

        {props.iswritecontrol && (
          <p className='contentText'>
            <span className='contentSpanText'>Ritstýrður samningur: </span>
            Kjarasamningar frá seinustu heildarútgáfu milli þessa aðila hafa
            verið teknir saman í einn heildstæðan kjarasamningstexta.
          </p>
        )}

        {props.iswritecontrol && props.comment && <Divider />}

        {props.comment && (
          <p className='contentText'>
            <span className='contentSpanText'>Athugasemd: </span>
            {props.comment}
          </p>
        )}

        {/* {props.iswritecontrol ? (
          <p className='contentText'>
            <span className='contentSpanText'>Ritstýrður samningur: </span>
            Kjarasamningar frá seinustu heildarútgáfu milli þessa aðila hafa
            verið teknir saman í einn einn heildstæðan kjarasamnings texta.
          </p>
        ) : props.comment ? (
          <p className='contentText'>
            <span className='contentSpanText'>Athugasemd: </span>
            {props.comment}
          </p>
        ) : (
          <Empty
            imageStyle={{
              height: 50,
            }}
            description={<span className='contentText'>Á ekki við</span>}
          />
        )} */}
      </Card>
    </>
  );
}
