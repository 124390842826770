import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import './admincontractpage.scss';
import '../../frontend/contractpage/contractpage.scss';

import { PageHeader, Button, Result } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';
import { fetchlink } from '../../../utils/link.js';
import { Header } from '../../../components/header/Header.jsx';
import { ChartCard } from '../../../components/cards/chartcard/ChartCard.jsx';
import { InfoCard } from '../../../components/cards/infocard/InfoCard.jsx';
import { ProgressBar } from '../../../components/progressbar/ProgressBar.jsx';
import { TimelineCard } from '../../../components/cards/timelinecard/TimelineCard.jsx';
import { SituationalCard } from '../../../components/cards/situationalcard/SituationalCard.jsx';
import { History } from '../../../components/history/History.jsx';
import { AdminNavBar } from '../../../components/adminnavbar/AdminNavBar.jsx';
import { Settings } from '../../../components/settings/Settings.jsx';

export function AdminContractPage(props) {
  const [loading, setLoading] = useState(true);
  const [contract, setContract] = useState([]);
  const [contractDelete, setContractDelete] = useState(false);
  const { id } = useParams();
  const routerHistory = useHistory();

  useEffect(() => {
    async function fetchContracts() {
      const data = await fetch(`${fetchlink}/contracts/id=${id}`);
      const employer = await fetch(`${fetchlink}/employers/contractid=${id}`);
      const json = await data.json();
      const employerJson = await employer.json();

      if (!data.ok || !employer.ok) {
        routerHistory.push('/not-found');
        return;
      } else {
        setContract({
          ...json[0],
          employer: employerJson,
        });
      }

      setLoading(false);
    }
    fetchContracts();
  }, []);

  if (contractDelete) {
    return (
      <>
        <AdminNavBar isAuthenticated={props.isAuthenticated} />
        <Header title={contract?.name} />
        <Result
          title='Samningi eytt'
          extra={
            <Button href='/admin' type='primary' key='console'>
              Til baka
            </Button>
          }
        />
      </>
    );
  }

  return (
    <>
      {props.isAuthenticated && (
        <>
          <AdminNavBar isAuthenticated={props.isAuthenticated} />
          <Header title={contract?.name} />
          <div className='contentSection'>
            <div className='adminGoBackContainer'>
              <PageHeader
                classNAme='goBack'
                style={{ paddingLeft: 0 }}
                ghost={true}
                onBack={() => window.history.back()}
                title='Til baka'
              />
              <Settings
                reFetch={() => setContractDelete(true)}
                isContractPage={true}
                id={id}
              />
            </div>
            <div className='contentContainer'>
              <ProgressBar
                dateFrom={contract?.durationperiodfrom}
                dateTo={contract?.durationperiodto}
                type={contract?.contractcategory}
                iswritecontrol={contract?.iswritecontrol}
              />
              <div className='btnContainer'>
                <Button
                  style={{ width: '100%', boxShadow: '1px 1px 15px -10px' }}
                  loading={loading}
                  type='default'
                  icon={<FileSearchOutlined />}
                  href={contract?.contractlink}
                  target='_blank'
                  rel='norefferer'
                >
                  Opna samning
                </Button>
              </div>

              {/* NÝJA CSS */}
              <div className='contractPageCards'>
                <ChartCard
                  loading={loading}
                  title='Atkvæðagreiðsla'
                  id={id}
                  admin='/admin'
                />

                <TimelineCard
                  id={contract?.id}
                  loading={loading}
                  contractarrid={contract?.contractarrid}
                  groupid={contract?.groupid}
                  admin={true}
                />

                <InfoCard
                  link={'/admin'}
                  loading={loading}
                  id={contract?.id}
                  contract={contract}
                />

                <SituationalCard
                  comment={contract.comment}
                  iswritecontrol={contract.iswritecontrol}
                />
              </div>
              <History
                id={contract?.id}
                contractarrid={contract?.contractarrid}
                admin={true}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}
