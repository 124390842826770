import { ContractForm } from '../../../components/forms/contractform/ContractForm.jsx';
import { Header } from '../../../components/header/Header.jsx';
import { AdminNavBar } from '../../../components/adminnavbar/AdminNavBar.jsx';

export function AddContract(props) {
  return (
    <>
      <AdminNavBar isAuthenticated={props.isAuthenticated} />
      <Header title='Kjarasamningar hjá ríkissáttasemjara' />
      <ContractForm />
    </>
  );
}
