import './contractcollapsereplication.css';
import { Typography, Collapse, List, Empty } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { ContractListReplication } from '../contractlistreplication/ContractListReplication.jsx';
import { dateManipulation } from '../../utils/utils.js';

export function ContractCollapseReplication(props) {
  return (
    <>
      {!props.loading && (
        <>
          {!props.data ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Engir samningar fundust"
            />
          ) : (
            <List
              className="mainContent p-reset p-lr16 p-b16"
              itemLayout="horizontal"
              dataSource={props.data}
              loading={props.loading}
              pagination={{
                defaultPageSize: 20,
                pageSizeOptions: [10, 20, 50, 100],
              }}
              renderItem={(item) => (
                <List.Item style={{ padding: 0 }}>
                  <Collapse
                    style={{ backgroundColor: 'white', width: '1000px' }}
                    expandIcon={({ isActive }) => (
                      <RightOutlined rotate={isActive ? 90 : 0} />
                    )}
                    expandIconPosition="right"
                    bordered={false}
                  >
                    <Collapse.Panel
                      header={
                        <>
                          <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <Typography.Title style={{ margin: 0 }} level={5}>
                              {item.name}
                            </Typography.Title>

                            <div>
                              <Typography.Text italic="true">
                                Síðasta heildarútgáfa gildir frá{' '}
                                {dateManipulation(item.durationperiodfrom)} til{' '}
                                {item.durationperiodto ? dateManipulation(item.durationperiodto) : 'Ótímabundinn'}
                              </Typography.Text>
                            </div>
                          </div>
                        </>
                      }
                    >

                      <ContractListReplication
                        isCollapse={true}
                        link={
                          !props.isHomePage
                            ? `/contracts/identity=${props.identity}?idtype=${props.idtype}&agecategory=${props.agecategory}&contractarrid=${item.contractarrid}&groupid=${item.groupid}`
                            : `/contracts/contractarrid=${item.contractarrid}/contractgroupid=${item.groupid}`
                        }
                      />

                    </Collapse.Panel>
                  </Collapse>
                </List.Item>
              )}
            />
          )}
        </>
      )}
    </>
  );
}
