import React from 'react';
import { Input, Button, Select } from 'antd';
import { RightOutlined, FileSearchOutlined } from '@ant-design/icons';

import './preferencesreplication.scss';
import { FilteredSearch } from '../forms/filteredsearch/FilteredSearch.jsx';
import { FilteredSearchReplication } from '../forms/filteredsearchreplication/FilteredSearchReplication';

const { Option } = Select;

export function PreferencesReplication(props) {
  return (
    <div className='preferencesBarContainer'>
      <Input.Search
        placeholder='Sláðu inn leitarorð'
        enterButton='Leita'
        suffix={
          <FileSearchOutlined
            style={{
              fontSize: 16,
            }}
          />
        }
        onSearch={(e) => props.onTextInput(e)}
        allowClear
      />

      <Select
        disabled={props.tabNumber === 2 && true}
        onChange={(e) => props.onSortSelect(e)}
        placeholder='Raða eftir...'
        optionFilterProp='children'
      >
        {props.tabNumber === 3 && (
          <>
            <Option value='durationperiodto'>Dags. - til</Option>
            <Option value='durationperiodfrom'>Dags. - frá</Option>
          </>
        )}
        <Option value='name'>Stafrófsröð</Option>
        {props.tabNumber === 1 && (
          <Option value='unionid'>Stéttarfélagi</Option>
        )}
      </Select>
      <FilteredSearchReplication isAuthenticated={props.isAuthenticated} />
      <Button
        href={props.oldcontractslink}
        icon={<RightOutlined />}
        type='link'
      >
        Eldri samningar
      </Button>
    </div>
  );
}
