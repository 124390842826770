import React, { useState } from 'react';
import './hero.scss';
import { fetchlink } from '../../utils/link';

import { EditablePieChart } from '../editablepiechart/EditablePieChart';
import { Modal, Form, Input, message } from 'antd';

export const Hero = () => {
  const [form] = Form.useForm();
  const [modal, setModal] = useState(false);

  const showModal = () => {
    setModal(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setModal(false);
  };

  const onFinish = async (values) => {
    let result;
    try {
      result = await fetch(`${fetchlink}/comments/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({ ...values }),
      });

      if (!result.ok) {
        message.error('Óþekkt villa');
      }
    } catch (err) {
      console.error('error --> ', err);
    }
    form.resetFields();
    message.success('Takk fyrir ábendinguna!');
    setModal(false);
  };

  return (
    <>
      <div className="hero">
        <div className="heroContentContainer">
          <div className="charts">
            <div className="heroDisclaimer">
              <p className="disclaimer">
                Tilgangurinn með gagnagrunninum er að veita launafólki og launagreiðendum skilvirkan aðgang að gildandi kjarasamningum og jafnframt að veita ýmiskonar tölulegar upplýsingar um kjarasamninga og kjarasamningagerðina. Ríkissáttasemjari safnar þessum gögnum m.a. á grundvelli laga nr. 80/1938.
              </p>

              <p className="disclaimer">
                Hægt er að nálgast alla kjarasamninga með því að smella á nafn stéttarfélags eða launagreiðenda eða með því að nýta hnappinn „ítarleg leit“ hér fyrir neðan
              </p>

              <p className="disclaimer">
                Vinsamlegast athugið að þessi gagnagrunnur er til upplýsingar og birting kjarasamninga í honum staðfestir ekki formlegt eða lagalegt gildi þeirra. Þannig kann að vera að það vanti kjarasamninga í gagnagrunninn eða að í honum sé að finna samninga sem ekki eigi að vera þar - eða að samningar séu vitlaust skráðir. Allar ábendingar um villur eru því vel þegnar í gegnum netfangið rikissattasemjari@rikissattasemjari.is eða með því að fylla út eftirfarandi{' '}
                <span
                  style={{
                    color: '#02529c',
                    cursor: 'pointer',
                    fontWeight: '700',
                  }}
                  onClick={showModal}
                >
                  form
                </span>
              </p>
            </div>
            <EditablePieChart homePage />
          </div>
        </div>
      </div>

      <Modal
        title="Ítarleg leit"
        visible={modal}
        okText="Senda athugasemd"
        cancelText="Hætta við"
        okButtonProps={{ htmlType: 'submit' }}
        onOk={form.submit}
        onCancel={handleCancel}
        width={600}
      >
        <Form layout="vertical" method="POST" onFinish={onFinish} form={form}>
          <Form.Item style={{ width: '60%' }} label="Nafn:" name="name">
            <Input />
          </Form.Item>

          <Form.Item
            style={{ width: '60%' }}
            label="Tölvupóstfang:"
            name="email"
          >
            <Input />
          </Form.Item>

          <Form.Item
            style={{ width: '100%' }}
            label="Athugasemd:"
            name="comment"
            rules={[
              {
                required: true,
                message: 'Verður að fylgja athugasemd',
              },
            ]}
          >
            <Input.TextArea rows={6} maxLength={512} showCount />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
