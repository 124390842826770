import React, { useState } from 'react';
import { Button, Menu, Dropdown, message, Modal, Form, Input } from 'antd';
import { fetchlink } from '../../utils/link.js';
import {
  DeleteOutlined,
  SettingOutlined,
  ExclamationCircleOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { getJwt } from '../../auth/auth';

export const UnionEmployerSettings = (props) => {
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [form] = Form.useForm();

  function showDeleteConfirm(id) {
    Modal.confirm({
      title: `Ertu viss um að þú viljir eyða ${
        props.contentType === 'employers'
          ? 'þessum launagreiðanda'
          : 'þessu stéttarfélagi'
      }?`,
      icon: <ExclamationCircleOutlined />,
      content: 'Ekki er hægt að afturkalla þessa aðgerð!',
      okText: 'Eyða',
      okType: 'danger',
      cancelText: 'Nei',
      async onOk() {
        setLoading(true);

        const jwt = getJwt();

        const del = await fetch(
          `${fetchlink}/${props.contentType}/delete/${id}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${jwt.token}`,
            },
          }
        );

        const response = await del.json();

        if (!del.ok) {
          message.error(response);
        } else {
          message.info('Tókst!');
          window.location.reload();
        }
        setLoading(false);
      },
      onCancel() {
        console.info('Cancel');
      },
    });
  }

  const changeContent = () => {
    setUpdating(true);
    form.setFieldsValue(props.data);
  };

  const handleClick = async (event, id) => {
    console.log(event);
    setLoading(true);
    event.key === 'edit' ? changeContent(id) : showDeleteConfirm(id);
  };

  const update = async (values) => {
    const jwt = getJwt();

    let result;
    try {
      result = await fetch(`${fetchlink}/${props.contentType}/update/`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${jwt.token}`,
        },
        body: JSON.stringify({ ...values, id: props.data.id }),
      });

      if (!result.ok) {
        const msg = await result.json();
        message.error(msg);
      } else {
        message.success('Tókst!');
      }
    } catch (err) {
      message.error('Úbbs, villa kom upp!');
    }
    setLoading(false);
    setUpdating(false);
  };

  const menu = (
    <Menu
      onClick={(event) => handleClick(event, props.id)}
      items={[
        {
          key: 'edit',
          label: (
            <Button icon={<EditOutlined />} type="link">
              Breyta
            </Button>
          ),
        },
        {
          key: 'delete',
          label: (
            <Button danger icon={<DeleteOutlined />} type="link">
              Eyða
            </Button>
          ),
        },
      ]}
    />
  );

  return (
    <>
      <Dropdown overlay={menu} disabled={loading} trigger={['click']}>
        <Button style={{ marginRight: '20px' }} type="primary" size="small">
          Stillingar
        </Button>
      </Dropdown>

      <Modal
        title={`Breytingar á ${
          props.contentType === 'employers' ? 'launagreiðanda' : 'stéttarfélagi'
        }`}
        visible={updating}
        onOk={form.submit}
        onCancel={() => {
          setUpdating(false);
          setLoading(false);
        }}
      >
        <Form method="PATCH" onFinish={update} form={form}>
          <Form.Item
            name="name"
            label={`Nafn ${
              props.contentType === 'employers'
                ? 'launagreiðanda'
                : 'stéttarfélags'
            }:`}
          >
            <Input />
          </Form.Item>

          {props.contentType === 'unions' && (
            <Form.Item
              name="unionid"
              label="Einkennisnúmer stéttarfélags"
              rules={[
                {
                  len: 6,
                  message: 'Einkenni verður að vera 6 tölustafir',
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};
