import { OldContractForm } from '../../../components/forms/oldcontractform/OldContractForm.jsx';
import { Header } from '../../../components/header/Header.jsx';
import { AdminNavBar } from '../../../components/adminnavbar/AdminNavBar.jsx';

export function AddOldContract(props) {
  return (
    <>
      <AdminNavBar isAuthenticated={props.isAuthenticated} />
      <Header title='Kjarasamningar hjá ríkissáttasemjara' />
      <OldContractForm />
    </>
  );
}
