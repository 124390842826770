import { useState, useEffect } from 'react';
import { Header } from '../../../components/header/Header.jsx';
import { Tabs } from 'antd';

import { fetchlink } from '../../../utils/link.js';

import { TabContent } from '../../../components/tabcontent/TabContent.jsx';
import { ContractCollapse } from '../../../components/contractcollapse/ContractCollapse.jsx';
import { AdminNavBar } from '../../../components/adminnavbar/AdminNavBar.jsx';
import { Preferences } from '../../../components/preferences/Preferences.jsx';
import { AdminContractList } from '../../../components/admincontractlist/AdminContractList.jsx';

export function AdminHome(props) {
  const [unions, setUnions] = useState([]);
  const [employers, setEmployers] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState('Stéttarfélög');
  const [error, setError] = useState(null);
  const [value, setValue] = useState('');
  const [tabNumber, setTabNumber] = useState(1);
  const [sortBy, setSortBy] = useState('name');
  const [noContracts, setNoContracts] = useState(false);
  const [orderBy, setOrderBy] = useState('name');

  useEffect(() => {
    if (tabNumber === 1) {
      setOrderBy('name');
      fetchByKeyword(value);
    } else if (tabNumber === 2) {
      setOrderBy('name');
      fetchByKeyword(value);
    } else if (tabNumber === 3) {
      setOrderBy('name');
      fetchByKeyword(value);
    }
  }, [tabNumber]);

  useEffect(() => {
    tabNumber === 1
      ? fetchByKeyword(value)
      : tabNumber === 2
      ? fetchByKeyword(value)
      : fetchByKeyword(value);
  }, [orderBy]);

  async function fetchData(link) {
    return await fetch(`${fetchlink}${link}`);
  }

  async function fetchUnions() {
    setLoading(true);
    const unionsData = await fetchData(`/unions?orderby=${sortBy}`);
    if (!unionsData.ok) {
      setError('Ekki tókst að sækja stéttarfélög');
      setLoading(false);
    } else {
      const json = await unionsData.json();
      setUnions(json);
      setLoading(false);
      setError(null);
    }
  }

  async function fetchEmployers() {
    setLoading(true);
    const employersData = await fetchData('/employers/asc');
    if (!employersData.ok) {
      setError('Ekki tókst að sækja launagreiðendur');
      setLoading(false);
    } else {
      const json = await employersData.json();
      setEmployers(json);
      setLoading(false);
      setError(null);
    }
  }

  async function fetchContracts() {
    setLoading(true);
    const contractsData = await fetchData('/contractgroup/new');
    if (!contractsData.ok) {
      setError('Engir samningar fundust');
      setLoading(false);
    } else {
      const json = await contractsData.json();
      setContracts(json);
      setNoContracts(false);
      setLoading(false);
    }
  }

  async function fetchByKeyword(query, offset, limit) {
    setLoading(true);
    let tab = '';
    if (tabNumber === 1) tab = 'unions';
    if (tabNumber === 2) tab = 'employers';
    if (tabNumber === 3) tab = 'contractgroup';
    const data = await fetchData(
      `/contracts/all?keyword=${query}&type=${tab}&issingle=true&agecategory=new&orderby=${orderBy}`
    );
    // const geymsla = `&offset=${offset || 1}&limit=${limit || 20}`;
    const json = await data.json();
    if (tab === 'unions') setUnions(json);
    if (tab === 'employers') setEmployers(json);
    if (tab === 'contractgroup') setContracts(json);
    setLoading(false);
  }

  return (
    <>
      {props.isAuthenticated && (
        <>
          <AdminNavBar isAuthenticated={props.isAuthenticated} />
          <Header title={`${title} / ADMIN`} />
          <div className='mainSection'>
            <div className='mainContainer'>
              <Preferences
                isAuthenticated={props.isAuthenticated}
                tabNumber={tabNumber}
                oldcontractslink='/oldcontracts'
                onSortSelect={(e) => {
                  setOrderBy(e);
                }}
                onTextInput={(e) => {
                  fetchByKeyword(e);
                  setValue(e);
                }}
              />
              <Tabs
                className='mainContent'
                onTabClick={(e) => setTabNumber(parseInt(e))}
                centered
                defaultActiveKey='1'
                onChange={(e) => {
                  setTitle(
                    e === '1'
                      ? 'Stéttarfélög'
                      : e === '2'
                      ? 'Launagreiðendur'
                      : 'Gildandi kjarasamningar'
                  );
                }}
              >
                <Tabs.TabPane tab='Stéttarfélög' key='1'>
                  <TabContent
                    admin={true}
                    items={unions}
                    contentType='unions'
                    loading={loading}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab='Launagreiðendur' key='2'>
                  <TabContent
                    admin={true}
                    items={employers}
                    contentType='employers'
                    loading={loading}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab='Gildandi kjarasamningar' key='3'>
                  <ContractCollapse
                    noData={noContracts}
                    isAuthenticated={props.isAuthenticated}
                    data={contracts}
                    isHomePage={true}
                    loading={loading}
                  />
                </Tabs.TabPane>

                <Tabs.TabPane tab='Lausir kjarasamningar' key='4'>
                  <AdminContractList
                    isCollapse={false}
                    link={`/contracts/notconnected`}
                  />
                </Tabs.TabPane>
              </Tabs>
            </div>
          </div>
        </>
      )}
    </>
  );
}
