import { useState, useEffect } from 'react';
import { fetchlink } from '../../utils/link.js';
import './historyreplication.css';

import { Collapse, Timeline, Tag, Typography, Empty } from 'antd';
import { dateManipulation } from '../../utils/utils.js';

export function HistoryReplication(props) {
  const [error, setError] = useState(null);
  const [contracts, setContracts] = useState([]);

  useEffect(() => {
    if (props.contractarrid) {
      async function fetchContracts() {
        const data = await fetch(
          `${fetchlink}/contracts/old/contractarrid=${props.contractarrid}`
        );

        if (!data.ok) {
          const json = await data.json();
          setError(json.message);
        } else {
          const json = await data.json();
          setContracts(json);
        }
      }
      fetchContracts();
    }
  }, [props.contractarrid]);

  return (
    <Collapse
      style={{
        width: '1000px',
        marginTop: '20px',
        backgroundColor: 'white',
        boxShadow: '1px 1px 15px -10px',
      }}
      ghost
    >
      <Collapse.Panel header='Sjá eldri samninga'>
        <Timeline mode='right'>
          {!error ? (
            contracts.map((contract, i) => {
              return (
                <Timeline.Item
                  className={
                    contract.contractcategory === 'heildarutgafa'
                      ? 'timelineItemHeildar'
                      : 'timelineItemKjara'
                  }
                  style={{
                    paddingBottom: i === contracts.length - 1 ? 40 : '',
                  }}
                  color={
                    contract.contractcategory === 'heildarutgafa'
                      ? 'green'
                      : 'blue'
                  }
                  key={i}
                  label={
                    contract.contractcategory === 'kjarasamningur' && (
                      <>
                        {contract.id === props.id ? (
                          <Typography.Text style={{ fontSize: 16 }}>
                            {contract.name}
                          </Typography.Text>
                        ) : (
                          <Typography.Link
                            href={`/kjara/contracts/${contract.id}`
                            }
                            style={{
                              fontSize: 16,
                            }}
                          >
                            {contract.name}
                          </Typography.Link>
                        )}
                        <p
                          style={{
                            color: 'rgba(0,0,0, 0.45)',
                            fontSize: 12,
                            margin: 0,
                          }}
                        >
                          <Tag
                            style={{
                              fontSize: 10,
                              marginLeft: '10px',
                              lineHeight: '12px',
                              padding: '0 4px',
                            }}
                            color={
                              contract.iswritecontrol
                                ? 'cyan'
                                : contract.contractcategory === 'heildarutgafa'
                                  ? 'green'
                                  : 'magenta'
                            }
                          >
                            {contract.iswritecontrol
                              ? 'Ritstýrður samningur'
                              : contract.contractcategory === 'heildarutgafa'
                                ? 'Heildarútgáfa'
                                : 'Kjarasamningur'}
                          </Tag>
                          <span>Gildistími: </span>
                          <span style={{ fontWeight: 500, color: '#02529c' }}>
                            {`${dateManipulation(
                              contract.durationperiodfrom
                            )} - 
                            ${contract.durationperiodto ? dateManipulation(contract.durationperiodto) : 'Ótímabundinn'}`}
                          </span>{' '}
                        </p>
                      </>
                    )
                  }
                >
                  {contract.contractcategory === 'heildarutgafa' && (
                    <>
                      {contract.id === props.id ? (
                        <Typography.Text style={{ fontSize: 16 }}>
                          {contract.name}
                        </Typography.Text>
                      ) : (
                        <Typography.Link
                          href={
                            `/kjara/contracts/${contract.id}`
                          }
                          style={{
                            fontSize: 16,
                          }}
                        >
                          {contract.name}
                        </Typography.Link>
                      )}
                      <p
                        style={{
                          color: 'rgba(0,0,0, 0.45)',
                          fontSize: 12,
                          margin: 0,
                        }}
                      >
                        <Tag
                          style={{
                            fontSize: 10,
                            marginLeft: '10px',
                            lineHeight: '12px',
                            padding: '0 4px',
                          }}
                          color={
                            contract.iswritecontrol
                              ? 'cyan'
                              : contract.contractcategory === 'heildarutgafa'
                                ? 'green'
                                : 'magenta'
                          }
                        >
                          {contract.iswritecontrol
                            ? 'Ritstýrður samningur'
                            : contract.contractcategory === 'heildarutgafa'
                              ? 'Heildarútgáfa'
                              : 'Kjarasamningur'}
                        </Tag>
                        <span>Gildistími: </span>
                        <span style={{ fontWeight: 500, color: '#02529c' }}>
                          {`${dateManipulation(contract.durationperiodfrom)} - 
                            ${contract.durationperiodto ? dateManipulation(contract.durationperiodto) : 'Ótímabundinn'}`}
                        </span>{' '}
                      </p>
                    </>
                  )}
                </Timeline.Item>
              );
            })
          ) : (
            <Empty
              imageStyle={{
                height: 50,
              }}
              description={<span className='contentText'>{error}</span>}
            />
          )}
        </Timeline>
      </Collapse.Panel>
    </Collapse>
  );
}
