import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Header } from '../../../components/header/Header.jsx';

import { message, PageHeader, Tabs } from 'antd';
import { fetchlink } from '../../../utils/link.js';
import { ContractList } from '../../../components/contractlist/ContractList.jsx';
import { AdminContractList } from '../../../components/admincontractlist/AdminContractList.jsx';
import { NavBar } from '../../../components/navbar/NavBar.jsx';
import { AdminNavBar } from '../../../components/adminnavbar/AdminNavBar.jsx';
import { ContractCollapse } from '../../../components/contractcollapse/ContractCollapse.jsx';
import { ContractCollapseReplication } from '../../../components/contractcollapsereplication/ContractCollapseReplication.jsx';
import { ContractListReplication } from '../../../components/contractlistreplication/ContractListReplication.jsx';

export function EmployerPageReplication(props) {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [heildar, setheildar] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    async function fetchGroups() {
      setLoading(true);
      const nameData = await fetchData(`/employers/id=${id}`);
      setName(nameData[0].name);
      const contractGroups = await fetchData(
        `/contractgroup/identity=${id}?idtype=employerid&agecategory=all`
      );

      const heildarTemp = {
        new: [],
        old: [],
      };

      contractGroups?.forEach((group) => {
        heildarTemp[group.isold ? 'old' : 'new'].push(group);
      });

      setheildar(heildarTemp);
      setLoading(false);
    }

    async function fetchData(link) {
      const data = await fetch(`${fetchlink}${link}`);

      if (!data.ok) {
        message.error('Ekki tókst að sækja gögn');
        setLoading(false);
      } else {
        setLoading(false);
        const json = await data.json();
        return json;
      }
    }

    fetchGroups();
  }, [id]);

  return (
    <>

      <Header title={name} />
      <div className="mainSection">
        <div className="mainContainer">
          <PageHeader
            style={{ paddingLeft: 0 }}
            ghost={true}
            onBack={() => window.history.back()}
            title="Til baka"
          />
          <Tabs className="mainContent" centered defaultActiveKey="1">
            <Tabs.TabPane key="1" tab="Heildarútgáfur">
              <ContractCollapseReplication
                loading={loading}
                data={heildar.new}
                isHomePage={false}
                isAuthenticated={props.isAuthenticated}
                identity={id}
                idtype="employerid"
                agecategory="new"
              />
            </Tabs.TabPane>

            <Tabs.TabPane
              style={{ padding: '1rem' }}
              key="2"
              tab="Kjarasamningar"
            >

              <ContractListReplication
                isCollapse={false}
                link={`/kjara/contracts/new/employerid=${id}`}
              />

            </Tabs.TabPane>

            <Tabs.TabPane
              key="3"
              style={{ paddingLeft: 0 }}
              tab="Eldri samningar"
            >
              <ContractCollapseReplication
                loading={loading}
                data={heildar.old}
                isHomePage={false}
                isAuthenticated={props.isAuthenticated}
                identity={id}
                idtype="employerid"
                agecategory="old"
              />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    </>
  );
}
