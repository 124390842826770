import { useState, useEffect } from 'react';
import { Header } from '../../../components/header/Header.jsx';

import { message, PageHeader } from 'antd';

import { fetchlink } from '../../../utils/link.js';

import { ContractCollapse } from '../../../components/contractcollapse/ContractCollapse.jsx';
import { NavBar } from '../../../components/navbar/NavBar.jsx';
import { ContractCollapseReplication } from '../../../components/contractcollapsereplication/ContractCollapseReplication.jsx';

export function OldContractsPageReplication() {
  const [loading, setLoading] = useState(true);
  const [contracts, setContracts] = useState([]);

  useEffect(() => {
    async function fetchContracts() {
      const data = await fetch(`${fetchlink}/contractgroup/old`);
      const json = await data.json();

      if (!data.ok) {
        message.error(json.message);
      } else {
        setContracts(json);
      }
      setLoading(false);
    }
    fetchContracts();
  }, []);
  return (
    <>
      <Header title="Eldri samningar" />
      <div className="mainSection">
        <div className="mainContainer">
          <PageHeader
            style={{ paddingLeft: 0, width: '100%' }}
            ghost={true}
            onBack={() => window.history.back()}
            title="Til baka"
          />
          <ContractCollapseReplication
            loading={loading}
            isCollapse={false}
            isHomePage={true}
            data={contracts}
          />
        </div>
      </div>
    </>
  );
}
