import { useEffect, useState } from 'react';
import './contractpage.scss';

import { PageHeader, Button } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';
import { fetchlink } from '../../../utils/link.js';
import { Header } from '../../../components/header/Header.jsx';
import { ChartCard } from '../../../components/cards/chartcard/ChartCard.jsx';
import { InfoCard } from '../../../components/cards/infocard/InfoCard.jsx';
import { ProgressBar } from '../../../components/progressbar/ProgressBar.jsx';
import { TimelineCard } from '../../../components/cards/timelinecard/TimelineCard.jsx';
import { SituationalCard } from '../../../components/cards/situationalcard/SituationalCard.jsx';
import { History } from '../../../components/history/History.jsx';
import { NavBar } from '../../../components/navbar/NavBar.jsx';

export function ContractPage(props) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [contract, setContract] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    async function fetchContracts() {
      const data = await fetch(`${fetchlink}/contracts/id=${id}`);
      const employer = await fetch(`${fetchlink}/employers/contractid=${id}`);
      const json = await data.json();
      const employerJson = await employer.json();

      if (!data.ok || !employer.ok) {
        setError(json.message);
      } else {
        setContract({
          ...json[0],
          employer: employerJson,
        });
      }

      setLoading(false);
    }
    fetchContracts();
  }, []);

  return (
    <>
      <NavBar />
      <Header title={contract?.name} />
      <div className='contentSection'>
        <div className='goBackContainer'>
          <PageHeader
            classNAme='goBack'
            style={{ paddingLeft: 0 }}
            ghost={true}
            onBack={() => window.history.back()}
            title='Til baka'
          />
        </div>
        <div className='contentContainer'>
          <ProgressBar
            dateFrom={contract?.durationperiodfrom}
            dateTo={contract?.durationperiodto}
            type={contract?.contractcategory}
            iswritecontrol={contract?.iswritecontrol}
          />
          <div className='btnContainer'>
            <Button
              style={{ width: '100%', boxShadow: '1px 1px 15px -10px' }}
              loading={loading}
              type='default'
              icon={<FileSearchOutlined />}
              href={contract?.contractlink}
              target='_blank'
              rel='norefferer'
            >
              Opna samning
            </Button>
          </div>

          {/* NÝJA CSS */}
          <div className='contractPageCards'>
            <ChartCard
              admin=''
              loading={loading}
              title='Atkvæðagreiðsla'
              id={id}
            />

            <TimelineCard
              id={contract?.id}
              loading={loading}
              contractarrid={contract?.contractarrid}
              groupid={contract?.groupid}
              admin={false}
            />

            <InfoCard
              link={''}
              loading={loading}
              id={contract?.id}
              contract={contract}
            />

            <SituationalCard
              comment={contract.comment}
              iswritecontrol={contract.iswritecontrol}
            />
          </div>

          <History
            id={contract?.id}
            contractarrid={contract?.contractarrid}
            admin={false}
          />
        </div>
      </div>{' '}
    </>
  );
}
