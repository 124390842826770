import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Form,
  Input,
  Card,
  Table,
  Typography,
  Select,
  Switch,
  InputNumber,
  Button,
  Modal,
  message,
} from 'antd';

import { fetchlink } from '../../../utils/link';
import { ChartCard } from '../../cards/chartcard/ChartCard';

import { getJwt, getUser } from '../../../auth/auth.js';

import {
  AreaChartOutlined,
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

export const EditVotes = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const { id } = useParams();
  const [render, setRender] = useState(false);
  const [unions, setUnions] = useState([]);
  const [savedVotes, setSavedVotes] = useState();

  useEffect(() => {
    if (props.contract.id) {
      async function fetchVotes() {
        const data = await fetch(
          `${fetchlink}/unioncontract/contractid=${props.contract.id}`
        );

        if (!data.ok) {
          message.error('Ekki tókst að sækja gögn!');
        } else {
          const json = await data.json();

          let contractUnion = [];

          if (props.contract.isjointelection) {
            contractUnion = await fetchContractUnions();
          }

          const select = {
            selectedUnionIds: props.contract.isjointelection
              ? contractUnion.map((item) => item.unionid)
              : json.map((item) => item.unionid),
            jointelection: props.contract.isjointelection,
            votes: json,
          };

          form.setFieldsValue(select);
          setHasLoaded(true);
        }
      }

      async function fetchUnions() {
        const data = await fetch(`${fetchlink}/unions/asc`);

        if (!data.ok) {
          message.error('Ekki tókst að sækja stéttarfélög');
        } else {
          const json = await data.json();
          setUnions(json);
        }
        setLoading(false);
      }

      async function fetchContractUnions() {
        const data = await fetch(
          `${fetchlink}/unions/contractid=${props.contract.id}`
        );

        if (!data.ok) {
          message.error('Ekki tókst að sækja gögn');
        } else {
          const json = await data.json();
          return json;
        }
      }

      fetchUnions().then(() => {
        fetchVotes();
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addUnionVote = (_, union) => {
    if (savedVotes)
      setSavedVotes([
        { unionname: union.children, unionid: union.value },
        ...savedVotes,
      ]);
    form.setFieldsValue({
      votes: [
        { unionname: union.children, unionid: union.value },
        ...form.getFieldValue('votes'),
      ],
    });
  };

  const deselectUnionVote = (unionid, union) => {
    if (savedVotes)
      setSavedVotes([savedVotes.filter((union) => union.unionid !== unionid)]);
    form.setFieldsValue({
      votes: [
        ...form
          .getFieldValue('votes')
          .filter((union) => union.unionid !== unionid),
      ],
    });
  };

  function showEditConfirm(values) {
    Modal.confirm({
      title: 'Ertu viss um að þú viljir breyta þessum gildum?',
      icon: <ExclamationCircleOutlined />,
      content: 'Ekki er hægt að afturkalla þessa aðgerð!',
      okText: 'Breyta',
      okType: 'primary',
      cancelText: 'Hætta við',
      async onOk() {
        const jwt = getJwt();
        const user = getUser();

        let result;
        try {
          result = await fetch(`${fetchlink}/contracts/update`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${jwt.token}`,
            },
            body: JSON.stringify({ ...values, id, user }),
          });
          if (!result.ok) {
            message.error('Tenging við gagnagrunn rofnaði');
          } else {
            message.success('Samningi breytt!');
          }
        } catch (err) {
          message.error('Óþekkt villa');
        } finally {
          setLoading(false);
        }
      },
      onCancel() {
        setLoading(false);
        console.info('Hætt við');
      },
    });
  }

  const onFinish = async (values) => {
    setLoading(true);

    if (!values.jointelection) {
      Object.defineProperty(
        values,
        'selectedUnions',
        Object.getOwnPropertyDescriptor(values, 'selectedUnionIds')
      );
      delete values['selectedUnionIds'];

      const temp = values.votes.reduce((acc, curr) => {
        acc[curr.unionid] = curr;
        return acc;
      }, {});

      values.votes = temp;

      showEditConfirm(values);
    } else {
      showEditConfirm({
        votes: values,
        selectedUnions: values.selectedUnionIds,
        jointelection: values.jointelection,
      });
    }
  };

  return (
    <>
      {hasLoaded && (
        <Form
          onValuesChange={() => setRender(!render)}
          method="PATCH"
          onFinish={onFinish}
          form={form}
        >
          <div
            style={{ maxHeight: '100%' }}
            className="formitemContainer_samflot"
          >
            <p>Samflot:</p>
            <Form.Item
              rules={[
                {
                  type: 'array',
                  required: true,
                  max: 50,
                  min: 1,
                  message: 'Verður að velja a.m.k eitt verkalýðsfélag',
                },
              ]}
              name="selectedUnionIds"
            >
              <Select
                disabled={loading}
                loading={loading}
                mode="multiple"
                showSearch
                optionFilterProp="children"
                onSelect={(e, more) => addUnionVote(e, more)}
                onDeselect={(e, more) => deselectUnionVote(e, more)}
              >
                {unions.map((union) => {
                  return (
                    <Select.Option key={union.unionid} value={union.unionid}>
                      {union.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>

            <div className="joint">
              <Form.Item
                name="jointelection"
                label="Setja inn heildaratkvæðagreiðslu:"
                valuePropName="checked"
              >
                <Switch
                  loading={loading}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onChange={(e) => {
                    if (!e) {
                      form.setFieldsValue({
                        votes: savedVotes
                          ? savedVotes
                          : form
                              .getFieldValue('selectedUnionIds')
                              .map((unionid) => {
                                return {
                                  unionid,
                                  unionname: unions.find(
                                    (union) => union.unionid === unionid
                                  ).name,
                                };
                              }),
                      });
                    } else {
                      setSavedVotes(form.getFieldValue('votes'));
                    }
                  }}
                ></Switch>
              </Form.Item>
            </div>

            <div style={{ gridColumn: 1 }}>
              {form.getFieldValue('jointelection') && (
                <div>
                  <h3>Atkvæðagreiðsla:</h3>
                  <div className="electionContainer">
                    <Form.Item
                      name="nrofmembers"
                      initialValue={
                        props.contract.isjointelection
                          ? form.getFieldValue('votes')[0]?.nrofmembers
                          : null
                      }
                      label="Fjöldi á kjörskrá"
                    >
                      <InputNumber />
                    </Form.Item>

                    <Form.Item
                      initialValue={
                        props.contract.isjointelection
                          ? form.getFieldValue('votes')[0]?.nrofmembersyes
                          : null
                      }
                      name="nrofmembersyes"
                      label="Fjöldi já"
                    >
                      <InputNumber />
                    </Form.Item>
                  </div>
                  <div className="electionContainer">
                    <Form.Item
                      initialValue={
                        props.contract.isjointelection
                          ? form.getFieldValue('votes')[0]?.nrofmembersno
                          : null
                      }
                      name="nrofmembersno"
                      label="Fjöldi nei"
                    >
                      <InputNumber />
                    </Form.Item>

                    <Form.Item
                      initialValue={
                        props.contract.isjointelection
                          ? form.getFieldValue('votes')[0]?.nrofmembersempty
                          : null
                      }
                      name="nrofmembersempty"
                      label="Fjöldi auð"
                    >
                      <InputNumber />
                    </Form.Item>
                  </div>
                </div>
              )}

              {!form.getFieldValue('jointelection') && (
                <div
                  style={{
                    borderBottom: '1px solid #eee',
                    marginBottom: '10px',
                  }}
                >
                  <Form.List name="votes">
                    {(fields) => (
                      <div>
                        {fields.map((field) => (
                          <div key={field.fieldKey}>
                            <h3>
                              {
                                form.getFieldValue('votes')[field.fieldKey]
                                  .unionname
                              }
                              :
                            </h3>
                            <div className="electionContainer">
                              <Form.Item
                                fieldKey={[field.fieldKey, 'nrofmembers']}
                                name={[field.name, 'nrofmembers']}
                                label="Fjöldi á kjörskrá"
                              >
                                <InputNumber />
                              </Form.Item>

                              <Form.Item
                                fieldKey={[field.fieldKey, 'nrofmembersyes']}
                                name={[field.name, 'nrofmembersyes']}
                                label="Fjöldi já"
                              >
                                <InputNumber />
                              </Form.Item>
                            </div>

                            <div className="electionContainer">
                              <Form.Item
                                fieldKey={[field.fieldKey, 'nrofmembersno']}
                                name={[field.name, 'nrofmembersno']}
                                label="Fjöldi nei"
                              >
                                <InputNumber />
                              </Form.Item>

                              <Form.Item
                                fieldKey={[field.fieldKey, 'nrofmembersempty']}
                                name={[field.name, 'nrofmembersempty']}
                                label="Fjöldi auð"
                              >
                                <InputNumber />
                              </Form.Item>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </Form.List>
                </div>
              )}
            </div>
          </div>

          <Form.Item className="btnContainer">
            <Button
              disabled={loading}
              loading={loading}
              className="submitBtn"
              type="primary"
              htmlType="submit"
            >
              Breyta samning
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};
