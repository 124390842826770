import React, { useState, useEffect } from 'react';

import './oldcontractform.scss';
import { Headline } from '../../headline/Headline.jsx';
import { fetchlink } from '../../../utils/link.js';
import {
  Form,
  Input,
  Button,
  Radio,
  Switch,
  DatePicker,
  InputNumber,
  Select,
  Upload,
  Result,
  Checkbox,
} from 'antd';
import { InboxOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { FormModal } from '../formmodal/FormModal';
import { dateManipulation } from '../../../utils/utils.js';
import { getJwt, getUser } from '../../../auth/auth.js';

const { Option } = Select;
const { RangePicker } = DatePicker;

export const OldContractForm = () => {
  const [form] = Form.useForm();
  const [unions, setUnions] = useState([]);
  const [employers, setEmployers] = useState([]);
  const [error, setError] = useState(null);
  const [samflot, setSamflot] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [contracts, setContracts] = useState(false);
  const [contractArr, setContractArr] = useState([]);
  const [carrId, setCarrId] = useState(null);
  const [groups, setGroups] = useState([]);
  const [selectedUnions, setSelectedUnions] = useState([]);
  const [addedContractID, setAddedContractID] = useState(null);
  const [render, setRender] = useState(true);

  useEffect(() => {
    async function fetchCarr() {
      const data = await fetchData('/contractarr');

      if (!data.ok) {
        setContracts(true);
      } else {
        const json = await data.json();
        setContractArr(json);
      }
    }
    fetchCarr();
  }, []);

  useEffect(() => {
    if (carrId) {
      async function fetchGroups() {
        const data = await fetchData(`/contractgroup/contractarrid=${carrId}`);

        if (!data.ok) {
          setError('Engin gögn fundust');
        } else {
          const json = await data.json();
          setGroups(json);
        }
      }
      fetchGroups();
    }
  }, [carrId]);

  async function fetchData(link) {
    return await fetch(`${fetchlink}${link}`);
  }

  const onFinish = async (values) => {
    setLoading(true);
    console.log('Received values of form ---> ', values);

    const jwt = getJwt();
    const user = getUser();

    const file = new FormData();

    if (typeof values.contractPdf !== 'undefined') {
      file.append('file', values.contractPdf.file.originFileObj);
    }

    const postfile = await fetch(`${fetchlink}/contracts/file`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${jwt.token}`,
      },
      body: file,
    });
    if (!postfile.ok) setError('Ekki tókst að hlaða upp skrá');

    const json = await postfile.json();
    let data;
    if (postfile.ok) {
      data = { ...values, key: json.key };
    }

    delete data.contractPdf;

    let result;
    try {
      result = await fetch(`${fetchlink}/oldcontracts/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${jwt.token}`,
        },
        body: JSON.stringify({ ...data, ...user }),
      });

      if (!result.ok) {
        setError('Villa kom upp, gat ekki sett inn gögn');
      }
    } catch (err) {
      console.error('error --> ', err);
    }

    setLoading(false);

    if (postfile && result) {
      const contractID = await result.json();
      setAddedContractID(contractID);
      setHasSubmitted(true);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleModalForm = (data, isUnion) => {
    if (isUnion) setUnions(data);
    if (!isUnion) setEmployers(data);
  };

  const addNewContract = () => {
    window.location.reload();
  };

  const addUnionVote = (e, more) => {
    setSelectedUnions([...selectedUnions, more]);
  };

  const deselectUnionVote = (e, more) => {
    let tempArray = [...selectedUnions];
    tempArray.forEach((item, i) => {
      if (e === item.value) {
        tempArray.splice(i, 1);
        setSelectedUnions(tempArray);
      }
    });
  };

  if (error) {
    return (
      <Result
        status='error'
        title='Villa kom upp!'
        subTitle={error}
        extra={
          <>
            <Button
              onClick={() => {
                setError(null);
                setHasSubmitted(false);
              }}
              key='back'
            >
              Til baka
            </Button>
          </>
        }
      ></Result>
    );
  }

  if (hasSubmitted) {
    return (
      <Result
        status='success'
        title='Aðgerð tókst!'
        subTitle='Samningur vistaður í gagnagrunn'
        extra={
          <>
            <Button onClick={addNewContract} key='newcontract'>
              Skrá nýjan samning
            </Button>

            <Button
              target='_blank'
              href={`/admin/contracts/${addedContractID}`}
            >
              Skoða samning
            </Button>
          </>
        }
      ></Result>
    );
  }

  const draggerProps = {
    name: 'files',
    accept: '.pdf',
    multiple: false,
    maxCount: 1,
    showUploadList: true,
    customRequest: ({ onSuccess, onError, file }) => {
      setTimeout(() => {
        onSuccess('ok');
      }, 0);
    },
  };

  return (
    <div className='oldFormContainer'>
      <Form
        onValuesChange={() => setRender(!render)}
        method='POST'
        onFinish={onFinish}
        className='oldForm'
        form={form}
        initialValues={{
          changedWorkHours: true,
          followUp: true,
          samflot: false,
          wasContractUpdated: false,
        }}
      >
        <Headline size='24' title='Form fyrir gamla samninga' />
        <Form.Item
          name='name'
          className='oldFormItemContainer'
          label='Kjarasamningur:'
          rules={[
            {
              required: true,
              message: 'Vinsamlegast settu inn auðkenni kjarasamnings',
              min: 1,
              max: 256,
            },
          ]}
        >
          <Input
            disabled={loading}
            placeholder='Kjarasamningur milli Eflingar og SA '
            allowClear
          />
        </Form.Item>

        <div className='oldFormItemContainer_history'>
          <p>Veldu sögu: </p>

          <Form.Item name='contractArrID'>
            <Select
              disabled={form.getFieldValue('disableHistory')}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ width: '800px' }}
              placement='bottomRight'
              loading={loading}
              showSearch
              placeholder
              optionFilterProp='children'
              onChange={(e) => setCarrId(e)}
            >
              {contractArr?.map((item, i) => {
                return (
                  <Option value={item.id} key={i}>
                    {console.log(item)}
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item valuePropName='checked' name='disableHistory'>
            <Checkbox defaultChecked={false} disabled={contracts}>
              Á ekki við
            </Checkbox>
          </Form.Item>
        </div>

        {groups && (
          <Form.Item
            className='oldFormItemContainer'
            name='groupID'
            label='Hvar í sögunni?:'
          >
            <Select
              placement='bottomRight'
              loading={loading}
              disabled={form.getFieldValue('disableHistory')}
              optionFilterProp='children'
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ width: '800px' }}
            >
              {groups?.map((item, i) => {
                return (
                  <Option value={item.groupid} key={i}>
                    ({new Date(item.durationperiodfrom).getFullYear()} -{' '}
                    {new Date(item.durationperiodto).getFullYear()}) -{' '}
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          className='oldFormItemContainer'
          name='signatureDate'
          label='Dagsetning undirritunar:'
        >
          <DatePicker disabled={loading} />
        </Form.Item>

        {/* <Form.Item
          name='durationDate'
          className='oldFormItemContainer'
          label='Gildistími:'
          rules={[
            {
              required: true,
              message: 'Vinsamlegast settu inn gildistíma samnings',
            },
          ]}
        >
          <RangePicker disabled={loading} placeholder={['Frá', 'Til']} />
        </Form.Item> */}

        <div className='formitemContainer_dates'>
          <p>Gildistími:</p>

          <Form.Item
            name='durationperiodfrom'
            rules={[
              {
                required: true,
                message: 'Vinsamlegast settu inn gildistíma samnings',
              },
            ]}
          >
            <DatePicker disabled={loading} placeholder='Frá' />
          </Form.Item>

          <Form.Item name='durationperiodto'>
            <DatePicker disabled={loading} placeholder='Til' />
          </Form.Item>
        </div>

        <div className='oldFormItemContainer_bookings'>
          <h4>Bókanir</h4>
          <Form.Item name='nrOfBookings' label='Fjöldi'>
            <InputNumber disabled={loading} />
          </Form.Item>
        </div>

        <Form.Item
          name='typeOfMarket'
          className='oldFormItemContainer'
          label='Er samningur á almennum eða opinberum markaði?'
          rules={[
            {
              required: true,
              message: 'Vinsamlegast hakaðu í valkost sem á best við',
            },
          ]}
        >
          <div>
            <Radio.Group>
              <Radio.Button disabled={loading} value='Almennur'>
                Almennum markaði
              </Radio.Button>
              <Radio.Button disabled={loading} value='Opinber'>
                Opinberum markaði
              </Radio.Button>
            </Radio.Group>
          </div>
        </Form.Item>

        <Form.Item
          name='contractCategory'
          label='Útgáfa:'
          className='formitemContainer'
          rules={[
            {
              required: true,
              message: 'Vinsamlegast hakaðu í valkost sem á best við',
            },
          ]}
        >
          <Radio.Group>
            <Radio.Button disabled={loading} value='heildarutgafa'>
              Heildarútgáfa
            </Radio.Button>
            <Radio.Button disabled={loading} value='kjarasamningur'>
              Kjarasamningur
            </Radio.Button>
          </Radio.Group>
        </Form.Item>

        {form.getFieldValue('contractCategory') === 'heildarutgafa' && (
          <>
            <Form.Item
              name='iswritecontrol'
              style={{ backgroundColor: '#f3f3f3' }}
              className='formitemContainer'
              label='Er kjarasamningur ritstýrður'
              valuePropName='checked'
            >
              <Switch
                loading={loading}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </>
        )}

        <Form.Item
          name='samflot'
          className='oldFormItemContainer'
          label='Samflot?'
          valuePropName='checked'
        >
          <Switch
            loading={loading}
            onChange={(e) => {
              if (e) {
                setSamflot(50);
              } else {
                setSamflot(1);
              }

              form.validateFields(['selectedUnions'], { force: true });
            }}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>

        <div className='formitemContainer_samflot'>
          <p>Verkalýðsfélög sem eiga aðild:</p>
          <Form.Item
            rules={[
              {
                type: 'array',
                required: true,
                max: samflot,
                message: 'Verður að velja a.m.k eitt verkalýðsfélag',
              },
            ]}
            name='selectedUnions'
          >
            <Select
              disabled={loading}
              loading={loading}
              mode='multiple'
              showSearch
              placeholder
              optionFilterProp='children'
              onSelect={(e, more) => addUnionVote(e, more)}
              onDeselect={(e, more) => deselectUnionVote(e, more)}
            >
              {unions.map((union) => {
                return (
                  <Option key={union.unionid} value={union.unionid}>
                    {union.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <FormModal
            title='Bæta við nýju stéttarfélagi'
            oktext='stéttarfélags'
            dataType='unions'
            setData={handleModalForm}
            isUnion={true}
          />

          <div className='joint'>
            <Form.Item
              name='jointelection'
              label='Setja inn heildaratkvæðagreiðslu:'
              valuePropName='checked'
            >
              <Switch
                loading={loading}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              ></Switch>
            </Form.Item>
          </div>
        </div>

        {form.getFieldValue('jointelection') && (
          <div className='formitemContainer_election'>
            <h4>Atkvæðagreiðsla</h4>
            <div className='electionContainer'>
              <Form.Item
                name={['votes', 'joint', 'nrOfMembers']}
                label='Fjöldi á kjörskrá'
              >
                <InputNumber />
              </Form.Item>

              <Form.Item
                name={['votes', 'joint', 'nrOfMembersYes']}
                label='Fjöldi já'
              >
                <InputNumber />
              </Form.Item>
            </div>

            <div className='electionContainer'>
              <Form.Item
                name={['votes', 'joint', 'nrOfMembersNo']}
                label='Fjöldi nei'
              >
                <InputNumber />
              </Form.Item>

              <Form.Item
                name={['votes', 'joint', 'nrOfMembersEmpty']}
                label='Fjöldi auð'
              >
                <InputNumber />
              </Form.Item>
            </div>
          </div>
        )}

        {!form.getFieldValue('jointelection') &&
          selectedUnions.map((union, i) => {
            return (
              <div className='formitemContainer_election' key={union.key + i}>
                <h4>{union.children}:</h4>
                <div className='electionContainer'>
                  <Form.Item
                    name={['votes', union.value, 'nrOfMembers']}
                    label='Fjöldi á kjörskrá'
                  >
                    <InputNumber />
                  </Form.Item>

                  <Form.Item
                    name={['votes', union.value, 'nrOfMembersYes']}
                    label='Fjöldi já'
                  >
                    <InputNumber />
                  </Form.Item>
                </div>

                <div className='electionContainer'>
                  <Form.Item
                    name={['votes', union.value, 'nrOfMembersNo']}
                    label='Fjöldi nei'
                  >
                    <InputNumber />
                  </Form.Item>

                  <Form.Item
                    name={['votes', union.value, 'nrOfMembersEmpty']}
                    label='Fjöldi auð'
                  >
                    <InputNumber />
                  </Form.Item>
                </div>

                <Form.Item
                  initialValue={union.children}
                  hidden={true}
                  name={['votes', union.value, 'unionName']}
                />
              </div>
            );
          })}

        {/* <div className="oldFormItemContainer_samflot">
          <p>Verkalýðsfélög sem eiga aðild:</p>
          <Form.Item
            rules={[
              {
                type: 'array',
                required: true,
                max: samflot,
                message: 'Verður að velja a.m.k eitt verkalýðsfélag',
              },
            ]}
            name="selectedUnions"
          >
            <Select
              disabled={loading}
              loading={loading}
              mode="multiple"
              showSearch
              placeholder
              optionFilterProp="children"
              onSelect={(e, more) => addUnionVote(e, more)}
              onDeselect={(e, more) => deselectUnionVote(e, more)}
            >
              {unions.map((union) => {
                return (
                  <Option key={union.unionid} value={union.unionid}>
                    {union.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <FormModal
            title="Bæta við nýju stéttarfélagi"
            oktext="stéttarfélags"
            dataType="unions"
            setData={handleModalForm}
            isUnion={true}
          />
        </div>

        {selectedUnions &&
          selectedUnions.map((union, i) => {
            return (
              <div
                className="oldFormItemContainer_election"
                key={union.key + i}
              >
                <h4>{union.children}:</h4>
                <div className="oldElectionContainer">
                  <Form.Item
                    name={['votes', union.value, 'nrOfMembers']}
                    label="Fjöldi á kjörskrá"
                  >
                    <InputNumber />
                  </Form.Item>

                  <Form.Item
                    name={['votes', union.value, 'nrOfMembersYes']}
                    label="Fjöldi já"
                  >
                    <InputNumber />
                  </Form.Item>
                </div>

                <div className="oldElectionContainer">
                  <Form.Item
                    name={['votes', union.value, 'nrOfMembersNo']}
                    label="Fjöldi nei"
                  >
                    <InputNumber />
                  </Form.Item>

                  <Form.Item
                    name={['votes', union.value, 'nrOfMembersEmpty']}
                    label="Fjöldi auð"
                  >
                    <InputNumber />
                  </Form.Item>
                </div>

                <Form.Item
                  initialValue={union.children}
                  hidden={true}
                  name={['votes', union.value, 'unionName']}
                />
              </div>
            );
          })} */}

        <div className='oldFormItemContainer_samflot'>
          <p>Launagreiðandi:</p>
          <Form.Item
            rules={[
              {
                type: 'any',
                required: true,
                max: 1,
                message: 'Verður að velja launagreiðanda',
              },
            ]}
            name='employer'
          >
            <Select
              disabled={loading}
              loading={loading}
              mode='multiple'
              showSearch
              placeholder
              optionFilterProp='children'
            >
              {employers.map((employer, i) => {
                return (
                  <Option key={i} value={employer.name}>
                    {employer.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <FormModal
            title='Bæta við nýjum launagreiðanda'
            oktext='launagreiðanda'
            dataType='employers'
            setData={handleModalForm}
            isUnion={false}
          />
        </div>

        <div className='oldFormItemContainer_rss'>
          <h4>Ríkissáttasemjari</h4>
          <Form.Item
            name='rss'
            label='Var samningi vísað til ríkissáttasemjara:'
            valuePropName='checked'
          >
            <Switch
              loading={loading}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Form.Item>

          <Form.Item name='rssDate' label='Dagsetning vísunar:'>
            <DatePicker disabled={loading} />
          </Form.Item>
        </div>

        <Form.Item
          className='formitemContainer'
          name='comment'
          label='Auka upplýsingar:'
        >
          <Input.TextArea
            disabled={loading}
            maxLength={255}
            rows={4}
            showCount
          />
        </Form.Item>

        <Form.Item
          className='oldFormItemContainer'
          label='Kjarasamningur í pdf formi'
        >
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Verður að hlaða inn pdf skjali!',
              },
            ]}
            name='contractPdf'
            valuePropName='file'
            noStyle
          >
            <Upload.Dragger disabled={loading} {...draggerProps}>
              <p className='ant-upload-drag-icon'>
                <InboxOutlined />
              </p>
              <p className='ant-upload-text'>
                Smelltu hér eða dragðu skjalið inn í boxið
              </p>
              <p className='ant-upload-hint'>
                Aðeins hægt að hlaða inn einu skjali (pdf).
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        <Form.Item className='btnContainer'>
          <Button
            disabled={loading}
            loading={loading}
            className='submitBtn'
            type='primary'
            htmlType='submit'
          >
            Skrá samning
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
