import { useEffect, useState } from 'react';

import { Tag, Button } from 'antd';
import { fetchlink } from '../../utils/link.js';

export function ContractTagReplication(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tags, setTags] = useState(false);
  const [click, setClicked] = useState(false);

  useEffect(() => {
    if (click) {
      async function fetchUnions() {
        setLoading(true);
        const data = await fetch(`${fetchlink}/unions/contractid=${props.id}`);
        if (!data.ok) {
          setError('Ekki tókst að sækja aðildarfélög');
        } else {
          const json = await data.json();
          setData(json);
        }
        setLoading(false);
      }
      fetchUnions();
    }
  }, [click, props.id]);

  const toggleTags = () => {
    setTags((e) => !e);
  };

  if (error) {
    return (
      <Button
        danger
        style={{ marginBottom: '0.25rem' }}
        size='small'
        type='dashed'
      >
        {error}
      </Button>
    );
  }

  return (
    <>
      <Button
        loading={loading}
        style={{ marginBottom: '0.25rem' }}
        size='small'
        type='dashed'
        onClick={() => {
          toggleTags();
          setClicked(true);
        }}
      >
        Aðildarfélög
      </Button>
      <div>
        {data.map((item, i) => {
          return (
            tags && (
              <Tag style={{ margin: '2px' }} key={i}>
                <a href={`/kjara${props.link}/unions/${item.unionid}`}>{item.name}</a>
              </Tag>
            )
          );
        })}
      </div>
    </>
  );
}
