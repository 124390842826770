import React, { useState, useEffect } from 'react';
import './editablepiechart.scss';

import { fetchlink } from '../../utils/link.js';
import Chart from 'react-apexcharts';

import { LoadingOutlined, ArrowRightOutlined } from '@ant-design/icons';

import { Button, Select, Empty } from 'antd';

export const EditablePieChart = (props) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({ options: null, series: null });
  const [months, setMonths] = useState(6);
  const [nrOfContracts, setNrOfContracts] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const data = await fetch(
        `${fetchlink}/contracts/free/calc?months=${months}`
      );
      const json = await data.json();

      if (!data.ok) {
        console.error('Engin gögn fundust');
      } else {
        setNrOfContracts(json.total);
        console.log(json);
        setData({
          series: [json.duenow, json.yes, json.no, json.inconclusive],
          options: {
            chart: {
              type: 'pie',
            },
            legend: {
              show: false,
            },
            colors: ['#048f6a', '#02529c', '#eda7af', '#A7EDE5'],
            labels: [
              'Samningar nú þegar búnir að renna út',
              'Samningar sem renna út',
              'Samningar sem renna ekki út',
              'Ótímabundir samningar',
            ],
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 150,
                  },
                  legend: {
                    position: 'bottom',
                  },
                },
              },
            ],
          },
        });
      }
      setLoading(false);
    }
    fetchData();
  }, [months]);

  const handleSelect = (value) => {
    setMonths(value);
  };

  const monthSelection = [1, 3, 6, 9, 12];

  return (
    <div
      className={`${props.homePage ? 'pieChartDisplayNone' : undefined
        } pieChartCard `}
    >
      <div className="pieChartTitleContainer">
        <h3 className="pieChartTitle">
          Fjöldi samninga sem renna út innan{' '}
          {months === 1 ? 'mánaðar' : `${months} mánaða`}{' '}
        </h3>
      </div>

      <div className="pieChartContainer">
        {loading ? (
          <LoadingOutlined style={{ fontSize: 150, color: '#02529c' }} spin />
        ) : nrOfContracts === 0 ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Engir samningar renna út á völdu tímabili"
          />
        ) : (
          <Chart
            height="90%"
            options={data.options}
            series={data.series}
            type="pie"
          />
        )}
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          style={{ textAlign: 'center' }}
          type="link"
          href={props.homePage ? '/stats' : '/kjara/stats'}
        >
          Tölfræðisíða <ArrowRightOutlined />
        </Button>
      </div>

      <div className="pieChartContent">
        <h4 className="pieChartCount">Fjöldi: {nrOfContracts} samningar</h4>
        <Select
          className="monthSelection"
          placeholder
          optionFilterProp="children"
          defaultValue={6}
          onSelect={handleSelect}
          loading={loading}
          dropdownMatchSelectWidth={110}
        >
          {monthSelection.map((m) => {
            return (
              <Select.Option key={m} value={m}>
                {m === 1 ? `${m} mánuður` : `${m} mánuðir`}
              </Select.Option>
            );
          })}
        </Select>
      </div>
    </div>
  );
};
