import React, { useState, useEffect } from 'react';

import { AdminNavBar } from '../../../components/adminnavbar/AdminNavBar.jsx';
import { getJwt } from '../../../auth/auth.js';

import {
  LoadingOutlined,
  EditOutlined,
  FastForwardOutlined,
  FastBackwardOutlined,
} from '@ant-design/icons';
import {
  message,
  Tabs,
  Table,
  Typography,
  Select,
  Empty,
  Pagination,
  Button,
  Modal,
} from 'antd';
import Chart from 'react-apexcharts';

import { fetchlink } from '../../../utils/link';
import moment from 'moment';
import { RSSChart } from '../../../components/piecharts/rsschart/RSSChart.jsx';
import { NewContractsChart } from '../../../components/piecharts/newcontractschart/NewContractsChart.jsx';
import { MiniTable } from '../../../components/minitable/MiniTable.jsx';

export const AdminStats = (props) => {
  const [loading, setLoading] = useState(true);
  const [contracts, setContracts] = useState([]);
  const [chartData, setChartData] = useState();
  const [months, setMonths] = useState(6);
  const [nrOfContracts, setNrOfContracts] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [tab, setTab] = useState('free');
  const [fetchOptions, setFetchOptions] = useState({ limit: 20, offset: 1 });

  useEffect(() => {
    async function fetchData() {
      // setLoading(true);

      const data = await fetch(
        `${fetchlink}/contracts/free/calc?months=${months}`
      );

      const json = await data.json();

      if (!data.ok) {
        message.error('Engin gögn fundust');
      } else {
        setNrOfContracts(json);
        setChartData({
          series: [json.duenow, json.yes, json.no, json.inconclusive],
          options: {
            chart: {
              type: 'pie',
            },
            legend: {
              show: false,
            },
            colors: ['#048f6a', '#02529c', '#eda7af', '#A7EDE5'],
            labels: [
              'Samningar nú þegar búnir að renna út',
              'Samningar sem renna út',
              'Samningar sem renna ekki út',
              'Ótímabundir samningar',
            ],
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 150,
                  },
                  legend: {
                    position: 'bottom',
                  },
                },
              },
            ],
          },
        });
      }
      // setLoading(false);
    }

    if (months) {
      setLoading(true);
      fetchData().then(() => {
        fetchContracts().then(() => {
          setLoading(false);
        });
      });
    }
  }, [months]);

  useEffect(() => {
    if (tab && fetchOptions) {
      setLoading(true);
      fetchContracts().then(() => {
        setLoading(false);
      });
    }
  }, [tab, fetchOptions]);

  async function fetchContracts() {
    // setLoading(true);
    const data = await fetch(
      `${fetchlink}/contracts/${tab}?months=${months}&limit=${fetchOptions.limit}&offset=${fetchOptions.offset}`
    );

    if (!data.ok) {
      message.error('Villa kom upp þegar gögn voru sótt');
    } else {
      const json = await data.json();
      setTotalRows(json.total);
      setContracts(json.data);
    }
    // setLoading(false);
  }

  const toggleInstats = (id) => {
    Modal.confirm({
      title: 'Ertu viss?',
      content: (
        <>
          <p>
            Þessi aðgerð breytir engu í gagnagrunninum sjálfum, merkir einungis
            samning sem "í tölfræði" eða ekki
          </p>
        </>
      ),
      async onOk() {
        const jwt = getJwt();

        let result;
        try {
          result = await fetch(`${fetchlink}/contracts/toggleinstats`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${jwt.token}`,
            },
            body: JSON.stringify({
              serialid: id,
            }),
          });

          const response = await result.json();

          if (!result.ok) {
            message.error(response);
            setLoading(false);
          } else {
            message.success('Samningi breytt!');
            // setTimeout(() => {
            //   window.location.reload();
            // }, 1000);

            fetchContracts();
          }
        } catch (err) {
          message.error('Óþekkt villa');
          setLoading(false);
        }
      },
      onCancel() {
        setLoading(false);
        console.info('Hætt við');
      },
    });
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'serialid',
      width: '10%',
      render: (_, record, i) => (
        <Typography.Link key={i} href={`/admin/unions/${record.unionid}`}>
          {record.serialid}
        </Typography.Link>
      ),
      sorter: (a, b) => (a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0),
    },
    {
      title: 'Stéttarfélag',
      dataIndex: 'unionname',
      width: '25%',
      render: (_, record, i) => (
        <Typography.Link key={i} href={`/admin/unions/${record.unionid}`}>
          {record.unionname}
        </Typography.Link>
      ),
      sorter: (a, b) => (a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0),
    },
    {
      title: 'Launagreiðendur',
      dataIndex: 'employername',
      width: '25%',
      sorter: (a, b) => (a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0),
      render: (_, record, i) =>
        record.employers.map((employer, i) => {
          return (
            <>
              <Typography.Link
                key={i}
                href={`/admin/employers/${record.employerid[i]}`}
              >
                {employer}
              </Typography.Link>
              <Typography.Text> - </Typography.Text>
            </>
          );
        }),
    },
    {
      title: 'Samningur',
      dataIndex: 'contractname',
      width: '40%',
      editable: true,
      render: (_, record, i) => (
        <Typography.Link key={i} href={`/admin/contracts/${record.contractid}`}>
          {record.contractname}
        </Typography.Link>
      ),
    },
    {
      title: 'Dags. undirritunar',
      dataIndex: 'signaturedate',
      editable: true,
      render: (_, record) =>
        record.signaturedate == null
          ? 'Á ekki við'
          : moment(record.signaturedate).format('ll'),
      sorter: (a, b) =>
        moment(a.signaturedate).unix() - moment(b.signaturedate).unix(),
      responsive: ['lg'],
    },
    {
      title: 'Gildir til',
      dataIndex: 'durationperiodto',
      editable: true,
      render: (_, record) =>
        record.durationperiodto
          ? moment(record.durationperiodto).format('ll')
          : 'Ótímabundinn',
      sorter: (a, b) =>
        moment(a.durationperiodto).unix() - moment(b.durationperiodto).unix(),
    },
    {
      title: 'Stillingar',
      dataIndex: 'operation',
      render: (_, record) => (
        <Button
          type="default"
          shape="circle"
          href={`/admin/editcontract/${record.contractid}`}
          icon={<EditOutlined />}
          target="_blank"
          rel="noreferrer"
        />
      ),
    },
    {
      title: tab === 'notinstats' ? 'Setja í reikning' : 'Taka úr reikning',
      dataIndex: 'Toggle',
      render: (_, record) =>
        tab === 'notinstats' ? (
          <Button
            type="default"
            shape="default"
            style={{
              color: 'green',
              borderColor: 'green',
              background: '#fff',
            }}
            onClick={() => toggleInstats(record.serialid)}
            icon={<FastBackwardOutlined />}
          />
        ) : (
          <Button
            type="default"
            shape="default"
            danger
            onClick={() => toggleInstats(record.serialid)}
            icon={<FastForwardOutlined />}
          />
        ),
    },
  ];

  const handleSelect = (value) => {
    setMonths(value);
  };

  const handleTabClick = (tabKey) => {
    setFetchOptions({ offset: 1, limit: fetchOptions.limit });
    switch (tabKey) {
      case '1':
        setTab('alreadydue');
        break;
      case '2':
        setTab('free');
        break;
      case '3':
        setTab('notfree');
        break;
      case '4':
        setTab('inconclusive');
        break;
      case '5':
        setTab('rss');
        break;
      case '6':
        setTab('not/rss');
        break;
      case '7':
        setTab('')
        break;
      case '8':
        setTab('notinstats');
    }
  };

  const handlePagination = (offs, lim) => {
    if (lim !== fetchOptions.limit) {
      setFetchOptions({ limit: lim, offset: 1 });
    } else {
      setFetchOptions({ limit: lim, offset: offs });
    }
  };

  const monthSelection = [1, 3, 6, 9, 12];

  return (
    <>
      <AdminNavBar />

      <div className="statsPage">
        <div className="statsTopSection">
          <div className="statsMainCard">
            <div className="mainCardTitleContainer">
              <h3 className="mainCardTitle">
                Fjöldi samninga sem renna út innan{' '}
                {months === 1 ? 'mánaðar' : `${months} mánaða`}{' '}
              </h3>
            </div>

            <div className="mainCardContent">
              <div className="mainCardContentContainer">
                <div className="mainChartLabel">
                  <div
                    className="mainChartLabelDot"
                    style={{ backgroundColor: '#048f6a' }}
                  />
                  <div className="mainCardDataContainer">
                    <p className="mainChartLabelText">Útrunnir:</p>
                    {!loading ? (
                      <span className="chartNumberData">
                        {nrOfContracts?.duenow}
                      </span>
                    ) : (
                      '---'
                    )}
                  </div>
                </div>
                <div className="mainChartLabel">
                  <div
                    className="mainChartLabelDot"
                    style={{ backgroundColor: '#02529c' }}
                  />
                  <div className="mainCardDataContainer">
                    <p className="mainChartLabelText">Renna út:</p>
                    {!loading ? (
                      <span className="chartNumberData">
                        {nrOfContracts?.yes}
                      </span>
                    ) : (
                      '---'
                    )}
                  </div>
                </div>

                <div
                  className="mainChartLabel"
                >
                  <div
                    className="mainChartLabelDot"
                    style={{ backgroundColor: '#eda7af' }}
                  />
                  <div className="mainCardDataContainer">
                    <p className="mainChartLabelText">Renna ekki út: </p>
                    {!loading ? (
                      <span className="chartNumberData">
                        {nrOfContracts?.no}
                      </span>
                    ) : (
                      '---'
                    )}
                  </div>
                </div>

                <div
                  className="mainChartLabel"
                  style={{ borderBottom: 'dotted 1px #cdcdcd' }}
                >
                  <div
                    className="mainChartLabelDot"
                    style={{ backgroundColor: '#A7EDE5' }}
                  />

                  <div className="mainCardDataContainer">
                    <p className="mainChartLabelText">Ótímabundir: </p>
                    {!loading ? (
                      <span className="chartNumberData">
                        {nrOfContracts?.inconclusive}
                      </span>
                    ) : (
                      '---'
                    )}
                  </div>
                </div>

                <div className="mainCardDataContainer">
                  <p className="mainChartLabelText">Samtals: </p>
                  {!loading ? (
                    <span className="chartNumberData">
                      {nrOfContracts?.no +
                        nrOfContracts?.yes +
                        nrOfContracts?.inconclusive +
                        nrOfContracts?.duenow}
                    </span>
                  ) : (
                    '---'
                  )}
                </div>

                <div
                  className="mainChartLabel"
                  style={{ paddingBottom: '10px' }}
                >
                  <div
                    className="mainChartLabelDot"
                    style={{ backgroundColor: 'white' }}
                  />
                </div>

                <div className="mainCardSelectionContainer">
                  <h4 style={{ margin: 0, fontSize: '16px' }}>
                    Fjöldi mánaða:{' '}
                  </h4>
                  <Select
                    className="monthSelection"
                    placeholder
                    optionFilterProp="children"
                    defaultValue={6}
                    onSelect={handleSelect}
                    loading={loading}
                    dropdownMatchSelectWidth={110}
                    size="middle"
                  >
                    {monthSelection.map((m) => {
                      return (
                        <Select.Option key={m} value={m}>
                          {m === 1 ? `${m} mánuður` : `${m} mánuðir`}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
              </div>

              <div className="mainCardChartContainer">
                {loading ? (
                  <LoadingOutlined
                    style={{ fontSize: 150, color: '#02529c' }}
                    spin
                  />
                ) : nrOfContracts?.total === 0 || !chartData ? (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="Engir samningar renna út á völdu tímabili"
                  />
                ) : (
                  <Chart
                    height="100%"
                    options={chartData.options}
                    series={chartData.series}
                    type="pie"
                  />
                )}
              </div>
            </div>
          </div>

          <div className="statsPieChartsCards">
            <RSSChart
              datalink="/contracts/rss/calc"
              title="Hlutfall samninga sem vísað var til Ríkissáttasemjara"
              admin
              showLegend={false}
            />

            <NewContractsChart
              datalink="/contracts/all/replace/active/calc"
              title="Fjöldi samninga þar sem nýr samningur tekur við af eldri, áður en sá eldri rennur út"
              showLegend={false}
              reverse
              displayDetails
              admin
            />
          </div>
        </div>

        <Tabs
          onTabClick={handleTabClick}
          className="statsTable"
          centered
          defaultActiveKey="2"
        >
          <Tabs.TabPane tab="Nú þegar búnir að renna út" key="1">
            <Table
              pagination={false}
              bordered
              dataSource={contracts}
              columns={columns}
              loading={loading}
              rowKey={(record) => {
                return record.unionid + record.contractid.toString();
              }}
            />

            <Pagination
              style={{ padding: '20px', float: 'right' }}
              total={totalRows}
              pageSizeOptions={[10, 20, 50, 100]}
              defaultPageSize={fetchOptions.limit}
              current={fetchOptions.offset}
              onChange={handlePagination}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={`Renna út innan ${
              months === 1 ? '1 mánaðar' : `${months} mánaða`
            }`}
            key="2"
          >
            <Table
              pagination={false}
              bordered
              dataSource={contracts}
              columns={columns}
              loading={loading}
              rowKey={(record) => {
                return record.unionid + record.contractid.toString();
              }}
            />

            <Pagination
              style={{ padding: '20px', float: 'right' }}
              total={totalRows}
              pageSizeOptions={[10, 20, 50, 100]}
              defaultPageSize={fetchOptions.limit}
              // defaultCurrent={1}
              current={fetchOptions.offset}
              onChange={handlePagination}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Renna ekki út" key="3">
            <Table
              pagination={false}
              bordered
              dataSource={contracts}
              columns={columns}
              loading={loading}
              rowKey={(record) => {
                return record.unionid + record.contractid.toString();
              }}
            />

            <Pagination
              style={{ padding: '20px', float: 'right' }}
              total={totalRows}
              pageSizeOptions={[10, 20, 50, 100]}
              defaultPageSize={fetchOptions.limit}
              // defaultCurrent={1}
              current={fetchOptions.offset}
              onChange={handlePagination}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Ótímabundnir samningar" key="4">
            <Table
              pagination={false}
              bordered
              dataSource={contracts}
              columns={columns}
              loading={loading}
              rowKey={(record) => {
                return record.unionid + record.contractid.toString();
              }}
            />

            <Pagination
              style={{ padding: '20px', float: 'right' }}
              total={totalRows}
              pageSizeOptions={[10, 20, 50, 100]}
              defaultPageSize={fetchOptions.limit}
              // defaultCurrent={1}
              current={fetchOptions.offset}
              onChange={handlePagination}
            />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Vísað" key="5">
            <Table
              pagination={false}
              bordered
              dataSource={contracts}
              columns={columns}
              loading={loading}
              rowKey={(record) => {
                return record.unionid + record.contractid.toString();
              }}
            />

            <Pagination
              style={{ padding: '20px', float: 'right' }}
              total={totalRows}
              pageSizeOptions={[10, 20, 50, 100]}
              defaultPageSize={fetchOptions.limit}
              // defaultCurrent={1}
              current={fetchOptions.offset}
              onChange={handlePagination}
            />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Ekki vísað" key="6">
            <Table
              pagination={false}
              bordered
              dataSource={contracts}
              columns={columns}
              loading={loading}
              rowKey={(record) => {
                return record.unionid + record.contractid.toString();
              }}
            />

            <Pagination
              style={{ padding: '20px', float: 'right' }}
              total={totalRows}
              pageSizeOptions={[10, 20, 50, 100]}
              defaultPageSize={fetchOptions.limit}
              // defaultCurrent={1}
              current={fetchOptions.offset}
              onChange={handlePagination}
            />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Meiri Tölfræði" key="7">
            <MiniTable
              title="Fjöldi launafólks eftir launagreiðendum"
              fetchRoute="/contracts/contractsinemployer/calc"
              tableHeaders={[
                'Atvinnurekendur',
                'Fjöldi launafólks á launaskrá',
                'Hlutfalssleg skipting',
              ]}
              tableRecordIndexes={['launagreiðandi', 'Fjöldi Samninga']}
            />

            <MiniTable
              title="Fjöldi kjarasamningar eftir heildarsamtökum"
              fetchRoute="/contracts/contractsinwholeorg/calc"
              tableHeaders={[
                'Atvinnurekendur',
                'Fjöldi launafólks á launaskrá',
                'Hlutfalssleg skipting',
              ]}
              tableRecordIndexes={['heildarsamtök', 'Fjöldi Samninga']}
            />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Ekki í reikning" key="8">
            <Table
              pagination={false}
              bordered
              dataSource={contracts}
              columns={columns}
              loading={loading}
              rowKey={(record) => {
                return record.unionid + record.contractid.toString();
              }}
            />

            <Pagination
              style={{ padding: '20px', float: 'right' }}
              total={totalRows}
              pageSizeOptions={[10, 20, 50, 100]}
              defaultPageSize={20}
              defaultCurrent={1}
              onChange={(offs, lim) => {
                // fetchContracts(offs, lim);
                setFetchOptions({ limit: lim, offset: offs });
              }}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </>
  );
};
