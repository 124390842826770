import React, { useContext, useState, useEffect, useRef } from 'react';
import {
  Table,
  Input,
  Popconfirm,
  DatePicker,
  Form,
  Button,
  Switch,
  Typography,
  Select,
  message,
  Modal,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import 'moment/locale/is';
import moment from 'moment';
import { Header } from '../../../components/header/Header.jsx';
import { AdminNavBar } from '../../../components/adminnavbar/AdminNavBar.jsx';

import './editallcontracts.scss';

import { fetchlink } from '../../../utils/link.js';
import { getJwt, getUser } from '../../../auth/auth.js';
import { Settings } from '../../../components/settings/Settings.jsx';

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = (isDate) => {
    setEditing(!editing);
    const editDate = isDate && !editing;
    const data =
      editDate && record[dataIndex] != null
        ? moment(record[dataIndex])
        : record[dataIndex];
    form.setFieldsValue({
      [dataIndex]: data,
    });
  };

  function showEditConfirm() {
    Modal.confirm({
      title: 'Ertu viss um að þú viljir breyta þessu gildi?',
      icon: <ExclamationCircleOutlined />,
      content: 'Ekki er hægt að afturkalla þessa aðgerð!',
      okText: 'Breyta',
      okType: 'primary',
      cancelText: 'Hætta við',
      async onOk() {
        try {
          const values = await form.validateFields();
          const objKey = Object.keys(values);

          toggleEdit();
          handleSave({ ...record, ...values }, objKey);
        } catch (errInfo) {
          console.log('Save failed:', errInfo);
        }
      },
      onCancel() {
        toggleEdit();
        console.log(record);
        console.info('Hætt við');
      },
    });
  }

  const save = async () => {
    showEditConfirm();
  };

  let childNode = children;

  if (editable) {
    const isDate = [
      'signatureDate',
      'durationPeriodFrom',
      'durationPeriodTo',
    ].includes(dataIndex);

    const isSelect = ['contractCategory', 'typeOfMarket'].includes(dataIndex);

    const selectOptions =
      dataIndex === 'contractCategory' ? (
        <>
          <Select.Option key={1} value='Heildarútgáfa'>
            Heildarútgáfa
          </Select.Option>
          <Select.Option key={2} value='Kjarasamningur'>
            Kjarasamningur
          </Select.Option>
        </>
      ) : (
        <>
          <Select.Option key={1} value='Opinber'>
            Opinber
          </Select.Option>
          <Select.Option key={2} value='Almennur'>
            Almennur
          </Select.Option>
        </>
      );

    const inputNode = isDate ? (
      <DatePicker ref={inputRef} onBlur={save} />
    ) : isSelect ? (
      <Select ref={inputRef} onBlur={save}>
        {selectOptions}
      </Select>
    ) : (
      <Input ref={inputRef} onBlur={save} />
    );

    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        {inputNode}
      </Form.Item>
    ) : (
      <div
        className='editable-cell-value-wrap'
        style={{
          paddingRight: 24,
        }}
        onClick={() => toggleEdit(isDate)}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export function EditAllContracts(props) {
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    fetchContracts();
  }, []);

  async function fetchContracts() {
    setLoading(true);
    const data = await fetch(`${fetchlink}/contracts`);
    const json = await data.json();

    if (!data.ok) {
      message.error(json.message);
    } else {
      const data = json.map((contract, i) => {
        return {
          key: contract.id,
          name: contract.name,
          signatureDate: contract.signaturedate,
          durationPeriodFrom: contract.durationperiodfrom,
          durationPeriodTo: contract.durationperiodto,
          contractCategory:
            contract.contractcategory.charAt(0).toUpperCase() +
            contract.contractcategory.slice(1),
          typeOfMarket: contract.typeofmarket,
        };
      }, []);

      setContracts(data);
    }
    setLoading(false);
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'key',
      width: '4%',
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: 'Samningur',
      dataIndex: 'name',
      width: '30%',
      editable: true,
      render: (_, record) =>
        editMode ? (
          record.name
        ) : (
          <Typography.Link href={`/admin/contracts/${record.key}`}>
            {record.name}
          </Typography.Link>
        ),
    },
    {
      title: 'Dags. undirritunar',
      dataIndex: 'signatureDate',
      editable: true,
      render: (_, record) =>
        record.signatureDate == null
          ? 'Á ekki við'
          : moment(record.signatureDate).format('ll'),
      sorter: (a, b) =>
        moment(a.signatureDate).unix() - moment(b.signatureDate).unix(),
      responsive: ['lg'],
    },
    {
      title: 'Gildist. Frá',
      dataIndex: 'durationPeriodFrom',
      editable: true,
      render: (_, record) => moment(record.durationPeriodFrom).format('ll'),
      sorter: (a, b) =>
        moment(a.durationPeriodFrom).unix() -
        moment(b.durationPeriodFrom).unix(),
    },
    {
      title: 'Gildist. Til',
      dataIndex: 'durationPeriodTo',
      editable: true,
      render: (_, record) => moment(record.durationPeriodTo).format('ll'),
      sorter: (a, b) =>
        moment(a.durationPeriodTo).unix() - moment(b.durationPeriodTo).unix(),
    },
    {
      title: 'Heildarútg. / Kjarasamningur',
      dataIndex: 'contractCategory',
      editable: true,
    },
    {
      title: 'Markaður',
      dataIndex: 'typeOfMarket',
      editable: true,
      responsive: ['lg'],
    },
    {
      title: 'Stillingar',
      dataIndex: 'operation',
      render: (_, record) =>
        contracts.length >= 1 ? (
          <Settings
            id={record.key}
            isContractPage={false}
            reFetch={() => {
              fetchContracts();
            }}
            displayIconOnly
          />
        ) : null,
    },
  ];

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const handleSave = async (row, objKey) => {
    setLoading(true);

    const jwt = getJwt();
    const user = getUser();

    let valueToChange;
    if (['contractCategory', 'typeOfMarket'].includes(objKey[0])) {
      valueToChange = {
        id: row.key,
        [objKey]: row[objKey]
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, ''),
      };
    } else {
      valueToChange = { id: row.key, [objKey]: row[objKey] };
    }

    const result = await fetch(`${fetchlink}/contracts/update`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${jwt.token}`,
      },
      body: JSON.stringify({ ...valueToChange, ...user }),
    });

    if (!result.ok) {
      message.error('Óþekkt villa, ekki tókst að breyta reitnum');
      setLoading(false);
    } else {
      message.success('Tókst!');
      const newData = [...contracts];
      const index = newData.findIndex((item) => row.key === item.key);
      const item = newData[index];

      newData.splice(index, 1, { ...item, ...row });
      setContracts(newData);
      setLoading(false);
    }
  };

  const cols = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: editMode
        ? (record) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave,
          })
        : '',
    };
  });

  return (
    <>
      <AdminNavBar isAuthenticated={props.isAuthenticated} />
      <div className='mainSection'>
        <div className='tableContainer'>
          <div
            style={{
              display: 'flex',
              marginBottom: '1rem',
              backgroundColor: 'white',
              justifyContent: 'space-between',
              height: '50px',
              padding: '1rem',
              border: '1px solid #eee',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <p style={{ marginRight: '10px' }}>Fara í stillingarham:</p>
              <Switch onChange={() => setEditMode(!editMode)}></Switch>
            </div>
            <p>Fjöldi samninga: {contracts.length}</p>
          </div>
          <Table
            pagination={{
              defaultPageSize: 20,
              pageSizeOptions: [10, 20, 50, 100],
            }}
            components={editMode ? components : ''}
            rowClassName={() => 'editable-row'}
            bordered
            dataSource={contracts}
            columns={cols}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
}
