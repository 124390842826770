import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './newpassword.css';

import { Form, Input, Button, message, Result } from 'antd';
import { Header } from '../../components/header/Header';
import { updatePass } from '../auth.js';
import { NavBar } from '../../components/navbar/NavBar.jsx';

export const NewPassword = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [changeComplete, setChangeComplete] = useState(false);
  const { id } = useParams();

  const onFinish = async (values) => {
    setLoading(true);
    const result = await updatePass(values, id);

    if (!result.ok) {
      message.error(result.error);
      form.resetFields();
    } else {
      setChangeComplete(true);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };

  if (changeComplete) {
    return (
      <Result
        status='success'
        title='Aðgerð tókst!'
        subTitle='Lykilorði hefur verið breytt!'
        extra={
          <Button href='/admin' key='newcontract'>
            Fara á forsíðu
          </Button>
        }
      ></Result>
    );
  }

  return (
    <>
      <NavBar />
      <Header title='Nýtt lykilorð' />
      <div className='newPasswordSection'>
        <div className='newPasswordContainer'>
          <Form
            form={form}
            layout='vertical'
            className='newPasswordForm'
            name='newPass'
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label='Nýtt lykilorð'
              className='registerItem'
              name='password'
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <Input.Password disabled={loading} />
            </Form.Item>

            <Form.Item
              label='Staðfesting lykilorðs'
              className='registerItem'
              name='confirmPassword'
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        'The two passwords that you entered do not match!'
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password disabled={loading} />
            </Form.Item>

            <Form.Item className='newPasswordItem'>
              <Button
                size='large'
                className='newPasswordBtn'
                type='primary'
                htmlType='submit'
                loading={loading}
              >
                Breyta lykilorði
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};
