import './navbar.scss';

import logo from '../../images/navlogo.png';
import mobileLogo from '../../images/rsslogo.png';
import { Link } from 'react-router-dom';

export const NavBar = () => {
  return (
    <div className={`bar`}>
      <div className="navLogoContainer">
        <Link style={{ width: '100%' }} to="/">
          <img src={logo} alt="logo" className="navbarLogo" />
          <img src={mobileLogo} alt="logo" className="logoMobile" />
        </Link>
      </div>
    </div>
  );
};
