import React, { useState } from 'react';
import './register.css';

import { Form, Input, Button, Result, message, Typography } from 'antd';
import { Header } from '../../components/header/Header.jsx';
import { AdminNavBar } from '../../components/adminnavbar/AdminNavBar.jsx';
import { register } from '../auth.js';

export const Register = (props) => {
  const [loading, setLoading] = useState(false);
  const [registered, setRegistered] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    const result = await register(values);

    setLoading(false);
    if (!result.ok) {
      message.error(result.error);
    } else {
      setRegistered(true);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };

  if (registered) {
    return (
      <>
        <AdminNavBar isAuthenticated={props.isAuthenticated} />
        <Header title='Skrá nýjan notanda' />
        <Result
          status='success'
          title='Nýr notandi skráður'
          subTitle='Póstur hefur verið sendur á netfang notanda.'
          extra={
            <Button onClick={() => setRegistered(false)} key='newcontract'>
              Skrá nýjan notanda
            </Button>
          }
        ></Result>
      </>
    );
  }

  return (
    <>
      <AdminNavBar isAuthenticated={props.isAuthenticated} />
      <Header title='Skrá nýjan notanda' />
      <div className='registerSection'>
        <div className='registerContainer'>
          <div
            style={{ paddingBottom: 0, marginBottom: 0 }}
            className='registerItem'
          >
            <Typography.Text>
              Settu inn netfang viðkomandi og notandanafn sem hann óskar eftir.
              Tölvupóstur mun berast nýja notendanum með hlekk á uppsetningu
              lykilorðs. Pósturinn rennur út eftir 12 klukkustundir.
            </Typography.Text>
          </div>
          <Form
            layout='vertical'
            className='registerForm'
            name='register'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label='Netfang:'
              className='registerItem'
              name='email'
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your E-mail!',
                },
              ]}
            >
              <Input disabled={loading} />
            </Form.Item>

            <Form.Item
              label='Notendanafn'
              className='registerItem'
              name='username'
              rules={[
                { required: true, message: 'Please input your username!' },
              ]}
            >
              <Input disabled={loading} />
            </Form.Item>

            <Form.Item className='registerItem'>
              <Button
                size='large'
                className='registerBtn'
                type='primary'
                htmlType='submit'
                loading={loading}
              >
                Skrá notanda
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};
