import React, { useState } from 'react';

import { Form, Input, Typography, Divider, Modal, message } from 'antd';
import { forgotPass } from '../auth.js';

export default function ForgotPassword(props) {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setLoading(true);
    const result = await forgotPass(values);

    if (!result.ok) {
      setLoading(false);
      message.error(result.error);
    } else {
      message.success('Tókst!');
      setTimeout(function () {
        setLoading(false);
        props.closeModal();
      }, 1500);
    }
  };

  return (
    <Modal
      title='Gleymt lykilorð?'
      visible={props.modal}
      okText='Senda'
      cancelText='Hætta við'
      okButtonProps={{ htmlType: 'submit' }}
      onOk={form.submit}
      onCancel={props.handleCancel()}
      width={550}
    >
      <Typography.Text type='secondary'>
        Vinsamlegast settu inn netfangið sem þú notar til að skrá þig inn
      </Typography.Text>
      <Divider />
      <Form
        layout='vertical'
        method='POST'
        onFinish={onFinish}
        className='forgotPasswordForm'
        form={form}
      >
        <Form.Item
          label='Netfang:'
          name='email'
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              message: 'Please input your E-mail!',
            },
          ]}
        >
          <Input disabled={loading} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
