import './progressbar.scss';
import { Progress, Tag } from 'antd';
import {
  CheckCircleOutlined,
  SubnodeOutlined,
  ExpandAltOutlined,
} from '@ant-design/icons';
import { dateProgress, dateManipulation } from '../../utils/utils.js';

export function ProgressBar(props) {
  return (
    <>
      <div className='progressContent'>
        <p style={{ marginBottom: 0 }}>
          Gildistími: Frá{' '}
          <span style={{ color: '#02529c', fontWeight: 500 }}>
            {dateManipulation(props.dateFrom)}
          </span>{' '}
          til{' '}
          <span style={{ color: '#02529c', fontWeight: 500 }}>
            {console.log(props.dateTo)}
          {props.dateTo === null ? 'Ótímabundinn': dateManipulation(props.dateTo)}
          </span>
        </p>
        {props.iswritecontrol ? (
          <Tag
            style={{ marginRight: 0 }}
            color='cyan'
            icon={<ExpandAltOutlined />}
          >
            <strong>RITSTÝRÐUR SAMNINGUR</strong>
          </Tag>
        ) : props.type === 'heildarutgafa' ? (
          <Tag
            style={{ marginRight: 0 }}
            color='success'
            icon={<CheckCircleOutlined />}
          >
            <strong>HEILDARÚTGÁFA</strong>
          </Tag>
        ) : (
          <Tag
            style={{ marginRight: 0 }}
            color='blue'
            icon={<SubnodeOutlined />}
          >
            <strong>KJARASAMNINGUR</strong>
          </Tag>
        )}
      </div>
        {props.dateTo === null ?('') : (

            <Progress
              trailColor='#e8e8e8'
              percent={dateProgress(props.dateFrom, props.dateTo)}
              className='progressBar'
              strokeColor='#02529c'
            />

        )}
    </>
  );
}
