import './infocard.scss';
import signature from '../../../images/signature.svg';
import { Card, Divider, Typography } from 'antd';
import {
  MenuUnfoldOutlined,
  BankOutlined,
  BookOutlined,
  AuditOutlined,
} from '@ant-design/icons';
import { dateManipulation } from '../../../utils/utils.js';
import { ContractTag } from '../../contracttag/ContractTag';

export function InfoCard(props) {
  return (
    <>
      <Card
        className='infoCard bs'
        title='Upplýsingar'
        extra={<MenuUnfoldOutlined style={{ fontSize: '28px' }} />}
      >
        <p className='contentText'>
          <img
            src={signature}
            alt=''
            style={{ width: '16px', height: '16px', marginRight: '5px' }}
          />
          Dagsetning undirritunar:{' '}
          {props.contract.signaturedate ? (
            <span className='contentSpanText'>
              {dateManipulation(props.contract.signaturedate)}
            </span>
          ) : (
            <span className='contentSpanText'>Á ekki við</span>
          )}
        </p>
        <Divider style={{ backgroundColor: 'white', margin: '10px 0' }} />
        <p className='contentText'>
          <BankOutlined
            style={{ marginRight: '5px', fontSize: 16, color: 'black' }}
          />
          Markaður:{' '}
          <span className='contentSpanText'>
            {props.contract.typeofmarket === 'Almennur'
              ? 'Almennur markaður'
              : 'Opinber markaður'}
          </span>
        </p>
        <Divider style={{ backgroundColor: 'white', margin: '10px 0' }} />
        <p className='contentText'>
          <BookOutlined
            style={{ marginRight: '5px', fontSize: 16, color: 'black' }}
          />
          Vísað til ríkissáttasemjara:{' '}
          <span className='contentSpanText'>
            {props.contract.rss
              ? props.contract.rssdate
                ? dateManipulation(props.contract.rssdate)
                : 'Engin dagsetning skráð'
              : 'Nei'}
          </span>
        </p>
        <Divider style={{ backgroundColor: 'white', margin: '10px 0' }} />
        <p className='contentText'>
          <AuditOutlined
            style={{ marginRight: '5px', fontSize: 16, color: 'black' }}
          />
          {props.contract.employer?.length > 1
            ? 'Launagreiðendur: '
            : 'Launagreiðandi: '}
          {props.contract.employer?.map((value, i) => {
            return (
              <span key={i}>
                <Typography.Link
                  href={`${props.link}/employers/${value.id}`}
                  className='contentSpanText'
                >
                  {props.contract.employer?.length === i + 1 ? (
                    value.name
                  ) : (
                    <span>
                      {value.name}
                      <span style={{ color: 'gray' }}>, </span>
                    </span>
                  )}
                </Typography.Link>
              </span>
            );
          })}
        </p>
        <Divider style={{ backgroundColor: 'white', margin: '10px 0' }} />
        <ContractTag link={props.link} id={props.contract.id} />
      </Card>
    </>
  );
}
