import { useEffect, useState } from 'react';

import './chartcard.scss';
import { Card, message, Table, Typography } from 'antd';
import { AreaChartOutlined } from '@ant-design/icons';
import { fetchlink } from '../../../utils/link.js';

export function ChartCard(props) {
  const [votes, setVotes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (props.id) {
      async function fetchVotes() {
        const data = await fetch(
          `${fetchlink}/unioncontract/contractid=${props.id}`
        );

        if (!data.ok) {
          message.error('Villa kom upp þegar gögn um atkvæðagreiðslu voru sótt');
        } else {
          const json = await data.json();
          setVotes(json);
        }
        setLoading(false);
      }

      fetchVotes();
    }
  }, [props.id]);

  const columns = [
    {
      title: 'Stéttarfélag',
      dataIndex: 'unionname',
      key: 'unionname',
      render: (text, obj) => (
        <Typography.Link href={`${props.admin}/unions/${obj.unionid}`}>
          {text}
        </Typography.Link>
      ),
      sorter: (a, b) => a.unionname.length - b.unionname.length,
      sortDirections: ['descend'],
    },
    {
      title: 'Fjöldi á kjörskrá',
      dataIndex: 'nrofmembers',
      key: 'nrofmembers',
      style: {
        color: 'blue',
      },
      sorter: (a, b) => a.nrofmembers - b.nrofmembers,
      render: (text) =>
        text ? (
          <Typography.Text>
            {`${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </Typography.Text>
        ) : (
          '0'
        ),
    },
    {
      title: 'Já',
      dataIndex: 'nrofmembersyes',
      key: 'nrofmembersyes',
      render: (text) =>
        text ? (
          <Typography.Text style={{ color: '#3f8600' }}>
            {`${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </Typography.Text>
        ) : (
          '0'
        ),
    },
    {
      title: 'Nei',
      dataIndex: 'nrofmembersno',
      key: 'nrofmembersno',
      render: (text) =>
        text ? (
          <Typography.Text style={{ color: '#cf1322' }}>
            {`${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </Typography.Text>
        ) : (
          '0'
        ),
    },
    {
      title: 'Auð/ógild',
      dataIndex: 'nrofmembersempty',
      key: 'nrofmembersempty',
      render: (text) =>
        text ? (
          <Typography.Text style={{ color: '#3a3a3a' }}>
            {`${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </Typography.Text>
        ) : (
          '0'
        ),
    },
  ];

  return (
    <>
      <Card
        loading={loading}
        className='chartCard bs'
        title={props.title}
        extra={<AreaChartOutlined style={{ fontSize: '28px' }} />}
      >
        <Table
          style={{ paddingBottom: '50px' }}
          loading={false}
          pagination={false}
          columns={columns}
          dataSource={votes}
          size='small'
          rowKey={(item) => `tableRow${item.id}`}
        />
      </Card>
    </>
  );
}
