import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import { fetchlink } from '../../../utils/link.js';

import { EditGeneralInfo } from '../../../components/forms/editgeneralinfo/EditGeneralInfo.jsx';
import { Header } from '../../../components/header/Header.jsx';
import { AdminNavBar } from '../../../components/adminnavbar/AdminNavBar.jsx';
import { Button, Tabs, Typography } from 'antd';
import { RollbackOutlined } from '@ant-design/icons';
import { EditVotes } from '../../../components/forms/editvotes/EditVotes.jsx';
import { EditConnections } from '../../../components/forms/editconnections/EditConnections.jsx';

export function EditContract(props) {
  const [contract, setContract] = useState([]);
  const { id } = useParams();
  const routerHistory = useHistory();

  useEffect(() => {
    fetchContracts();
  }, []);

  async function fetchContracts() {
    const data = await fetch(`${fetchlink}/contracts/id=${id}`);
    const json = await data.json();
    if (!data.ok) {
      routerHistory.push('/not-found');
      return;
    } else {
      setContract(json[0]);
    }
  }

  return (
    <>
      <AdminNavBar isAuthenticated={props.isAuthenticated} />
      <Header title="Breyta samning" />
      <div className="mainSection">
        <div className="mainContainer">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Typography.Title level={5}>
              Gagnagrunnur: <span>{contract.isold ? 'Gamli' : 'Nýji'}</span>
            </Typography.Title>
            <Button
              style={{
                marginBottom: '8px',
                marginLeft: '20px',
              }}
              icon={<RollbackOutlined />}
              type="default"
              href={`/admin/contracts/${id}`}
            >
              Samningssíða
            </Button>
          </div>

          <Tabs className="mainContent" centered defaultActiveKey="1">
            <Tabs.TabPane tab="Almennar upplýsingar" key="1">
              <EditGeneralInfo contract={contract} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Stéttarfélög" key="2">
              <EditVotes contract={contract} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Tengingar" key="3">
              <EditConnections onEdit={fetchContracts} contract={contract} />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    </>
  );
}
