import { useState, useEffect } from 'react';
import { Header } from '../../../components/header/Header.jsx';

import { PageHeader } from 'antd';

import { fetchlink } from '../../../utils/link.js';

import { ContractCollapse } from '../../../components/contractcollapse/ContractCollapse.jsx';
import { AdminNavBar } from '../../../components/adminnavbar/AdminNavBar.jsx';

export function AdminOldContractsPage(props) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [contracts, setContracts] = useState([]);

  useEffect(() => {
    async function fetchContracts() {
      const data = await fetch(`${fetchlink}/contractgroup/old`);
      const json = await data.json();

      if (!data.ok) {
        setError(json.message);
      } else {
        setContracts(json);
      }
      setLoading(false);
    }
    fetchContracts();
  }, []);
  return (
    <>
      <AdminNavBar isAuthenticated={props.isAuthenticated} />
      <Header title='Eldri samningar' />
      <div className='mainSection'>
        <div className='mainContainer'>
          <PageHeader
            style={{ paddingLeft: 0, width: '100%' }}
            ghost={true}
            onBack={() => window.history.back()}
            title='Til baka'
          />
          <ContractCollapse
            loading={loading}
            isAuthenticated={props.isAuthenticated}
            isCollapse={false}
            isHomePage={true}
            data={contracts}
          />
        </div>
      </div>
    </>
  );
}
