import React, { useState } from 'react';

import {
  Form,
  Input,
  Button,
  Switch,
  DatePicker,
  InputNumber,
  Space,
  Tooltip,
} from 'antd';
import {
  CloseOutlined,
  CheckOutlined,
  DeleteOutlined,
  PlusOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

export function Bookings(props) {
  const [followUp, setFollowUp] = useState(false);

  const followUpSwitch = (e) => {
    setFollowUp(e);
  };

  return (
    <>
      <div className='formitemContainer_bookings'>
        <Tooltip
          title='Samningsaðilar gera stundum bókanir við kjarasamning þar sem 
                 t.d. er lýst yfir vilja til að vinna saman að
                 ákveðnum verkefnum eða breytingum á samningstímanum.'
          placement='topLeft'
        >
          <h4 style={{ width: '50%' }}>
            <InfoCircleOutlined /> Bókanir
          </h4>
        </Tooltip>
        <div className='bookingsContainer'>
          <Form.Item name='nrOfBookings' label='Fjöldi'>
            <InputNumber disabled={props.loading} />
          </Form.Item>

          <Form.Item
            name='followUp'
            label='Eftirfylgni ríkissáttasemjara:'
            valuePropName='checked'
          >
            <Switch
              loading={props.loading}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onChange={(e) => followUpSwitch(e)}
            />
          </Form.Item>

          {followUp && (
            <Form.List name='bookings'>
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <Space
                      className='commentContainer'
                      key={field.key}
                      align='baseline'
                      direction='vertical'
                      size='100%'
                    >
                      <Form.Item
                        {...field}
                        fieldKey={[field.fieldKey, 'number']}
                        name={[field.name, 'number']}
                        label='Númer bókunar:'
                      >
                        <InputNumber />
                      </Form.Item>

                      <Form.Item
                        {...field}
                        fieldKey={[field.fieldKey, 'comment']}
                        name={[field.name, 'comment']}
                        label='Athugasemd:'
                      >
                        <Input.TextArea allowClear autoSize showCount />
                      </Form.Item>

                      <Form.Item
                        {...field}
                        fieldKey={[field.fieldKey, 'date']}
                        name={[field.name, 'date']}
                        label='Dagsetning:'
                      >
                        <DatePicker />
                      </Form.Item>

                      {/* <MinusCircleOutlined onClick={() => remove(field.name)} /> */}
                      <Button
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => remove(field.name)}
                      />
                    </Space>
                  ))}

                  <Form.Item>
                    <Button
                      style={{ marginTop: '16px' }}
                      type='dashed'
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Bæta við bókun
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          )}
        </div>
      </div>
    </>
  );
}
